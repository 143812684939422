import { Maybe, Network, ZoomParamsType } from '@apolloGenerated';
import { FiguresType, LineType } from '@rubin-dev/octavius';
import { AddressTable } from '../models';
import { TableType } from './table';

export type NewVisualizationParams = {
  network: Network;
  address?: string;
  txid?: string;
  uuid?: string;
};
export type GraphData = {
  tableData: Maybe<AddressTable>;
  nodes: NodeItem[];
  links: LinkItem[];
  canvas: ZoomParamsType | null;
};
export type GraphHashes = {
  hash: string;
  type: TableType;
};
export type WithAnnotation<T> = { annotation?: Maybe<string> } & T;
export type NodeItem = FiguresType & {
  size: number;
  active?: boolean;
  type:
    | TableType.Address
    | TableType.Cluster
    | TableType.Annotation
    | TableType.AnnotationLink;
};
export type LinkItem = LineType &
  WithAnnotation<{
    color: string;
    type: TableType.Transaction | TableType.AnnotationLink;
  }>;

export enum GraphApiErrors {
  ALREADY_EXIST = 'ALREADY_EXISTS',
  SERVER_ERROR = 'SERVER_ERROR',
  INVALID_DATA = 'INVALID_DATA',
  INVALID_IMPORTED_DATA = 'INVALID_IMPORTED_DATA',
  NOT_FOUND = 'NOT_FOUND',
}

export type GraphError = {
  code: GraphApiErrors;
};
export type GraphDataResult<Data, Error = GraphError> = {
  data: Maybe<Data>;
  error: Maybe<Error>;
  status: 'success' | 'error';
};
