import { FC } from 'react';
import { GraphCustomColor } from '@graph/modules';

import { ColorPicker } from '@shared/ui';

import styles from './styles.module.scss';

export type ColorMenuProps = {
  activeColor: GraphCustomColor;
  onSelect(val: GraphCustomColor): void;
};
export const ColorMenu: FC<ColorMenuProps> = ({ activeColor, onSelect }) => {
  const items = Object.values(GraphCustomColor);

  return (
    <ColorPicker
      className={styles.linkColor}
      value={activeColor}
      items={items}
      onChange={onSelect}
    />
  );
};
