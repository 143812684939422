import { Dict } from '@aml/sdk/risk';
import sdk from '@aml/sdk';
import { Network } from '@apolloGenerated';

export class RiskCategories {
  private static categories: Partial<Record<Network, Dict['params']>> = {};

  static get(network: Network) {
    return RiskCategories.categories[network];
  }

  private static set(categories: Dict['params'], network: Network) {
    RiskCategories.categories[network] = categories;
  }

  static async init(network: Network) {
    if (RiskCategories.categories[network]) return;
    const riskCurrent = await sdk.risk[network].requests.riskServiceCurrentDict();
    RiskCategories.set(riskCurrent?.params || {}, network);
  }
}
