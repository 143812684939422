import { gql } from '@apollo/client';
import { jwtFragment } from './fragments';

export const login = gql`
  ${jwtFragment}
  mutation login($input: LoginInput!) {
    login(input: $input) {
      jwt {
        ...JwtFields
      }
      errors
    }
  }
`;
