import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  useQuery,
  useMutation,
  useQueryClient,
  type QueryClient,
  type UseMutationOptions,
  type UseQueryOptions,
  type MutationFunction,
  type UseMutationResult,
  type UseQueryResult,
} from '@tanstack/react-query';
export type Address = {
  address: AddressField;
  amount: AmountField;
};
export type AddressField = string;
export type AmountField = number;
export type BadRequestProblem = {
  error: string;
};
export type CalculateItem = {
  name: NameField;
  number: IDField;
  total: TotalField;
  percent: number;
  risk: RiskField;
  limit: RiskField;
  i18n: DirectoryI18n;
};
export type CalculatedRisk = {
  total: TotalField;
  risk: RiskField;
  items: {
    [key: string]: CalculateItem;
  };
};
export type CategoryField = string;
export type CheckedByField = 'User' | 'System';
export type Cluster = {
  cluster: ClusterField;
  owner: OwnerField;
  category_name: CategoryField;
  category_number: IDField;
  risk: RiskField;
};
export type ClusterField = string;
export type CreatedAtField = number;
export type DirectoryI18n = {
  ru: string;
  en: string;
};
export type ForAPICreatedAtField = string;
export type ForbiddenProblem = {
  error: string;
};
export type IDField = number;
export type InternalServerErrorProblem = {
  error: string;
};
export type NameField = string;
export type NetworkField = 'BTC' | 'ETH' | 'TRON';
export type NotFoundProblem = {
  error: string;
};
export type OwnerField = string;
export type PaymentRequiredProblem = {
  error: string;
};
export type Reported = {
  category_name: CategoryField;
  category_number: IDField;
  risk: RiskField;
};
export type RiskAddress = {
  created_at: ForAPICreatedAtField;
  transactions: IDField;
  sent: RiskField;
  received: RiskField;
  status: StatusField;
  first: ForAPICreatedAtField;
  last: ForAPICreatedAtField;
  risk: RiskField;
  categories: CategoryField[];
  reported?: Reported;
  cluster?: Cluster;
  calculated: CalculatedRisk;
};
export type RiskField = number;
export type StatusField = 'OVER_LIMIT' | 'CLEAN' | 'BLOCKED';
export type TotalField = string;
export type Transaction = {
  id: IDField;
  tx_id?: TxIDField;
  network: NetworkField;
  checkedBy?: CheckedByField;
  riskCategories: TransactionRisk[];
  status: StatusField;
  sendingAddresses?: {
    data?: Address[];
    total?: number;
  };
  receivingAddresses?: {
    data?: Address[];
    total?: number;
  };
  created_at?: CreatedAtField;
  updated_at?: UpdatedAtField;
};
export type TransactionRisk = {
  category: CategoryField;
  risk: RiskField;
  limit: RiskField;
  color?: string;
};
export type TransactionTotals = {
  over_limit: number;
  blocked: number;
  clean: number;
};
export type TxIDField = string;
export type UnauthorizedProblem = {
  error: string;
};
export type UpdatedAtField = number;
export type UserCategory = {
  id: IDField;
  category: CategoryField;
  limit: RiskField;
  recommended_limit: RiskField;
  color: string;
};
export type AxiosConfig = {
  paramsSerializer?: AxiosRequestConfig['paramsSerializer'];
};
export type Config = {
  mutations?: MutationConfigs;
  axios?: AxiosConfig;
};
export function initialize(axios: AxiosInstance, config?: Config) {
  const requests = makeRequests(axios, config?.axios);
  return {
    requests,
    queries: makeQueries(requests),
    mutations: makeMutations(requests, config?.mutations),
  };
}
function useRapiniMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  config?: (
    queryClient: QueryClient,
  ) => Pick<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'onSuccess' | 'onSettled' | 'onError'
  >,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { onSuccess, onError, onSettled, ...rest } = options ?? {};
  const queryClient = useQueryClient();
  const conf = config?.(queryClient);
  const mutationOptions: typeof options = {
    onSuccess: (data: TData, variables: TVariables, context: TContext) => {
      conf?.onSuccess?.(data, variables, context);
      onSuccess?.(data, variables, context);
    },
    onError: (error: TError, variables: TVariables, context?: TContext) => {
      conf?.onError?.(error, variables, context);
      onError?.(error, variables, context);
    },
    onSettled: (
      data: TData | undefined,
      error: TError | null,
      variables: TVariables,
      context?: TContext,
    ) => {
      conf?.onSettled?.(data, error, variables, context);
      onSettled?.(data, error, variables, context);
    },
    ...rest,
  };
  return useMutation({ mutationFn, ...mutationOptions });
}
function nullIfUndefined<T>(value: T): NonNullable<T> | null {
  return typeof value === 'undefined' ? null : (value as NonNullable<T> | null);
}
export const queryKeys = {
  getNetworkRiskAddress: (network: 'btc' | 'eth' | 'tron', address: string) =>
    ['getNetworkRiskAddress', network, address] as const,
  getNetworkRiskTransactionTxID: (network: 'btc' | 'eth' | 'tron', txID: string) =>
    ['getNetworkRiskTransactionTxID', network, txID] as const,
  getTransactionList: (
    hash?: string,
    page?: number,
    pageSize?: number,
    from?: string,
    to?: string,
    status?: 'CLEAN' | 'BLOCKED' | 'OVER_LIMIT',
    orderBy?: 'Asc' | 'Desc',
  ) =>
    [
      'getTransactionList',
      nullIfUndefined(hash),
      nullIfUndefined(page),
      nullIfUndefined(pageSize),
      nullIfUndefined(from),
      nullIfUndefined(to),
      nullIfUndefined(status),
      nullIfUndefined(orderBy),
    ] as const,
  getTransactionTotals: () => ['getTransactionTotals'] as const,
  getUserCategoryList: () => ['getUserCategoryList'] as const,
} as const;
export type QueryKeys = typeof queryKeys;
function makeRequests(axios: AxiosInstance, config?: AxiosConfig) {
  return {
    getNetworkRiskAddress: (network: 'btc' | 'eth' | 'tron', address: string) =>
      axios
        .request<RiskAddress>({
          method: 'get',
          url: `/${network}/risk/address/${address}`,
        })
        .then((res) => res.data),
    getNetworkRiskTransactionTxID: (network: 'btc' | 'eth' | 'tron', txID: string) =>
      axios
        .request<{
          transaction: Transaction;
        }>({
          method: 'get',
          url: `/${network}/risk/transaction/${txID}`,
        })
        .then((res) => res.data),
    getTransactionList: (
      hash?: string,
      page?: number,
      pageSize?: number,
      from?: string,
      to?: string,
      status?: 'CLEAN' | 'BLOCKED' | 'OVER_LIMIT',
      orderBy?: 'Asc' | 'Desc',
    ) =>
      axios
        .request<{
          transactionList?: Transaction[];
          total: number;
        }>({
          method: 'get',
          url: `/risk/transaction/list`,
          params: {
            ...(hash !== undefined ? { hash } : undefined),
            ...(page !== undefined ? { page } : undefined),
            ...(pageSize !== undefined ? { pageSize } : undefined),
            ...(from !== undefined ? { from } : undefined),
            ...(to !== undefined ? { to } : undefined),
            ...(status !== undefined ? { status } : undefined),
            ...(orderBy !== undefined ? { orderBy } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    postSetStatusToRiskTransaction: (payload: { id: IDField; status: StatusField }) =>
      axios
        .request<Transaction>({
          method: 'post',
          url: `/risk/transaction/set/status`,
          data: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data),
    getTransactionTotals: () =>
      axios
        .request<TransactionTotals>({
          method: 'get',
          url: `/risk/transaction/totals`,
        })
        .then((res) => res.data),
    getUserCategoryList: () =>
      axios
        .request<{
          userCategoryList: UserCategory[];
        }>({
          method: 'get',
          url: `/user/category/list`,
        })
        .then((res) => res.data),
    putSetNumberToUserCategory: (payload: {
      risks: {
        id?: IDField;
        limit?: RiskField;
      }[];
    }) =>
      axios
        .request<InternalServerErrorProblem>({
          method: 'put',
          url: `/user/category/set`,
          data: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data),
  } as const;
}
export type Requests = ReturnType<typeof makeRequests>;
export type Response<T extends keyof Requests> = Awaited<ReturnType<Requests[T]>>;
function makeQueries(requests: Requests) {
  return {
    useGetNetworkRiskAddress: (
      network: 'btc' | 'eth' | 'tron',
      address: string,
      options?: Omit<
        UseQueryOptions<
          Response<'getNetworkRiskAddress'>,
          unknown,
          Response<'getNetworkRiskAddress'>,
          ReturnType<QueryKeys['getNetworkRiskAddress']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'getNetworkRiskAddress'>, unknown> =>
      useQuery({
        queryKey: queryKeys.getNetworkRiskAddress(network, address),
        queryFn: () => requests.getNetworkRiskAddress(network, address),
        ...options,
      }),
    useGetNetworkRiskTransactionTxID: (
      network: 'btc' | 'eth' | 'tron',
      txID: string,
      options?: Omit<
        UseQueryOptions<
          Response<'getNetworkRiskTransactionTxID'>,
          unknown,
          Response<'getNetworkRiskTransactionTxID'>,
          ReturnType<QueryKeys['getNetworkRiskTransactionTxID']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'getNetworkRiskTransactionTxID'>, unknown> =>
      useQuery({
        queryKey: queryKeys.getNetworkRiskTransactionTxID(network, txID),
        queryFn: () => requests.getNetworkRiskTransactionTxID(network, txID),
        ...options,
      }),
    useGetTransactionList: (
      hash?: string,
      page?: number,
      pageSize?: number,
      from?: string,
      to?: string,
      status?: 'CLEAN' | 'BLOCKED' | 'OVER_LIMIT',
      orderBy?: 'Asc' | 'Desc',
      options?: Omit<
        UseQueryOptions<
          Response<'getTransactionList'>,
          unknown,
          Response<'getTransactionList'>,
          ReturnType<QueryKeys['getTransactionList']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'getTransactionList'>, unknown> =>
      useQuery({
        queryKey: queryKeys.getTransactionList(
          hash,
          page,
          pageSize,
          from,
          to,
          status,
          orderBy,
        ),
        queryFn: () =>
          requests.getTransactionList(hash, page, pageSize, from, to, status, orderBy),
        ...options,
      }),
    useGetTransactionTotals: (
      options?: Omit<
        UseQueryOptions<
          Response<'getTransactionTotals'>,
          unknown,
          Response<'getTransactionTotals'>,
          ReturnType<QueryKeys['getTransactionTotals']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'getTransactionTotals'>, unknown> =>
      useQuery({
        queryKey: queryKeys.getTransactionTotals(),
        queryFn: () => requests.getTransactionTotals(),
        ...options,
      }),
    useGetUserCategoryList: (
      options?: Omit<
        UseQueryOptions<
          Response<'getUserCategoryList'>,
          unknown,
          Response<'getUserCategoryList'>,
          ReturnType<QueryKeys['getUserCategoryList']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'getUserCategoryList'>, unknown> =>
      useQuery({
        queryKey: queryKeys.getUserCategoryList(),
        queryFn: () => requests.getUserCategoryList(),
        ...options,
      }),
  } as const;
}
type MutationConfigs = {
  usePostSetStatusToRiskTransaction?: (
    queryClient: QueryClient,
  ) => Pick<
    UseMutationOptions<
      Response<'postSetStatusToRiskTransaction'>,
      unknown,
      Parameters<Requests['postSetStatusToRiskTransaction']>[0],
      unknown
    >,
    'onSuccess' | 'onSettled' | 'onError'
  >;
  usePutSetNumberToUserCategory?: (
    queryClient: QueryClient,
  ) => Pick<
    UseMutationOptions<
      Response<'putSetNumberToUserCategory'>,
      unknown,
      Parameters<Requests['putSetNumberToUserCategory']>[0],
      unknown
    >,
    'onSuccess' | 'onSettled' | 'onError'
  >;
};
function makeMutations(requests: Requests, config?: Config['mutations']) {
  return {
    usePostSetStatusToRiskTransaction: (
      options?: Omit<
        UseMutationOptions<
          Response<'postSetStatusToRiskTransaction'>,
          unknown,
          Parameters<Requests['postSetStatusToRiskTransaction']>[0],
          unknown
        >,
        'mutationFn'
      >,
    ) =>
      useRapiniMutation<
        Response<'postSetStatusToRiskTransaction'>,
        unknown,
        Parameters<Requests['postSetStatusToRiskTransaction']>[0]
      >(
        (payload) => requests.postSetStatusToRiskTransaction(payload),
        config?.usePostSetStatusToRiskTransaction,
        options,
      ),
    usePutSetNumberToUserCategory: (
      options?: Omit<
        UseMutationOptions<
          Response<'putSetNumberToUserCategory'>,
          unknown,
          Parameters<Requests['putSetNumberToUserCategory']>[0],
          unknown
        >,
        'mutationFn'
      >,
    ) =>
      useRapiniMutation<
        Response<'putSetNumberToUserCategory'>,
        unknown,
        Parameters<Requests['putSetNumberToUserCategory']>[0]
      >(
        (payload) => requests.putSetNumberToUserCategory(payload),
        config?.usePutSetNumberToUserCategory,
        options,
      ),
  } as const;
}
