import {
  Maybe,
  Network,
  useExplorerFindTransactionByHashLazyQuery,
} from '@apolloGenerated';
import { isValidTransaction } from '@shared/libs';
import { useState } from 'react';

export const useTransactionValidate = (
  network: Network,
): [(id: string) => Promise<boolean>, { loading: boolean; value: Maybe<boolean> }] => {
  const [value, setValue] = useState<Maybe<boolean>>(null);
  const [findTransactionByHash, { loading }] =
    useExplorerFindTransactionByHashLazyQuery();
  const validate = async (id: string): Promise<boolean> => {
    if (!isValidTransaction(id, network)) {
      setValue(false);
      return false;
    }
    try {
      await findTransactionByHash({
        variables: {
          network,
          id,
        },
      });
      setValue(true);
      return true;
    } catch {
      setValue(false);
      return false;
    }
  };
  return [validate, { loading, value }];
};
