import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Paper, PaperProps, PropsWithNativeTypes } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

export type CardItemProps = {
  rightSlot?: JSX.Element;
};
export const CardItem: FC<
  PropsWithChildren<PropsWithNativeTypes<CardItemProps & PaperProps, HTMLDivElement>>
> = ({ rightSlot, radius = 8, px = 10, py = 5, children, className, ...rest }) => {
  return (
    <Paper
      className={cx(styles.cardItem, className)}
      px={px}
      py={py}
      radius={radius}
      {...rest}
    >
      {children}
      {rightSlot && <div className={styles.cardItem__rightSlot}>{rightSlot}</div>}
    </Paper>
  );
};
