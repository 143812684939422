import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Root } from './Root';
import './config/index';
import './styles/app.scss';

const rootNode = document.getElementById('root')!;
const useStrict = false;
const root = createRoot(rootNode);
root.render(
  useStrict ? (
    <StrictMode>
      <Root />
    </StrictMode>
  ) : (
    <Root />
  ),
);
