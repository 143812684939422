import React, { FC } from 'react';
import { Network } from '@apolloGenerated';
import styles from './styles.module.scss';
import { ClusterBalanceCard } from '../ClusterBalanceCard/ClusterBalanceCard';
import { ClusterTransactionsCard } from '../ClusterTransactionsCard/ClusterTransactionsCard';
import { ClusterTransactionInfo } from '../ClusterTransactionInfo/ClusterTransactionInfo';
import { Spacer } from '@rubin-dev/goblin';
import { ClusterMainInfo } from '@component/Address';

type ClusterInfoProps = {
  hash: string;
  network: Network;
};
export const ClusterInfo: FC<ClusterInfoProps> = ({ hash, network }) => {
  return (
    <div className={styles.clusterInfo}>
      <ClusterMainInfo
        wid={hash}
        network={network}
        hideRootAddress
        hideCluster
        hideActivity
        hideTransactions
        hideBalance
      />
      <Spacer size={12} />
      <div className={styles.clusterInfo__card}>
        <ClusterBalanceCard wid={hash} network={network} />
        <ClusterTransactionsCard wid={hash} network={network} />
      </div>
      <Spacer size={8} />
      <ClusterTransactionInfo wid={hash} network={network} />
    </div>
  );
};
