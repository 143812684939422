import React, { FC } from 'react';
import {
  ProfileAnalyzer,
  ProfileApi,
  ProfileMainInfo,
  ProfileMarkup,
  ApiKeys,
} from '../../component/Profile';
import { useTranslation } from 'react-i18next';
import { useMeQuery, User } from '@apolloGenerated';
import { hasPermission } from '@utils/auth';
import { SUBSCRIPTION_EXPLORER_MARKUP } from '@constants/permissions.const';
import { LoaderOverlay, Spacer, Typography } from '@rubin-dev/goblin';

export type ProfileScreenProp = {
  user?: User;
};

export const ProfileScreen: FC<ProfileScreenProp> = ({ user: prevUser }) => {
  const { t } = useTranslation();
  const { data } = useMeQuery({ fetchPolicy: 'network-only' });
  const user = data?.me || prevUser;

  if (!user) return <LoaderOverlay show fixed />;
  return (
    <>
      <Typography tag="h1" variant="head-32">
        {t('titles.personalData')}
      </Typography>
      <Spacer size={10} />
      <ProfileMainInfo name={user.name} email={user.email} />
      <Spacer size={24} />
      <Typography tag="h1" variant="head-32">
        {t('titles.products')}
      </Typography>
      <Spacer size={10} />
      {hasPermission(SUBSCRIPTION_EXPLORER_MARKUP) && (
        <>
          <ProfileMarkup count={user.billing.markup} />
          <Spacer size={10} />
        </>
      )}
      <ProfileAnalyzer
        plan={null}
        endTimestamp={0}
        analyzerSubscriptionExpireDate={user.billing.analyzerSubscriptionExpireDate}
      />
      <Spacer size={10} />
      <ProfileApi
        countTransaction={user.billing.transactions}
        countAddress={user.billing.address}
        bottomSlot={<ApiKeys />}
      />
    </>
  );
};
