import { router, RouterName } from '../../../router';

export const openTransaction = (network: string, txid: string) => {
  window.open(
    router.urlFor(RouterName.ExplorerTransactionDetails, {
      txid,
      network,
    }),
    '_blank',
    'noopener,noreferrer',
  );
};

export const openAddress = (network: string, address: string) => {
  window.open(
    router.urlFor(RouterName.ExplorerAddressDetails, {
      address,
      network,
    }),
    '_blank',
    'noopener,noreferrer',
  );
};
export const openWallet = (network: string, wid: string) => {
  window.open(
    router.urlFor(RouterName.ExplorerWallet, {
      wid,
      network,
    }),
    '_blank',
    'noopener,noreferrer',
  );
};

export const openVisualizationByUuid = (network: string, uuid: string) => {
  window.open(
    router.urlFor(RouterName.ExplorerSavedVisualization, {
      uuid,
      network,
    }),
    '_blank',
    'noopener,noreferrer',
  );
};
export const openVisualizationAddress = (network: string, address: string) => {
  window.open(
    router.urlFor(RouterName.ExplorerAddressVisualization, {
      address,
      network,
    }),
    '_blank',
    'noopener,noreferrer',
  );
};

export const openVisualizationTransaction = (network: string, txid: string) => {
  window.open(
    router.urlFor(RouterName.ExplorerTransactionVisualization, {
      txid,
      network,
    }),
    '_blank',
    'noopener,noreferrer',
  );
};

export const openMarkup = (address: string, details?: boolean, id?: number) => {
  window.open(
    router.urlFor(
      details ? RouterName.MarkupDetails : RouterName.Markup,
      {},
      { query: address, ...(id ? { id } : {}) },
    ),
    '_blank',
    'noopener,noreferrer',
  );
};
