import { FC } from 'react';
import { toaster } from '@rubin-dev/goblin';
import { Redirect } from 'react-justanother-router';
import { RouterName } from '../router';

export type AppErrorProps = {
  error: Error | undefined | null | string;
};

export const AppError: FC<AppErrorProps> = ({ error }) => {
  if (!error) {
    return null;
  } else {
    return <Redirect to={RouterName.TechnicalWorks} />;
  }

  toaster.error({ title: `${JSON.stringify(error, null, 4)}` });
  return null;
};
