import { ContextSetter, setContext } from '@apollo/client/link/context';
import {
  loadUser,
  logoutUser,
  persistUser,
  setAuthorizationHeaders,
  getNewTokenDirect,
} from '@utils/auth';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client';
import { router, history, RouterName } from '../router';
import { ApolloErrors, ApolloErrorService } from '@service/ApolloErrorService';
import { toaster } from '@rubin-dev/goblin';
import { GraphQLErrors } from '@apollo/client/errors';

const refreshTokenHandler: ErrorHandler = ({ graphQLErrors, operation, forward }) => {
  if (
    ApolloErrorService.hasError(
      graphQLErrors as GraphQLErrors,
      ApolloErrors.CORRUPTED_TOKEN,
      ApolloErrors.EXPIRED_REFRESH_TOKEN,
    )
  ) {
    logoutUser();
    history.navigate(router.urlFor(RouterName.AuthLogin));
    return;
  } else if (
    ApolloErrorService.hasError(
      graphQLErrors as GraphQLErrors,
      ApolloErrors.EXPIRED_TOKEN,
    )
  ) {
    return new Observable((observer) => {
      getNewTokenDirect()
        .then((refreshResponse) => {
          if (!refreshResponse) {
            return;
          }

          persistUser(refreshResponse);

          operation.setContext({
            headers: setAuthorizationHeaders(
              operation.getContext().headers,
              refreshResponse.accessToken,
              refreshResponse.refreshToken,
            ),
          });

          return forward(operation);
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          observer.error(error);
          toaster.error({ title: error.message });
          logoutUser();
          history.navigate(router.urlFor(RouterName.AuthLogin));
          return;
        });
    });
  }
};

export const refreshTokenErrorLink = onError(refreshTokenHandler);

const authContextSetter: ContextSetter = (operation, prevContext) => {
  const user = loadUser();

  return {
    headers: setAuthorizationHeaders(prevContext.headers, user?.accessToken),
  };
};

export const authLink = setContext(authContextSetter);

export const resetAuthTokenErrorLink = onError(({ graphQLErrors }) => {
  if (
    ApolloErrorService.hasError(
      graphQLErrors as GraphQLErrors,
      ApolloErrors.MISSING_AUTH_TOKEN,
    )
  ) {
    logoutUser();
    history.navigate(router.urlFor(RouterName.AuthLogin));
  }
});

export const globalErrorLink = onError(({ graphQLErrors, networkError }) => {
  ApolloErrorService.debugConsoleErrors({
    graphQLErrors: graphQLErrors as GraphQLErrors,
    networkError,
  });
});
