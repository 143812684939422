import sdk from '@aml/sdk';
import { useMemo } from 'react';

export type GetRiskDataType = {
  risk?: number;
  category?: string;
  isLoading?: boolean;
};

export function useClusterRisk(category: number): GetRiskDataType {
  const { data: categoryList, isLoading } =
    sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  const risk = useMemo(() => {
    if (!categoryList?.params) return null;
    return Object.values(categoryList?.params).find((item) => item.number === category);
  }, [category, categoryList]);

  return {
    risk: risk?.risk,
    category: risk?.name,
    isLoading,
  };
}
