import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactNode,
} from 'react';

export function childrenWithProps<T>({ children, ...props }: PropsWithChildren<T>) {
  return Children.map<ReactNode, ReactNode>(
    children,
    (child) => isValidElement(child) && cloneElement(child, props),
  );
}
