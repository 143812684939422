import { create } from 'zustand';
import { EventsType } from './types';
import { Maybe } from '@apolloGenerated';

export type GraphStoreType = {
  event: Maybe<EventsType>;
  sendEvent(event: EventsType, awaited?: boolean): void;
  clearEvent(): void;
  // sendAsyncEvent(event: EventsType): Promise<unknown>;
};
export const useGraphStore = create<GraphStoreType>()((set) => ({
  event: null,
  sendEvent: (event: EventsType, awaited?: boolean) => {
    if (awaited) setTimeout(() => set({ event }), 100);
    else set({ event });
  },
  clearEvent: () => ({ event: null }),
}));
export * from './types';
