import React, { FC, useCallback, useEffect, useState } from 'react';
import { Container } from '@shared/ui';
import { Divider } from '@rubin-dev/goblin';
import { FormContainer } from '@component/FormContainer';
import { ContentMarkingComponent } from '@component/Marking';
import styles from './marking.module.scss';
import { RouterName } from '../../router';
import { useNavigate } from 'react-justanother-router';
import { detectNetworkByAddress } from '@aml/validation';

interface Props {
  query: {
    query: string;
  };
}

export const MarkingScreen: FC<Props> = ({ query }) => {
  const { navigate } = useNavigate();
  const [address, setAddress] = useState<string>(query.query || '');
  const [network, setNetwork] = useState<string | undefined>(
    address ? detectNetworkByAddress(address) : undefined,
  );

  useEffect(() => {
    if (!Object.keys(query).length) {
      return;
    }

    setAddress(query.query.trim());
    setNetwork(detectNetworkByAddress(query.query));
  }, [query]);

  const onSubmit = useCallback(
    (val: string) => {
      navigate(RouterName.Marking, {}, { query: val });
    },
    [query],
  );

  return (
    <>
      <Divider />
      <Container className={styles.marking}>
        <div className={styles.marking__container}>
          <div className={styles.marking__wrapper}>
            <FormContainer
              initialValues={{ query: query.query }}
              formOnSubmit={(value) => onSubmit(value.query)}
            />
          </div>
          <ContentMarkingComponent address={address} network={network} />
        </div>
      </Container>
    </>
  );
};
