import React, { FC } from 'react';
import { InfoIcon, Tooltip } from '@rubin-dev/goblin';
import styles from './styles.module.scss';
export const TableCellUTXO: FC = () => {
  return (
    <Tooltip className={styles.utxoCell} placement="bottom" label="Change Output">
      <InfoIcon className={styles.utxoCell__icon} width={24} height={24} />
    </Tooltip>
  );
};
export const renderCellUTXO = () => <TableCellUTXO />;
