import { gql } from '@apollo/client';

export const billingKeyFragment = gql`
  fragment BillingKeyFields on BillingKey {
    createdAt
    key
  }
`;

export const billingFragment = gql`
  fragment BillingFields on Billing {
    address
    transactions
    markup
    analyzerSubscriptionExpireDate
  }
`;

export const billingKeyList = gql`
  ${billingKeyFragment}
  query billingKeyList($page: Int!, $pageSize: Int!) {
    billingKeyList(page: $page, pageSize: $pageSize) {
      total
      edge {
        ...BillingKeyFields
      }
      errors
    }
  }
`;

export const billingAddPacket = gql`
  mutation billingAddPacket(
    $userId: ID!
    $requests: Int64!
    $requestType: BillingRequestType!
  ) {
    billingAddPacket(userId: $userId, requests: $requests, requestType: $requestType)
  }
`;

export const billingKeyCreate = gql`
  ${billingKeyFragment}
  mutation billingKeyCreate {
    billingKeyCreate {
      ...BillingKeyFields
    }
  }
`;

export const billingKeyRemove = gql`
  ${billingKeyFragment}
  mutation billingKeyRemove($key: String!) {
    billingKeyRemove(key: $key)
  }
`;

export const billingAnalyzerSubscribe = gql`
  mutation billingAnalyzerSubscribe($userId: ID!, $expireDate: Int64!) {
    billingAnalyzerSubscribe(userId: $userId, expireDate: $expireDate)
  }
`;
