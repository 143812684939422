import {
  Address,
  AddressTransaction,
  AddressTransactionsFilterTxType,
  Network,
} from '@apolloGenerated';
import { TableType } from '@graph/types';
import { FEATURE_CLUSTERING } from '@graph/const/config.const';

const NETWORK_WITH_UTXO = [Network.Btc];
export class Transaction {
  mode: TableType;
  type: AddressTransactionsFilterTxType;
  txid: string;
  amount: string;
  token: string;
  network: Network;
  timestamp: string;
  isDirectTransfer: boolean = false;
  targetAddress?: string;
  sourceAddress?: string;
  checked: boolean = false;
  isUTXO: boolean = false;
  constructor(
    {
      txID,
      amount,
      token,
      senderAddr,
      recvAddr,
      timestamp,
      direction,
    }: AddressTransaction,
    network: Network,
    mode: TableType,
    isReversed?: boolean,
    checked?: boolean,
  ) {
    this.mode = mode;
    this.type = this.getTransactionType(direction);
    this.txid = txID;
    this.amount = amount;
    this.token = token ? token.toUpperCase() : network;
    this.network = network;
    this.timestamp = timestamp;
    this.setChecked(!!checked);
    isReversed
      ? this.handleReversedTransaction(senderAddr, recvAddr)
      : this.handleNormalTransaction(senderAddr, recvAddr);
    this.isUTXO = this.checkUTXO(this.sourceAddress, this.targetAddress);
  }

  private getTransactionType(
    direction: AddressTransaction['direction'],
  ): AddressTransactionsFilterTxType {
    return direction === 'receives'
      ? AddressTransactionsFilterTxType.Receives
      : AddressTransactionsFilterTxType.Sent;
  }

  private handleReversedTransaction(
    senderAddr: AddressTransaction['senderAddr'],
    recvAddr: AddressTransaction['recvAddr'],
  ) {
    this.isDirectTransfer = senderAddr.length === 1 && recvAddr.length === 1;
    if (this.type === AddressTransactionsFilterTxType.Sent)
      this.handleSentTransaction(senderAddr, recvAddr);
    else if (this.type === AddressTransactionsFilterTxType.Receives)
      this.handleReceivedTransaction(senderAddr, recvAddr);
  }

  private handleNormalTransaction(
    senderAddr: AddressTransaction['senderAddr'],
    recvAddr: AddressTransaction['recvAddr'],
  ) {
    this.isDirectTransfer = true;
    this.targetAddress = this.getReceiveSentHash(recvAddr[0]);
    this.sourceAddress = this.getReceiveSentHash(senderAddr[0]);
  }

  private handleSentTransaction(
    senderAddr: AddressTransaction['senderAddr'],
    recvAddr: AddressTransaction['recvAddr'],
  ) {
    if (this.isDirectTransfer) this.targetAddress = this.getReceiveSentHash(recvAddr[0]);
    if (senderAddr.length === 1)
      this.sourceAddress = this.getReceiveSentHash(senderAddr[0]);
  }

  private handleReceivedTransaction(
    senderAddr: AddressTransaction['senderAddr'],
    recvAddr: AddressTransaction['recvAddr'],
  ) {
    if (this.isDirectTransfer)
      this.targetAddress = this.getReceiveSentHash(senderAddr[0]);
    if (recvAddr.length === 1) this.sourceAddress = this.getReceiveSentHash(recvAddr[0]);
  }

  private getReceiveSentHash(addr: Address) {
    if (addr.cluster && FEATURE_CLUSTERING) return addr.cluster.id;
    return addr.address;
  }

  private checkUTXO(source?: string, target?: string): boolean {
    if (!NETWORK_WITH_UTXO.includes(this.network)) return false;
    return !!(source && target && target === source);
  }

  setChecked(checked: boolean) {
    this.checked = checked;
  }
}
