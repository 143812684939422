import React, { FC } from 'react';
import { ListItem } from '@shared/ui';
import { Typography } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

type AddressTooltipRowProps = {
  label: string;
  value: string;
};
export const TooltipRow: FC<AddressTooltipRowProps> = ({ label, value }) => {
  return (
    <ListItem
      className={styles.addressTooltip__row}
      label={`${label}:`}
      value={
        <Typography variant="body-16" color="on-surface-primary-1">
          {value}
        </Typography>
      }
      row
    />
  );
};
