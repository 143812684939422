import React, { FC } from 'react';
import { TooltipRow } from './TooltipRow';
import { InfoDenseIcon, InfoIcon, Tooltip, Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export type AddressBalanceTooltipProps = {
  totalIn: string;
  totalOut: string;
  active?: boolean;
  offsetValue?: number;
  dense?: boolean;
  network?: string;
};
export const BalanceTooltip: FC<AddressBalanceTooltipProps> = ({
  totalIn,
  totalOut,
  active,
  offsetValue,
  dense,
  network,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      active={active}
      placement={'bottom-start'}
      width={400}
      offsetValue={offsetValue}
      label={
        <div className={styles.addressTooltip__wrapper}>
          <TooltipRow label={t('strings.received')} value={totalIn} />
          <TooltipRow label={t('strings.sent')} value={totalOut} />
          {network && network !== 'BTC' && (
            <Typography variant="body-14" color="on-surface-primary-2">
              {t('titles.transactionsExcluded')}
            </Typography>
          )}
        </div>
      }
    >
      <div className={styles.addressTooltip__iconWrap}>
        {dense ? (
          <InfoDenseIcon className={styles.addressTooltip__icon} />
        ) : (
          <InfoIcon className={styles.addressTooltip__icon} />
        )}
      </div>
    </Tooltip>
  );
};
