import { gql } from '@apollo/client';
import { billingFragment } from './billing';

export const userFragment = gql`
  ${billingFragment}
  fragment UserFields on User {
    id
    name
    email
    isActive
    permissions
    createdAt
    updatedAt
    billing {
      ...BillingFields
    }
  }
`;

export const jwtFragment = gql`
  fragment JwtFields on Jwt {
    id
    accessToken
    refreshToken
    permissions
  }
`;
