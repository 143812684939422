import React, { FC, PropsWithChildren } from 'react';
import { PageNotFound } from '../../../../screen';
import { getNetworkEnum } from '@helpers/address';
import { useExplorerFindAddressByHashQuery } from '@apolloGenerated';
import { LayoutExplorer } from './LayoutExplorer';

type ExplorerAddressLayoutProps = {
  params: {
    address: string;
    network: string;
  };
};

export const LayoutExplorerAddress: FC<PropsWithChildren<ExplorerAddressLayoutProps>> = ({
  children,
  params: { network, address },
}) => {
  const { data, loading } = useExplorerFindAddressByHashQuery({
    variables: { network: getNetworkEnum(network), address },
    skip: !address,
  });

  if (!data?.explorerFindAddressByHash?.node && !loading) {
    return <PageNotFound />;
  }

  return <LayoutExplorer params={{ network, value: address }}>{children}</LayoutExplorer>;
};
