import { gql } from '@apollo/client';
import { jwtFragment } from './fragments';

export const registrationConfirm = gql`
  ${jwtFragment}
  mutation registrationConfirm($input: RegistrationConfirmInput!) {
    registrationConfirm(input: $input) {
      jwt {
        ...JwtFields
      }
      errors
    }
  }
`;
