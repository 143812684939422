import { FC, useMemo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { TableRow } from '@component/downloadPDF/ui/TableRow';
import { useTranslation } from 'react-i18next';
import { Dict } from '@aml/sdk/risk';
import { CalculateItem } from '@apolloGenerated';

interface Props {
  data: Dict;
  items?: Array<CalculateItem & { colorRisk?: number }>;
}

const borderColor = '#dfe2eb';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    flexGrow: 1,
    minHeight: 24,
  },
  cell: {
    width: '20%',
    fontSize: 8,
    color: '#3C4654',
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderColor,
    fontFamily: 'Helvetica-Bold',
  },
});
export const Table: FC<Props> = ({ data, items }) => {
  const { t } = useTranslation();

  const filterData = useMemo(() => {
    const itemNumbers = items?.map((item) => item.number) || [];
    const filterParams = Object.values(data?.params || {});

    return filterParams
      .map((item) => {
        return {
          ...item,
          colorRisk: String(items?.find((el) => el.number === item.number)?.colorRisk),
          risk: Number(items?.find((el) => el.number === item.number)?.risk),
          amount: Number(items?.find((el) => el.number === item.number)?.total_human),
          percent: Number(items?.find((el) => el.number === item.number)?.percent),
        };
      })
      .filter((item) => itemNumbers.includes(item.number) && Number(item?.risk) > 0.01);
  }, [data, items]);

  return (
    <View style={styles.tableContainer}>
      <View style={styles.container}>
        <Text style={[styles.cell, { width: '60%' }]}>{t('links.category')}</Text>
        <Text style={styles.cell}>{t('strings.riskscore')}</Text>
        <Text style={styles.cell}>{t('strings.percentage')}</Text>
      </View>
      <TableRow items={filterData} />
    </View>
  );
};
