import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const ArrowLeftToRightIcon: FC<IconProps> = ({
  width = 584,
  height = 32,
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 584 32"
      fill="none"
      {...rest}
    >
      <path
        d="M0.5 31C0.5 31.2761 0.723858 31.5 1 31.5C1.27614 31.5 1.5 31.2761 1.5 31H0.5ZM579.646 31.3536C579.842 31.5488 580.158 31.5488 580.354 31.3536L583.536 28.1716C583.731 27.9763 583.731 27.6597 583.536 27.4645C583.34 27.2692 583.024 27.2692 582.828 27.4645L580 30.2929L577.172 27.4645C576.976 27.2692 576.66 27.2692 576.464 27.4645C576.269 27.6597 576.269 27.9763 576.464 28.1716L579.646 31.3536ZM1.5 31V13H0.5V31H1.5ZM13 1.5H570V0.5H13V1.5ZM579.5 11V31H580.5V11H579.5ZM570 1.5C575.247 1.5 579.5 5.7533 579.5 11H580.5C580.5 5.20101 575.799 0.5 570 0.5V1.5ZM1.5 13C1.5 6.64873 6.64873 1.5 13 1.5V0.5C6.09644 0.5 0.5 6.09644 0.5 13H1.5Z"
        fill={color}
      />
    </svg>
  );
};
