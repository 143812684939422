import React, { FC, useMemo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { DictDictItem } from '@aml/sdk/risk';

interface Props {
  items: (DictDictItem & { amount: number; percent: number; colorRisk: string })[];
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#dfe2eb',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 18,
    fontSize: 8,
  },
  description: {
    width: '60%',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
  },
  risk: {
    width: '20%',
    textAlign: 'left',
  },
  percentage: {
    width: '20%',
    textAlign: 'left',
  },
  riskDot: {
    width: 4,
    height: 4,
    borderRadius: '50%',
  },
});

export const TableRow: FC<Props> = ({ items }) => {
  const getRiskColor = (riskValue: number): string => {
    if (riskValue <= 25) return '#3D7A21';
    if (riskValue >= 75) return '#B8433D';
    return '#ffb400';
  };

  const sorting = useMemo(() => {
    return items.sort((a, b) => Number(b?.risk) - Number(a?.risk));
  }, [items]);

  const rows = sorting.map((item) => (
    <View key={String(item.name)} style={styles.row}>
      <View style={styles.description}>
        <Text
          style={[
            styles.riskDot,
            { backgroundColor: getRiskColor(Number(item.colorRisk)), marginRight: 4 },
          ]}
        ></Text>
        <Text>{String(item.name)}</Text>
      </View>
      <Text style={styles.risk}>{String(item.risk)}%</Text>
      <Text style={styles.percentage}>{item.percent}%</Text>
    </View>
  ));
  return <>{rows}</>;
};
