import React, { FC, useMemo } from 'react';
import { getNetworkEnum } from '@helpers/address';
import { useExplorerFindAddressByHashQuery } from '@apolloGenerated';
import { BalanceCard } from '@graph-sidebar-module/shared';

type AddressTransactionsCardProps = {
  address: string;
  network: string;
};
export const AddressBalanceCard: FC<AddressTransactionsCardProps> = ({
  address,
  network,
}) => {
  const { data, loading } = useExplorerFindAddressByHashQuery({
    variables: { network: getNetworkEnum(network), address },
  });

  const node = useMemo(() => data?.explorerFindAddressByHash?.node, [data]);

  return (
    <BalanceCard
      network={network}
      loading={loading}
      total={Number(node?.total || 0)}
      totalIn={Number(node?.totalIn || 0)}
      totalOut={Number(node?.totalOut || 0)}
    />
  );
};
