import { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const IconAnalyzer: FC<IconProps> = ({ width = 32, height = 32, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
      {...rest}
    >
      <rect width="32" height="32" rx="10" fill="url(#paint0_linear_1745_87852)" />
      <path
        d="M16.0001 24.3333C20.6025 24.3333 24.3334 20.6023 24.3334 16C24.3334 11.3976 20.6025 7.66663 16.0001 7.66663C11.3977 7.66663 7.66675 11.3976 7.66675 16C7.66675 20.6023 11.3977 24.3333 16.0001 24.3333Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5315 12.4664L17.7648 17.7664L12.4648 19.5331L14.2315 14.2331L19.5315 12.4664Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1745_87852"
          x1="16.4"
          y1="32"
          x2="16.4"
          y2="-2.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.236288" stopColor="#8883E8" />
          <stop offset="1" stopColor="#D8D3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
