import React, { FC } from 'react';
import { Maybe, ZoomParamsTypeInput } from '@apolloGenerated';
import Octavius, { ZoomParamsType } from '@rubin-dev/octavius';
import { LinkItem, NodeItem } from '@graph/types/graph';
import { EventName, useGraphStore } from '@graph/libs';
import { stopEvent } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

export type VisualizationViewProps = {
  nodes: NodeItem[];
  links: LinkItem[];
  canvas: Maybe<ZoomParamsTypeInput>;
};

export type VisualizationEvents = {
  onClickGraph(item: any): void;
  onRightClickGraph(e: MouseEvent, item: any): false;
  onUpdateGraph(type: 'nodes' | 'canvas'): (item: any) => void;
};
export const VisualizationView: FC<VisualizationViewProps> = ({
  nodes,
  canvas,
  links,
}) => {
  const { sendEvent } = useGraphStore();
  const handleUpdateGraph: VisualizationEvents['onUpdateGraph'] = (type) => (params) => {
    switch (type) {
      case 'nodes':
        sendEvent({ type: EventName.DATA_SAVE_POSITION, params });
        break;
      case 'canvas':
        sendEvent({ type: EventName.DATA_SAVE_CANVAS, params });
        break;
    }
  };
  const handleClickItem: VisualizationEvents['onClickGraph'] = (item) => {
    if (item?.type) {
      sendEvent({ type: EventName.WINDOW_SHOW }, true);
      sendEvent({ type: EventName.GRAPH_CLICK, params: { item } });
    }
  };
  const handleRightClickItem: VisualizationEvents['onRightClickGraph'] = (e, item) => {
    stopEvent(e);
    sendEvent({
      type: EventName.GRAPH_RIGHT_CLICK,
      params: { item, coords: { x: e.clientX, y: e.clientY } },
    });
    return false;
  };

  return (
    <div className={styles.visualization}>
      <Octavius
        nodes={nodes}
        lines={links}
        canvasStyle={(canvas || {}) as ZoomParamsType}
        handlerOnClick={handleClickItem}
        handlerRightClick={handleRightClickItem}
        handlerDoubleClick={handleRightClickItem}
        callbackOnUpdate={handleUpdateGraph('nodes')}
        canvasCoordinatesUpdate={handleUpdateGraph('canvas')}
      />
    </div>
  );
};
