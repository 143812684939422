export enum AddressSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export enum AddressColor {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
