import React, { FC } from 'react';
import { Redirect } from 'react-justanother-router';
import * as gqlTypes from '../apolloGenerated';
import { RouterName } from '../router';

export type HomepageScreenProps = {
  user?: gqlTypes.User;
};

export const HomepageScreen: FC<HomepageScreenProps> = (): JSX.Element => {
  return <Redirect to={RouterName.Analyzer} />;
};
