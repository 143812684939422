import React, { FC } from 'react';
import { Link } from 'react-justanother-router';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { RouterName } from '../../router/types';
export const PageNotFound: FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.staticPage404}>
      <div className={styles.staticPage404__textWrapper}>
        <h1 className={styles.staticPage404__title}>404</h1>
        <h3 className={styles.staticPage404__subtitle}>{t('strings.pageNotFound')}</h3>
      </div>

      <div className={styles.staticPage404__footer}>
        <p> {t('strings.takeMeBack')}</p>
        <Link className={styles.staticPage404__link} to={RouterName.Analyzer}>
          {t('titles.analyzer')}
        </Link>
      </div>
    </div>
  );
};
