import React, { FC } from 'react';
import { Typography, WithCloseModal, Dialog } from '@rubin-dev/goblin';
import styles from './styles.module.scss';
import { TariffApi } from '../../../../screen';
import { useTranslation } from 'react-i18next';

export type TariffsProps = {};
declare global {
  interface ModalProps {
    modalMarkupTariffs: TariffsProps;
  }
}
export const ModalMarkupTariffs: FC<WithCloseModal<TariffsProps>> = () => {
  const { t } = useTranslation();
  return (
    <Dialog
      width={982}
      titleCenter
      title={
        <div className={styles.mentions__modalHeader}>
          <Typography tag="h3" variant="head-24" color="on-surface-primary-1">
            {t('markup.getAccess')}
          </Typography>
          <Typography variant={'body-16'}>{t('markup.subscribe')}</Typography>
        </div>
      }
      closed
    >
      <TariffApi />
    </Dialog>
  );
};
