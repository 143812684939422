import Cookie from 'js-cookie';
import * as gqlTypes from '../../apolloGenerated';
import { apolloClient } from '../../apollo';
import * as query from '../../query';
import { Maybe, MeDocument } from '../../apolloGenerated';

export const USER_LOCALSTORAGE_KEY = 'auth';

let localUserHook: gqlTypes.Jwt | null = null;

export const hasPermission = (permission: string): boolean => {
  const jwtUser = loadUser();
  if (!jwtUser) {
    return false;
  }
  return (jwtUser.permissions || []).indexOf(permission) > -1;
};

export const hasPermissions = (permissions: string[]): boolean => {
  for (let i = 0; i < permissions.length; i++) {
    if (!hasPermission(permissions[i])) {
      return false;
    }
  }

  return true;
};

export const fetchUser = async (
  successCallback?: Maybe<(data: gqlTypes.MeQuery) => void>,
  errorCallback?: Maybe<() => void>,
) => {
  try {
    const { data } = await apolloClient.query<gqlTypes.MeQuery>({
      query: MeDocument,
    });
    if (data && successCallback) successCallback(data!);
  } catch (e) {
    if (errorCallback) errorCallback();
  }
};
export const loadUser = (): gqlTypes.Jwt | null => {
  if (localUserHook) {
    return localUserHook;
  }

  const base64token = Cookie.get(USER_LOCALSTORAGE_KEY);
  if (!base64token) {
    return null;
  }

  try {
    return JSON.parse(window.atob(base64token));
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const logoutUser = (): void => {
  localUserHook = null;
  Cookie.remove(USER_LOCALSTORAGE_KEY);
};

export const persistUser = (data: gqlTypes.Jwt): void => {
  localUserHook = data;
  Cookie.set(USER_LOCALSTORAGE_KEY, window.btoa(JSON.stringify(data)));
};

export const setAuthorizationHeaders = (
  headers?: object,
  token?: string,
  refreshToken?: string,
) => {
  if (typeof headers === 'undefined') {
    headers = {};
  }

  if (
    (!token || Object.prototype.hasOwnProperty.call(headers, 'Authorization')) &&
    !refreshToken
  ) {
    return headers;
  }

  return {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
};

export const getNewTokenDirect = async (): Promise<gqlTypes.Jwt | null | undefined> => {
  const user: gqlTypes.Jwt | null = loadUser();

  if (!user) {
    return new Promise((resolve, reject) => reject('user not found'));
  }

  try {
    const resp = await apolloClient.mutate<
      gqlTypes.RefreshTokenMutation,
      gqlTypes.RefreshTokenMutationVariables
    >({
      mutation: query.refreshToken,
      context: {
        headers: {
          Authorization: `Bearer ${user.refreshToken}`,
        },
      },
    });

    const errors = resp.data?.refreshToken.errors || {};
    if (Object.keys(errors).length > 0) {
      console.error(errors);
      throw new Error('refresh token error');
    }

    return resp.data?.refreshToken.result;
  } catch (e) {
    console.error(e);
    return null;
  }
};
