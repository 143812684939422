import React, { FC, useEffect, useState } from 'react';
import { OverLimitTable } from './OverLimit';
import { CleanDataTable } from './CleanDataTable';
import { BlockedDataTable } from './BlockedDataTable';
import { useTranslation } from 'react-i18next';
import { Tabs, TabItem, Divider } from '@rubin-dev/goblin';
import styles from './transactions.module.scss';
import sdk from '@aml/sdk';
import { history, router, RouterName } from '../../router';
import { Container } from '@shared/ui';

enum TransactionsScreenTabs {
  OVER_LIMIT = 'over-limit',
  CLEAN = 'clean',
  BLOCKED = 'blocked',
}

export type DashboardFilters = {
  dateFrom?: string;
  dateTo?: string;
  hash?: string;
};
export type TransactionsScreenProps = {
  query: DashboardFilters;
  params: {
    type?: TransactionsScreenTabs;
  };
};
export const TransactionsScreen: FC<TransactionsScreenProps> = ({
  params: { type },
  query,
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(type || TransactionsScreenTabs.OVER_LIMIT);
  const { data, refetch } = sdk.dashboard.queries.useGetTransactionTotals();

  useEffect(() => {
    if (!type) handleChangeTab(tab);
  }, []);

  const handleChangeTab = (tab: TransactionsScreenTabs) => {
    history.navigate(
      router.urlFor(RouterName.TransactionsType, {
        type: tab,
      }),
    );
    setTab(tab);
  };

  return (
    <>
      <Container>
        <Tabs className={styles.transactionsScreen__tabs} onChange={handleChangeTab}>
          <TabItem
            value={TransactionsScreenTabs.OVER_LIMIT}
            label={`${t('titles.OVER_LIMIT')} (${data?.over_limit || 0})`}
          />
          <TabItem
            value={TransactionsScreenTabs.CLEAN}
            label={`${t('titles.CLEAN')} (${data?.clean || 0})`}
          />
          <TabItem
            value={TransactionsScreenTabs.BLOCKED}
            label={`${t('titles.BLOCKED')} (${data?.blocked || 0})`}
          />
        </Tabs>
      </Container>
      <Divider />
      <Container className={styles.transactionsScreen__content}>
        {tab === TransactionsScreenTabs.OVER_LIMIT && (
          <OverLimitTable updateTotal={refetch} initFilters={query} />
        )}
        {tab === TransactionsScreenTabs.CLEAN && <CleanDataTable initFilters={query} />}
        {tab === TransactionsScreenTabs.BLOCKED && (
          <BlockedDataTable initFilters={query} />
        )}
      </Container>
    </>
  );
};
