import { FC } from 'react';
import styles from './styles.module.scss';
import { Maybe } from '@rubin-dev/goblin';

export type LineContentWrapProps = {
  amountSlot?: Maybe<JSX.Element>;
  annotationSlot?: Maybe<JSX.Element>;
};
export const LineContentWrap: FC<LineContentWrapProps> = ({
  amountSlot,
  annotationSlot,
}) => {
  return (
    <div className={styles.lineContentWrap}>
      <div className={styles.lineContentWrap__amount}>{amountSlot}</div>
      {annotationSlot}
    </div>
  );
};
