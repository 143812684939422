import React, { FC, useRef } from 'react';
import {
  DropdownItem,
  EditIcon,
  TrashIcon,
  Typography,
  useOnClickOutside,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { useGraphAnnotation } from '@graph/libs/hooks';
import { TableType } from '@graph/types';
import styles from './styles.module.scss';
import { NodeMenuProps } from '@graph-visualization-module/features/ContextMenu/ui/NodeMenu/NodeMenu';

export const LabelNodeMenu: FC<
  NodeMenuProps & { node: { parentHash: string; text: string }; onSubmit(): void }
> = ({ node, onSubmit }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    onAddNodeAnnotation,
    onAddLineAnnotation,
    onRemoveNodeAnnotation,
    onRemoveLineAnnotation,
  } = useGraphAnnotation();

  const handleEditAnnotation = () => {
    onSubmit();
    switch (node.type) {
      case TableType.Annotation:
        return onAddNodeAnnotation(node?.parentHash, node.uuid);
      case TableType.AnnotationLink:
        return onAddLineAnnotation(node?.parentHash);
    }
  };
  const handleRemoveAnnotation = () => {
    onSubmit();
    switch (node.type) {
      case TableType.Annotation:
        return onRemoveNodeAnnotation(node.uuid, node.parentHash);
      case TableType.AnnotationLink:
        return onRemoveLineAnnotation(node.uuid, node.parentHash);
    }
  };

  useOnClickOutside(dropdownRef, () => onSubmit());

  return (
    <div>
      {node.type !== TableType.AnnotationLink && (
        <Typography
          variant="body-14"
          color="on-surface-secondary-2"
          className={styles.annotation__content}
        >
          {node?.text}
        </Typography>
      )}
      <div className={styles.annotation__dropdown}>
        <DropdownItem
          label={t('buttons.edit')}
          icon={EditIcon}
          fullWidth
          onClick={handleEditAnnotation}
        />
        <DropdownItem
          label={t('buttons.delete')}
          icon={TrashIcon}
          fullWidth
          onClick={handleRemoveAnnotation}
        />
      </div>
    </div>
  );
};
