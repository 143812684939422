import i18n from '../../../../../../../i18n/i18n';
import { tableStyles } from '@graph-sidebar-module/shared';
import { FEATURE_CLUSTERING } from '@graph/const/config.const';

export const PAGE_SIZE = 10;
export const headersSent = () => [
  {
    title: '',
    value: 'checkbox',
    width: 20,
    className: tableStyles.table__checkbox,
    hide: !FEATURE_CLUSTERING,
  },
  { title: i18n.t('strings.received'), value: 'hash' },
  {
    title: i18n.t('strings.amount'),
    value: 'amount',
    width: 106,
  },
  { title: '', value: 'hashUniq', key: true, hide: true },
];

export const headersReceive = () => [
  {
    title: '',
    value: 'checkbox',
    width: 20,
    className: tableStyles.table__checkbox,
    hide: !FEATURE_CLUSTERING,
  },
  { title: i18n.t('strings.sending'), value: 'hash' },
  {
    title: i18n.t('strings.amount'),
    value: 'amount',
    width: 106,
  },
  { title: '', value: 'hashUniq', key: true, hide: true },
];
