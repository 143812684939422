import React, { FC, PropsWithChildren } from 'react';
import { Header } from '../Header/Header';
import * as gqlTypes from '../../../../apolloGenerated';
import { ArrowLeftDenseIcon, Button, Typography } from '@rubin-dev/goblin';
import { useCurrentRoute } from 'react-justanother-router';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Container } from '@shared/ui';
import { useGoBack } from '@hooks/useGoBack';
import cx from 'classnames';

export type BaseProps = {
  header?: JSX.Element;
  user?: Omit<gqlTypes.User, 'permissions' | 'billing'>;
  dense?: boolean;
};

export const Base: FC<PropsWithChildren<BaseProps>> = ({
  children,
  user,
  header,
  dense,
}): JSX.Element => {
  const goPrevPage = useGoBack();
  const { t } = useTranslation();
  const { currentRoute } = useCurrentRoute();

  return (
    <div className={cx(styles.base, dense && styles.base_dense)}>
      <div style={{ zIndex: 1 }}>
        <Header user={user} dense={dense} />
      </div>
      <main className={styles.base__main}>
        {currentRoute?.props?.translate && (
          <Container className={styles.base__header}>
            <Typography
              className={styles.base__title}
              variant="head-32"
              tag="h1"
              color="on-surface-primary-1"
            >
              {currentRoute?.props?.hasBack && (
                <Button
                  onClick={() => goPrevPage()}
                  variant="outlined"
                  icon
                  prependIcon={ArrowLeftDenseIcon}
                />
              )}
              {t(currentRoute.props.translate as string, '')}
            </Typography>
            {header && <div className={styles.base__headerSlot}>{header}</div>}
          </Container>
        )}
        {children}
      </main>
    </div>
  );
};
