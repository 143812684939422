import React, { FC, PropsWithChildren } from 'react';

export const LayoutClean: FC<PropsWithChildren<any>> = ({ children }): JSX.Element => {
  return (
    <div className="b-auth">
      <div className="b-auth__container">
        <div className="b-auth__helper">{children}</div>
      </div>
    </div>
  );
};
