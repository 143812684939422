import { AddressTable, NodeList, LinkService } from '@graph/models';

export class AddressBetweenList {
  static tableHashList: Record<string, AddressTable> = {};

  static create(tableItem: AddressTable) {
    AddressBetweenList.tableHashList[tableItem.idN] = tableItem;
  }

  static getTableDataByHash(hash: string): AddressTable {
    const tableItem = AddressBetweenList.tableHashList[hash];
    if (tableItem) this.addCheckedByNode(tableItem);
    return tableItem;
  }

  private static addCheckedByNode(item: AddressTable) {
    const { source } = LinkService.getAddressesByHash(item.idN || ',');
    item.clearChecked();
    item.setChecked(NodeList.getTableDataByHash(source).checked);
  }
}
