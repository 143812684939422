import { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { CardTariff } from './CardTariff';

export const TariffApi: FC = () => {
  const { t } = useTranslation();

  const cardsData = [
    {
      title: t('titles.base'),
      hasLabel: false,
      mainAmountInYear: '300',
      subtitle: `0.3 USDT ${t('titles.forChecking')}`,
      conditions: [
        `1 000 ${t('titles.checking')} MS`,
        `${t('titles.checkingOverLimit')} 0,4 USDT`,
      ],
    },
    {
      title: t('titles.advanced'),
      hasLabel: true,
      mainAmountInYear: '2 000',
      subtitle: `0.2 USDT ${t('titles.forChecking')}`,
      conditions: [
        `10 000 ${t('titles.checking')} MS`,
        `${t('titles.checkingOverLimit')} 0.3 USDT`,
      ],
    },
    {
      title: t('titles.pro'),
      hasLabel: false,
      mainAmountInYear: '8 000',
      subtitle: `0.16 USDT ${t('titles.forChecking')}`,
      conditions: [
        `50 000 ${t('titles.checking')} MS`,
        `${t('titles.checkingOverLimit')} 0.2 USDT`,
      ],
    },
  ];

  return (
    <div className={styles['tariffs']}>
      <div className={styles['tariffs__group']}>
        {cardsData &&
          cardsData.map((item, i) => (
            <CardTariff
              key={i}
              title={item.title}
              hasLabel={item.hasLabel}
              mainAmountInYear={item.mainAmountInYear}
              subtitle={item.subtitle}
              conditions={item.conditions}
            />
          ))}
      </div>
    </div>
  );
};
