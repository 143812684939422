import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import {
  ArrowRightDenseIcon,
  Button,
  LoaderOverlay,
  Driver,
  Spacer,
} from '@rubin-dev/goblin';

export type GraphWindowProps = {
  isOpen: boolean;
  onClose(): void;
  headerSlot?: JSX.Element;
  width: number;
  isLoading?: boolean;
};
export const GraphWindow: FC<PropsWithChildren<GraphWindowProps>> = ({
  isOpen,
  onClose,
  children,
  headerSlot,
  width,
  isLoading,
}) => {
  return (
    <div className={cx(styles.graphWindow, isOpen && styles.graphWindow_opened)}>
      <Button
        className={styles.graphWindow__close}
        variant="outlined"
        prependIcon={ArrowRightDenseIcon}
        onClick={onClose}
      />
      <LoaderOverlay show={isLoading} />
      <Driver
        width={width}
        headerSlot={
          <div className={styles.graphWindow__head}>
            {headerSlot}
            <Spacer size={10} />
          </div>
        }
        px={0}
        className={styles.graphWindow__content}
        trackClassName={styles.graphWindow__track}
      >
        <div className={styles.graphWindow__wrap}>{children}</div>
      </Driver>
    </div>
  );
};
