import { FC, useContext, useMemo } from 'react';
import { isArray } from '@rubin-dev/goblin';
import { AppContext } from '../../../contexts';
import { CalculateItem } from '../../../apolloGenerated';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Dict } from '@aml/sdk/risk';
import { useTranslation } from 'react-i18next';

interface Props {
  data: Dict;
  items?: (CalculateItem & { colorRisk?: number })[];
}

enum RiskLevel {
  lowRisk = 'lowRisk',
  mediumRisk = 'mediumRisk',
  highRisk = 'highRisk',
}

export const DescriptionCategories: FC<Props> = ({ data, items }) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  const filterData = useMemo(() => {
    const itemNumbers = items?.map((item) => item.number) || [];
    const filterParams = Object.values(data?.params || {});

    return filterParams
      .map((item) => {
        const findItem = items?.find((el) => el.number === item.number);
        return {
          ...item,
          risk: Number(findItem?.colorRisk),
          currentRisk: Number(findItem?.risk),
        };
      })
      .filter(
        (item) => itemNumbers.includes(item.number) && Number(item?.currentRisk) > 0.01,
      );
  }, [data, items]);

  type RiskItem = { title: string; description: string; risk: number };
  type FilteredDataType = Partial<Record<RiskLevel, RiskItem[]>>;
  const formattedItems = useMemo<FilteredDataType>(() => {
    if (!data) return {};

    const items = filterData.reduce((acc: FilteredDataType, item) => {
      const addRiskItem = (key: keyof FilteredDataType, data: RiskItem) =>
        (acc[key] = [...(acc[key] || []), data]).sort(
          (a, b) => Number(b.risk) - Number(a.risk),
        );

      const getRiskKey = (risk: number): keyof FilteredDataType => {
        if (risk <= 30) return RiskLevel.lowRisk;
        if (31 <= risk && risk <= 70) return RiskLevel.mediumRisk;
        return RiskLevel.highRisk;
      };

      addRiskItem(getRiskKey(item.risk), {
        title: item.name || '',
        description: (language === 'ru' ? item.i18n?.ru : item.i18n?.en) || '',
        risk: item.risk,
      });

      return acc;
    }, {});

    return {
      ...(items?.highRisk ? { highRisk: items.highRisk } : {}),
      ...(items?.mediumRisk ? { mediumRisk: items.mediumRisk } : {}),
      ...(items?.lowRisk ? { lowRisk: items.lowRisk } : {}),
    };
  }, [items, data]);

  const styles = StyleSheet.create({
    container: {
      marginTop: 14,
    },
    wrapper: {
      marginBottom: 14,
    },
    title: {
      fontSize: 12,
      color: '#B8433D',
      marginBottom: 3,
    },
    group: {
      flexDirection: 'row',
      marginBottom: 4,
      paddingVertical: 4,
      borderBottomWidth: 1,
      borderColor: '#dfe2eb',
      flexWrap: 'wrap',
    },
    subtitle: {
      fontSize: 10,
      marginRight: 6,
    },
    text: {
      fontSize: 10,
      flexWrap: 'wrap',
      color: '#798597',
    },
  });

  const getColor = (key: RiskLevel) => {
    if (RiskLevel.lowRisk === key) return '#32841d';
    if (RiskLevel.mediumRisk === key) return '#ffb400';

    return '#de3a3a';
  };

  const count = Object.values(formattedItems).flatMap((item) => item);

  let counter = 0;

  return (
    <View style={styles.container}>
      <View>
        <Text style={[styles.title, { color: '#000', marginBottom: 10 }]}>
          Match Systems Identifies {count?.length} Money Laundering Risk Sources
        </Text>
      </View>
      {(formattedItems ? Object.entries(formattedItems || {}) : []).map(
        ([key, items]) => (
          <View key={key} style={styles.wrapper}>
            <Text style={[styles.title, { color: getColor(key as RiskLevel) }]}>
              {t(`pdf.${key}`, 'unknown error')}
            </Text>
            <View>
              {isArray(items) &&
                (
                  items as {
                    title: string;
                    description: string;
                    risk: number;
                  }[]
                ).map((item) => {
                  counter++;
                  return (
                    <View key={item.title} style={styles.group}>
                      <Text style={[styles.text, { marginRight: 8 }]}>{counter}.</Text>
                      <Text style={styles.subtitle}>{item.title}:</Text>
                      <Text style={styles.text}>{item.description}</Text>
                    </View>
                  );
                })}
            </View>
          </View>
        ),
      )}
    </View>
  );
};
