import { gql } from '@apollo/client';

export const subscribe = gql`
  mutation subscribe($input: SubscribeInput!) {
    subscribe(input: $input)
  }
`;

export const unsubscribe = gql`
  mutation unsubscribe($input: SubscribeInput!) {
    unsubscribe(input: $input)
  }
`;

export const subscribed = gql`
  query subscribed($input: SubscribeInput!) {
    subscribed(input: $input)
  }
`;

export const subscriptionList = gql`
  query subscriptionList($page: Int!, $pageSize: Int!) {
    subscriptionList(page: $page, pageSize: $pageSize) {
      errors
      total
      edge {
        network
        address
        createdAt
        isViewed
        hasResult
      }
    }
  }
`;
