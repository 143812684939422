import { FC } from 'react';
import { Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import styles from './risk-score.module.scss';
import cx from 'classnames';

export const RiskScoreInformation: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.riskScore}>
      <Typography variant={'body-16'} color={'on-surface-primary-2'}>
        {t('strings.riskscore')}:
      </Typography>
      <span className={styles.riskScore__item}>
        <i className={cx(styles.riskScore__circle, styles.riskScore__circle_green)} />
        <Typography variant={'body-14'} color={'on-surface-primary-1'}>
          0-25%
        </Typography>
      </span>
      <span className={styles.riskScore__item}>
        <i className={cx(styles.riskScore__circle, styles.riskScore__circle_orange)} />
        <Typography variant={'body-14'} color={'on-surface-primary-1'}>
          26-74%
        </Typography>
      </span>
      <span className={styles.riskScore__item}>
        <i className={cx(styles.riskScore__circle, styles.riskScore__circle_red)} />
        <Typography variant={'body-14'} color={'on-surface-primary-1'}>
          75-100%
        </Typography>
      </span>
    </div>
  );
};
