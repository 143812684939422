import { SUBSCRIPTION_EXPLORER_MARKUP, USER_ADMIN } from '@constants/permissions.const';
import i18n from '../../i18n/i18n';

export const permissionsList = [
  'api.dashboard',
  'subscription.explorer.mentions',
  SUBSCRIPTION_EXPLORER_MARKUP,
  USER_ADMIN,
];

export const HEADER_TABLE_USERS = [
  { title: i18n.t('titles.nameEmail'), value: 'nameEmail', width: 200 },
  { title: '', value: 'e_1', width: 78 },
  { title: i18n.t('titles.enableProducts'), value: 'enableProducts', width: 124 },
  { title: '', value: 'e_2', width: 78 },
  {
    title: i18n.t('titles.subscriptionAnalyzer'),
    value: 'subscriptionAnalyzer',
    width: 114,
  },
  { title: '', value: 'e_3', width: 78 },
  {
    title: i18n.t('titles.checksMarkup'),
    value: 'checksMarkup',
    width: 71,
  },
  { title: '', value: 'e_4', width: 78 },
  {
    title: i18n.t('titles.checksAPIAddress'),
    value: 'checksAPIAddress',
    width: 98,
  },
  { title: '', value: 'e_5', width: 78 },
  {
    title: i18n.t('titles.checksAPITransaction'),
    value: 'checksAPITransaction',
    width: 121,
  },
  { title: '', value: 'e_6', width: 24 },
  {
    title: '',
    value: 'actions',
    width: 56,
  },
];
