import React, { FC, FormEvent, useEffect, useState, useMemo } from 'react';
import {
  Typography,
  WithCloseModal,
  Dialog,
  Button,
  Checkbox,
  Divider,
  TextField,
  InfoSlabDenseIcon,
  CopyHash,
} from '@rubin-dev/goblin';
import { ActivateUser } from './ActivateUser';
import { useTranslation } from 'react-i18next';
import { useModal } from '@hooks/useModal';
import styles from './styles/editUser.module.scss';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { IconAnalyzer, IconApi, IconMarkup, IconUser } from '@shared/ui';
import * as gqlTypes from '@apolloGenerated';
import {
  USER_ADMIN,
  SUBSCRIPTION_EXPLORER_MARKUP,
  API_DASHBOARD,
} from '@constants/permissions.const';
import {
  useBillingAnalyzerSubscribeMutation,
  useGeneratePasswordMutation,
  User,
} from '@apolloGenerated';

export type editUser = {
  userId?: number;
  name?: string;
  email?: string;
  commonData?: User;
  isCreate?: boolean;
  onSuccess?: () => void;
};
declare global {
  interface ModalProps {
    editUserDialog: editUser;
  }
}
export const EditUserDialog: FC<WithCloseModal<editUser>> = ({
  userId,
  name,
  email,
  isCreate,
  onSuccess,
  commonData,
  onClose,
}) => {
  const { t } = useTranslation();
  const openRemoveUserDialog = useModal('removeUserDialog');
  const [currentEmail, setEmail] = useState<string>(email || '');
  const [currentName, setName] = useState<string>(name || '');
  const [addressPacket, setAddressPacket] = useState<string>('');
  const [transactionPacket, setTransactionPacket] = useState<string>('');
  const [markupPacket, setMarkupPacket] = useState<string>('');

  const [dateSubscribe, setDateSubscribe] = useState<DateTime | number>();

  const [checked, setChecked] = useState<string[]>([]);

  const [mutate] = gqlTypes.useSetUserPermissionsMutation({});
  const [mutateUserCreate, { loading }] = gqlTypes.useCreateMutation({});

  const [generateMutate, { data: generateData, loading: loadingPassword }] =
    useGeneratePasswordMutation({});

  const [subscribeMutate, { loading: SubscribeLoading }] =
    useBillingAnalyzerSubscribeMutation({});

  const [billingAddPacketMutation, { loading: addPacketLoading }] =
    gqlTypes.useBillingAddPacketMutation();

  const password = useMemo(
    () => generateData?.generatePassword?.password,
    [generateData],
  );

  useEffect(() => {
    if (commonData?.permissions) {
      setChecked(commonData.permissions);
    }
  }, [commonData]);

  const removeUser = () => {
    if (!userId || !onSuccess) return;

    openRemoveUserDialog({
      userId,
      updateUserList: onSuccess,
    });
  };

  const onChange = (permission: string) => {
    if (checked.includes(permission)) {
      setChecked([...checked, permission].filter((item) => item !== permission));
    } else {
      setChecked([...checked, permission]);
    }
  };

  const onChangeDateSubscription = async (date: DateTime[]) => {
    setDateSubscribe(
      date.length && date[0].toMillis() >= DateTime.now().minus({ day: 1 }).toMillis()
        ? date[0].toMillis() / 1000
        : 0,
    );
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (isCreate) {
      await mutateUserCreate({
        variables: {
          input: {
            email: currentEmail,
            name: currentName,
            permissions: checked,
          },
        },
      });
    } else if (userId) {
      await subscribeMutate({
        variables: {
          userId,
          expireDate: dateSubscribe || 0,
        },
      });

      if (checked.length) {
        await mutate({
          variables: {
            input: {
              userId,
              permissions: checked,
            },
          },
        });
      }

      if (addressPacket) {
        await billingAddPacketMutation({
          variables: {
            userId,
            requests: parseInt(addressPacket, 10),
            requestType: gqlTypes.BillingRequestType.Address,
          },
        });
      }

      if (markupPacket) {
        await billingAddPacketMutation({
          variables: {
            userId,
            requests: parseInt(markupPacket, 10),
            requestType: gqlTypes.BillingRequestType.Markup,
          },
        });
      }

      if (transactionPacket) {
        await billingAddPacketMutation({
          variables: {
            userId,
            requests: parseInt(transactionPacket, 10),
            requestType: gqlTypes.BillingRequestType.Transaction,
          },
        });
      }
    }

    if (onSuccess) {
      onSuccess();
    }

    onClose();
  };

  const generatePassword = async () => {
    if (userId) {
      await generateMutate({
        variables: {
          input: {
            userId,
          },
        },
      });
    }
  };

  return (
    <Dialog
      width={830}
      title={
        <div>
          <Typography tag="h3" variant="head-24" color="on-surface-primary-1">
            {isCreate ? t('titles.createNewUser') : name}
          </Typography>
        </div>
      }
      closed
    >
      <form className={styles.editUser__form} onSubmit={onSubmit}>
        <div className={styles.editUser__wrapper}>
          <div>
            {isCreate ? (
              <div className={cx(styles.editUser__group, styles.editUser__group_column)}>
                <TextField
                  name="name"
                  label={t('titles.name')}
                  value={currentName}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  name="email"
                  type={'email'}
                  label={t('titles.email')}
                  value={currentEmail.toLowerCase()}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className={cx(styles.editUser__subtitle)}>
                  <InfoSlabDenseIcon fill={'#A3A9BD'} color={'#fff'} />
                  <Typography variant={'body-14'} color={'on-surface-primary-1'}>
                    {t('titles.userPassword')}
                  </Typography>
                </div>
              </div>
            ) : (
              <>
                <div>
                  <Typography variant={'body-14'} color={'on-surface-primary-1'}>
                    {t('titles.email')}: {email}
                  </Typography>
                  <Button
                    type="button"
                    variant={'text'}
                    onClick={removeUser}
                    className={styles.editUser__btn_text}
                  >
                    <Typography variant={'body-12'} color={'on-surface-secondary-1'}>
                      {t('titles.deleteUser')}
                    </Typography>
                  </Button>
                </div>
                <div>
                  <Typography variant={'body-14'} className={styles.editUser__subtitle}>
                    {t('titles.password')}: {password && <CopyHash hash={password} />}
                  </Typography>
                  <Button
                    variant={'text'}
                    loading={loadingPassword}
                    className={styles.editUser__btn_text}
                    onClick={generatePassword}
                    type={'button'}
                  >
                    <Typography variant={'body-12'} color={'primary-1'}>
                      {t('titles.generateNew')}
                    </Typography>
                  </Button>
                </div>
              </>
            )}
          </div>
          <div>
            <div className={styles.editUser__group}>
              <div className={styles['editUser__icon-group']}>
                <IconAnalyzer width={24} height={24} />
                <Typography variant={'head-16'}>{t('titles.analyzer')}</Typography>
              </div>
              <div className={styles.editUser__rows}>
                <div className={styles.editUser__items}>
                  <ActivateUser
                    disabled={isCreate}
                    subscriptionExpiryDate={
                      commonData?.billing.analyzerSubscriptionExpireDate
                    }
                    onChangeDate={(date) => onChangeDateSubscription(date)}
                  />
                </div>
              </div>
            </div>
            <Divider className={styles.editUser__divider} />
            <div className={styles.editUser__group}>
              <div className={styles['editUser__icon-group']}>
                <IconMarkup width={24} height={24} />
                <Typography variant={'head-16'}>{t('titles.markup')}</Typography>
              </div>
              <div className={styles.editUser__rows}>
                <Checkbox
                  checked={checked.includes(SUBSCRIPTION_EXPLORER_MARKUP) || false}
                  onChange={() => onChange(SUBSCRIPTION_EXPLORER_MARKUP)}
                  label={SUBSCRIPTION_EXPLORER_MARKUP}
                />
                <div className={styles.editUser__items}>
                  <div>
                    <div className={styles.editUser__checks}>
                      <div>
                        <Typography
                          variant={'body-14'}
                          color={
                            isCreate ? 'on-surface-primary-3' : 'on-surface-primary-1'
                          }
                        >
                          {t('strings.checks')}:
                        </Typography>
                        <Typography
                          variant={'head-14'}
                          color={
                            isCreate ? 'on-surface-primary-3' : 'on-surface-primary-1'
                          }
                        >
                          {commonData?.billing.markup || 0}
                        </Typography>
                      </div>
                      <TextField
                        disabled={isCreate}
                        value={markupPacket.toLowerCase()}
                        onChange={(e) => setMarkupPacket(e.target.value)}
                        placeholder={`+ ${t('strings.add')}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider className={styles.editUser__divider} />
            <div className={styles.editUser__group}>
              <div className={styles['editUser__icon-group']}>
                <IconApi width={24} height={24} />
                <Typography variant={'head-16'}>{t('titles.api')}</Typography>
              </div>
              <div className={styles.editUser__rows}>
                <Checkbox
                  checked={checked.includes(API_DASHBOARD) || false}
                  onChange={() => onChange(API_DASHBOARD)}
                  label={API_DASHBOARD}
                />
                <div className={styles.editUser__checks}>
                  <div>
                    <Typography
                      variant={'body-14'}
                      color={isCreate ? 'on-surface-primary-3' : 'on-surface-primary-1'}
                    >
                      {t('strings.checksAddresses')}:
                    </Typography>
                    <Typography
                      variant={'head-14'}
                      color={isCreate ? 'on-surface-primary-3' : 'on-surface-primary-1'}
                    >
                      {commonData?.billing.address || 0}
                    </Typography>
                  </div>
                  <TextField
                    disabled={isCreate}
                    value={addressPacket.toLowerCase()}
                    onChange={(e) => setAddressPacket(e.target.value)}
                    placeholder={`+ ${t('strings.add')}`}
                  />
                </div>
                <div className={styles.editUser__checks}>
                  <div>
                    <Typography
                      variant={'body-14'}
                      color={isCreate ? 'on-surface-primary-3' : 'on-surface-primary-1'}
                    >
                      {t('strings.checksTransactions')}:
                    </Typography>
                    <Typography
                      variant={'head-14'}
                      color={isCreate ? 'on-surface-primary-3' : 'on-surface-primary-1'}
                    >
                      {commonData?.billing.transactions || 0}
                    </Typography>
                  </div>
                  <TextField
                    disabled={isCreate}
                    value={transactionPacket.toLowerCase()}
                    onChange={(e) => setTransactionPacket(e.target.value)}
                    placeholder={`+ ${t('strings.add')}`}
                  />
                </div>
              </div>
            </div>
            <Divider className={styles.editUser__divider} />
            <div className={styles.editUser__group}>
              <div className={styles['editUser__icon-group']}>
                <IconUser />
                <Typography variant={'head-16'}>{t('titles.admin')}</Typography>
              </div>
              <Checkbox
                checked={checked.includes(USER_ADMIN) || false}
                onChange={() => onChange(USER_ADMIN)}
                label={USER_ADMIN}
              />
            </div>
          </div>
        </div>
        <Button
          size="large"
          color="primary"
          fullWidth
          type="submit"
          loading={loading || SubscribeLoading || addPacketLoading}
          disabled={!currentEmail || !currentName}
        >
          {isCreate ? t('titles.createUser') : t('buttons.apply')}
        </Button>
      </form>
    </Dialog>
  );
};
