import { create } from 'zustand';
import { Maybe } from '@apolloGenerated';

export enum GraphCrashStatus {
  NOT_FOUND_SAVED,
}
export type GraphStatusStoreType = {
  crashMessage: Maybe<GraphCrashStatus>;
  loadingCounter: number;
  isSaving: boolean;
  isLoading: boolean;
  isInitialized: boolean;
  isCrash: boolean;
  setSaving(saving: boolean): void;
  setCrash(message?: GraphCrashStatus): void;
  setLoading(loading: boolean): void;
  setInitialized(initialized: boolean): void;
};
export const useGraphStatusStore = create<GraphStatusStoreType>()((set) => ({
  crashMessage: null,
  loadingCounter: 0,
  isSaving: false,
  isLoading: false,
  isInitialized: false,
  isCrash: false,
  setLoading: (isLoading) =>
    set((st) => {
      const counter = isLoading ? st.loadingCounter + 1 : st.loadingCounter - 1;
      return {
        ...st,
        loadingCounter: counter,
        isLoading: !!counter,
      };
    }),
  setInitialized: (isInitialized) => set((st) => ({ ...st, isInitialized })),
  setSaving: (isSaving) => set((st) => ({ ...st, isSaving })),
  setCrash: (message?: GraphCrashStatus) =>
    set((st) => ({
      ...st,
      isCrash: true,
      crashMessage: message,
      isInitialized: false,
      isLoading: false,
      isSaving: false,
    })),
}));
