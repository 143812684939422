import { gql } from '@apollo/client';

export const restoreCheck = gql`
  mutation restoreCheck($input: RestoreCheckInput!) {
    restoreCheck(input: $input) {
      state
      errors
    }
  }
`;
