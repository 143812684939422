import React, { FC } from 'react';
import { Link } from 'react-justanother-router';
import { RouterName } from '../../router';
import { bem } from 'classnames-bem';
import { useTranslation } from 'react-i18next';
import styles from './styles/markup.module.scss';
import { Typography } from '@rubin-dev/goblin';

interface Props {}
export const RegistrationComponent: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant={'head-20'} color={'neutrals-1'}>
        {t('explorer.markup_registration')}
      </Typography>
      <div className={styles['markup__controls']}>
        <Link className="b-mention__btn" to={RouterName.AuthLogin}>
          {t('titles.login')}
        </Link>
        <Link
          className={bem('b-mention__btn', 'outline')}
          to={RouterName.AuthRegistration}
        >
          {t('titles.registration')}
        </Link>
      </div>
    </>
  );
};
