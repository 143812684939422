import { FC, MouseEvent, useState } from 'react';
import { stopEvent, TextField } from '@rubin-dev/goblin';
import { Meta } from '@graph/models';
import styles from './styles.module.scss';
import { useModal } from '@hooks/useModal';

export interface GraphNameInputProps {
  appendSlot?: JSX.Element;
}
export const GraphNameInput: FC<GraphNameInputProps> = ({ appendSlot }) => {
  const [graphName, setGraphName] = useState(Meta.graphName);
  const openRenameGraphModal = useModal('renameGraph');
  const handleClickInput = (e: MouseEvent) => {
    stopEvent(e);
    openRenameGraphModal({
      onSuccess: (val) => {
        setGraphName(val);
        Meta.graphName = val;
      },
      uuid: Meta.uuid,
      initName: graphName,
    });
  };

  return (
    <div>
      <TextField
        className={styles.graphNameInput}
        fullWidth
        placeholder={graphName}
        ellipsis
        autoComplete="off"
        readonly
        onClick={handleClickInput}
        appendSlot={appendSlot}
      />
    </div>
  );
};
