import { useEffect } from 'react';
import { EventName, useGraphStore } from '@graph/libs';
import { useSidebarWindowStore } from '../store/windowStore';

export type UseSidebarWindowType = [
  {
    onHide(): void;
  },
  {
    isShow: boolean;
  },
];
export const useSidebarWindow = (): UseSidebarWindowType => {
  const { event } = useGraphStore();
  const { setShow, isShow } = useSidebarWindowStore();

  useEffect(() => {
    switch (event?.type) {
      case EventName.WINDOW_SHOW:
        setShow(true);
        break;
      case EventName.WINDOW_CLOSE:
        setShow(false);
        break;
    }
  }, [event]);

  return [{ onHide: () => setShow(false) }, { isShow }];
};
