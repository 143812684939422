import React, { FC, PropsWithChildren } from 'react';
import { LayoutUser } from '../../../Layout';
import { Button, PlusIcon, Spacer } from '@rubin-dev/goblin';
import { RouterName } from '../../../../router';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-justanother-router';

interface LayoutMarkupProps {
  query: {
    query: string;
  };
}

export const LayoutMarking: FC<PropsWithChildren<LayoutMarkupProps>> = ({
  children,
  query,
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();

  const openCreatePage = () => {
    navigate(RouterName.MarkingCreate, {}, { query: query?.query });
  };

  const renderAddMark = (): JSX.Element => (
    <Button prependIcon={PlusIcon} color={'secondary'} onClick={openCreatePage}>
      {t('marking.add')}
    </Button>
  );

  return (
    <LayoutUser headerSlot={renderAddMark()}>
      {children}
      <Spacer size={24} />
    </LayoutUser>
  );
};
