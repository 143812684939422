import i18n from 'i18next';

export const translateLinks = () => [
  {
    title: i18n.t('titles.analyzer'),
    link: 'https://steadfast-literature-8ee.notion.site/AML-Analyzer-062831164d1d4ebbb942e4b4274257a8',
  },
  {
    title: i18n.t('titles.markup'),
    link: 'https://steadfast-literature-8ee.notion.site/AML-Markup-6e8b93abf0554b42b1f0b26a64766b0b',
  },
  {
    title: i18n.t('titles.api'),
    link: 'https://steadfast-literature-8ee.notion.site/AML-API-and-Transaction-Dashboard-6b8102ba6c5748d28369db9c6826bf97',
  },
];
export const translateLinksRU = () => [
  {
    title: i18n.t('titles.analyzer'),
    link: 'https://steadfast-literature-8ee.notion.site/AML-plainchain-ru-d7b03d258fc447bb8fc1bbdcb9616ae1?pvs=4',
  },
  {
    title: i18n.t('titles.markup'),
    link: 'https://steadfast-literature-8ee.notion.site/AML-plainchain-ru-914576b394d54c3c942340aad2e30e9c',
  },
  {
    title: i18n.t('titles.api'),
    link: 'https://steadfast-literature-8ee.notion.site/AML-API-plainchain-ru-c45bc40b17e44598a7f0c0a5e852af14',
  },
];
