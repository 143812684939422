import React, { FC, useState, MouseEvent } from 'react';
import {
  Button,
  CopyIcon,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownList,
  EditIcon,
  ExportIcon,
  MoreIcon,
  stopEvent,
  TrashIcon,
} from '@rubin-dev/goblin';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useModal } from '@hooks/useModal';
import { useGraphIntegration } from '../../../../libs/hooks/useGraphIntegration';

export type GraphsListControlDropdownProps = {
  uuid: string;
  name: string;
  refetch(): void;
  onChange(val: boolean): void;
};
enum GraphItemActions {
  DELETE,
  DUPLICATE,
  EXPORT,
  RENAME,
}
export const GraphsListControlDropdown: FC<GraphsListControlDropdownProps> = ({
  uuid,
  refetch,
  name,
  onChange,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [{ exportJSONByUuid, duplicateData }] = useGraphIntegration();
  const openRenameModal = useModal('renameGraph');
  const openRemoveModal = useModal('removeGraph');

  const handleClickItem = (type: GraphItemActions) => async (e: MouseEvent) => {
    stopEvent(e);
    switch (type) {
      case GraphItemActions.DELETE:
        handleClickRemove();
        break;
      case GraphItemActions.RENAME:
        handleClickRename();
        break;
      case GraphItemActions.EXPORT:
        await handleClickExport();
        break;
      case GraphItemActions.DUPLICATE:
        await handleClickDuplicate();
        refetch();
        break;
    }
    handleChangeDropdown(false);
  };
  const handleClickRename = () =>
    openRenameModal({ uuid, initName: name, onSuccess: () => refetch() });
  const handleClickRemove = () =>
    openRemoveModal({ uuid, name, onSuccess: () => refetch() });
  const handleClickExport = () => exportJSONByUuid(uuid);
  const handleClickDuplicate = () => duplicateData(uuid);

  const handleChangeDropdown = (val: boolean) => {
    setIsOpen(val);
    onChange(val);
  };

  return (
    <Dropdown
      value={isOpen}
      className={styles.graphsListDropdown}
      targetSlot={
        <Button
          icon
          prependIcon={MoreIcon}
          variant="outlined"
          active={isOpen}
          onClick={stopEvent}
        />
      }
      onChange={handleChangeDropdown}
      placement="bottom-end"
    >
      <DropdownList className={styles.graphsListDropdown__content}>
        <DropdownItem
          icon={EditIcon}
          fullWidth
          onClick={handleClickItem(GraphItemActions.RENAME)}
        >
          {t('buttons.rename')}
        </DropdownItem>
        <DropdownItem
          icon={CopyIcon}
          fullWidth
          onClick={handleClickItem(GraphItemActions.DUPLICATE)}
        >
          {t('buttons.duplicate')}
        </DropdownItem>
        <DropdownItem
          icon={ExportIcon}
          fullWidth
          onClick={handleClickItem(GraphItemActions.EXPORT)}
        >
          {t('buttons.export')} JSON
        </DropdownItem>
        <Divider className={styles.graphsListDropdown__divider} />
        <DropdownItem
          icon={TrashIcon}
          fullWidth
          onClick={handleClickItem(GraphItemActions.DELETE)}
        >
          {t('buttons.delete')}
        </DropdownItem>
      </DropdownList>
    </Dropdown>
  );
};
