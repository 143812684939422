import { FC, PropsWithChildren, useMemo } from 'react';
import { Typography, TypographyProps, CopyHash } from '@rubin-dev/goblin';
import cx from 'classnames';
import styles from '../Mention/mention.module.scss';
import { TypographyColorType } from '@rubin-dev/goblin/dist/ui/cjs/types/ui/components/Typography';

export type MentionRowProps = {
  title: string;
  color?: TypographyColorType;
  link?: boolean;
  href?: string;
  copyValue?: string;
};
export const MentionRow: FC<PropsWithChildren<MentionRowProps>> = ({
  children,
  title,
  link,
  color,
  href,
  copyValue,
}) => {
  const isLink = useMemo((): TypographyProps & {
    tag: string;
    target?: string;
    href?: string;
    copy?: boolean;
  } => {
    if (link) {
      return {
        tag: 'a',
        variant: 'head-16',
        target: '_blank',
        color: 'primary-1',
        href,
      };
    }

    return {
      tag: 'div',
      variant: 'head-16',
    };
  }, [link]);

  return (
    <div className={styles['mention__row']}>
      <Typography
        variant="body-14"
        className={cx(styles['mention__item'], styles['mention__item_surface'])}
        color={'on-surface-primary-2'}
      >
        {title}
      </Typography>
      <div className={styles['mention__group']}>
        <Typography
          className={cx(styles['mention__item'], styles['mention__item_gray'])}
          color={color}
          {...isLink}
        >
          {children}
        </Typography>
        {!!copyValue && (
          <CopyHash
            className={styles['mention__copy']}
            defaultColor={'primary-1'}
            hash={String(copyValue)}
          />
        )}
      </div>
    </div>
  );
};
