import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const PoliceIcon: FC<IconProps> = ({ width = 24, height = 24, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_101_4662)">
        <rect x="8" y="8" width="22" height="22" fill="#BBC7D7" />
        <path
          d="M34.6665 10.0002C34.6665 5.87355 36.1465 3.85856 36.1599 3.84356C36.4165 3.51192 36.3865 3.0402 36.0899 2.74356L33.5899 0.243578C33.3283 -0.0180614 32.9249 -0.0747798 32.6015 0.101938C32.5715 0.116938 29.6932 1.66693 26.3332 1.66693C22.9916 1.66693 20.0933 0.116938 20.0649 0.101938C19.8165 -0.0314207 19.5149 -0.0330613 19.2683 0.101938C19.2383 0.116938 16.3599 1.66693 12.9999 1.66693C9.65832 1.66693 6.75998 0.116938 6.73162 0.101938C6.40826 -0.0730611 6.00498 -0.0164208 5.74498 0.245296L3.245 2.74528C2.95164 3.03864 2.92164 3.50864 3.17164 3.84028C3.18664 3.86028 4.66663 5.87526 4.66663 10.0019C4.66663 11.8769 4.36327 13.1769 4.01163 14.6819C3.53664 16.7135 3 19.0168 3 23.3352C3 31.2518 9.1566 34.8801 19.2965 39.9151C19.4132 39.9701 19.5399 40.0001 19.6665 40.0001C19.7932 40.0001 19.9199 39.9701 20.0365 39.9134C30.1765 34.8785 36.3331 31.2501 36.3331 23.3335C36.3331 19.0135 35.7947 16.7119 35.3215 14.6802C34.9715 13.1768 34.6665 11.8769 34.6665 10.0002ZM28.5899 17.2568L24.7566 21.0901L26.2549 27.0834C26.3266 27.2068 26.3666 27.3484 26.3666 27.5001C26.3666 27.9667 25.9082 28.3667 25.5166 28.3334H25.4999C25.3583 28.3334 25.215 28.2968 25.0866 28.2234L19.6666 25.1268L14.2467 28.2234C13.9567 28.3884 13.5967 28.3651 13.33 28.1634C13.0634 27.9618 12.9434 27.6218 13.025 27.2968L14.5767 21.0868L10.7433 17.2535C10.52 17.0301 10.4417 16.7001 10.5417 16.4001C10.6417 16.1001 10.9017 15.8835 11.2133 15.8385L16.5833 15.0718L18.8999 9.66852C19.1615 9.05517 20.1699 9.05517 20.4315 9.66852L22.7482 15.0718L28.1181 15.8385C28.4315 15.8835 28.6915 16.1001 28.7898 16.4001C28.8899 16.7018 28.8116 17.0335 28.5899 17.2568Z"
          fill="#D7DEE9"
        />
      </g>
      <defs>
        <clipPath id="clip0_101_4662">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
