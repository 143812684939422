import React, { FC } from 'react';
import { Link } from 'react-justanother-router';
import { RouterName } from '../../../../router/types';
import { Divider, Paper, Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export type ProfileMainInfoProps = {
  name: string;
  email: string;
};

export const ProfileMainInfo: FC<ProfileMainInfoProps> = ({ name, email }) => {
  const { t } = useTranslation();

  const [emailName, domain] = email.split('@');
  return (
    <Paper className={styles.profileMain} py={4} px={12}>
      <div className={styles.profileMain__row}>
        <Typography variant="body-14" color="on-secondary-2">
          {t('titles.name')}
        </Typography>
        <Typography variant="body-14" color="on-surface-primary-1">
          {name}
        </Typography>
      </div>
      <Divider />
      <div className={styles.profileMain__row}>
        <Typography variant="body-14" color="on-secondary-2">
          E-mail
        </Typography>
        <Typography variant="body-14" color="on-surface-primary-1">
          {emailName.substring(0, 2)}...@{domain}
        </Typography>
      </div>
      <Divider />
      <div className={styles.profileMain__row}>
        <Typography variant="body-14" color="on-secondary-2">
          {t('titles.password')}
        </Typography>
        <Typography variant="body-14" color="primary-1">
          <Link to={RouterName.AuthRestore}>Change password</Link>
        </Typography>
      </div>
      <Divider />
    </Paper>
  );
};
