import { FC } from 'react';
import {
  IconProps,
  PortugalIcon,
  RussiaIcon,
  SpainIcon,
  UkIcon,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { PROJECT_TYPE, ProjectType } from '@shared/libs';

export type LangType = 'ru' | 'en' | 'es' | 'pt';
export type LangItemType = {
  label: string;
  iconComponent: FC<IconProps>;
};
export type LangListType = Partial<Record<LangType, LangItemType>>;
export const LANGS: LangType[] = ['ru', 'en', 'es', 'pt'];
export const useLangListConst = (): LangListType => {
  const { t } = useTranslation();
  const en: LangItemType = { label: t('strings.english'), iconComponent: UkIcon };
  const es: LangItemType = { label: t('strings.spanish'), iconComponent: SpainIcon };
  const pt: LangItemType = {
    label: t('strings.portuguese'),
    iconComponent: PortugalIcon,
  };
  const ru: LangItemType = {
    label: t('strings.russian'),
    iconComponent: RussiaIcon,
  };
  const LANG_LIST_BY_VITE_TYPE: Record<ProjectType, LangListType> = {
    plainchain: {
      ru,
    },
    matchsystems: {
      en,
      es,
      pt,
    },
  };
  return LANG_LIST_BY_VITE_TYPE[PROJECT_TYPE];
};
export const LANG_STORAGE_KEY = `lng-${PROJECT_TYPE}`;
