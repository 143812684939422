import { FC } from 'react';
import { MarkingCreateComponent } from '@component/Marking/MarkingCreateComponent';

interface Props {
  query: {
    query: string;
  };
}

export const MarkingCreateScreen: FC<Props> = ({ query }) => {
  return <MarkingCreateComponent address={query.query} />;
};
