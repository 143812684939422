import { FC } from 'react';
import { Button, SidebarDenseIcon } from '@rubin-dev/goblin';
import { EventName, useGraphStore } from '@graph/libs';

export const GraphSidebarButton: FC = () => {
  const { sendEvent } = useGraphStore();
  return (
    <Button
      variant="outlined"
      icon
      prependIcon={SidebarDenseIcon}
      onClick={() => sendEvent({ type: EventName.WINDOW_SHOW })}
    />
  );
};
