import { useContext } from 'react';
import { ModalContextType, ModalContext } from '@rubin-dev/goblin';

export const useModal = <T extends keyof ModalProps>(
  modalType: T,
  modalConfig?: ModalContextType['modalConfig'],
) => {
  const { showModal } = useContext<ModalContextType>(ModalContext);
  return (modalProps?: ModalProps[T]) => {
    showModal(modalType, modalProps, modalConfig!);
  };
};
