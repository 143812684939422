import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormGenericData,
  LoaderOverlay,
  Spacer,
  TextField,
  useForm,
} from '@rubin-dev/goblin';

export type AuthRestoreConfirmFormProps = FormGenericData<AuthRestoreConfirmFormInput>;

export type AuthRestoreConfirmFormInput = {
  password: string;
  passwordConfirm: string;
};

const defaultValues: AuthRestoreConfirmFormInput = {
  password: '',
  passwordConfirm: '',
};

export const AuthRestoreConfirmForm: FC<AuthRestoreConfirmFormProps> = ({
  formOnSubmit,
  loading,
  initialValues,
}): JSX.Element => {
  const { t } = useTranslation();
  const { onChange, onSubmit, values, errors } = useForm<AuthRestoreConfirmFormInput>(
    formOnSubmit,
    {
      ...defaultValues,
      ...initialValues,
    },
  );

  return (
    <form onSubmit={onSubmit}>
      <TextField
        label={t('titles.password')}
        fullWidth
        size="large"
        autoFocus
        type="password"
        onChange={onChange}
        name="password"
        error={errors?.password}
        id="password"
        initValue={values.password}
      />
      <Spacer size={12} />
      <TextField
        fullWidth
        size="large"
        type="password"
        label={t('titles.passwordconf')}
        onChange={onChange}
        name="passwordConfirm"
        error={errors?.passwordConfirm}
        id="passwordConfirm"
        initValue={values.passwordConfirm}
      />
      <Spacer size={12} />
      <Button type="submit">{t('titles.savenewpasswd')}</Button>
      <LoaderOverlay show={loading} />
    </form>
  );
};
