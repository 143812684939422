import React, { FC, PropsWithChildren } from 'react';
import { LayoutUser } from '@component/Layout';
import { ExplorerTabs, ExplorerTabsEnum } from '@component/explorer';
import { Spacer } from '@rubin-dev/goblin';

export const LayoutExplorerGraphs: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LayoutUser>
      <ExplorerTabs initTab={ExplorerTabsEnum.Graphs} />
      {children}
      <Spacer size={24} />
    </LayoutUser>
  );
};
