import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from '@aml/amount';
import { useActivityPeriod } from '@component/Address/libs/hooks/useActivityPeriod';
import { Text, StyleSheet, View } from '@react-pdf/renderer';
import { Cluster, Maybe } from '@apolloGenerated';

interface Props {
  address: string;
  network: string;
  balance?: {
    balance: string | number;
    balanceIn: string | number;
    balanceOut: string | number;
  };
  risk?: number;
  categoryName?: string;
  transaction?: {
    total: string | number;
    totalSent: string | number;
    totalReceived: string | number;
  };
  activity?: {
    first: string;
    last: string;
  };
  cluster?: Maybe<Cluster>;
}

const styles = StyleSheet.create({
  address: {
    fontSize: 14,
    marginBottom: 12,
  },
  groupHorizontal: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  groupBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  groupVertical: {
    flexDirection: 'column',
    paddingRight: 6,
    paddingLeft: 6,
    borderRightWidth: 1,
    borderColor: '#dfe2eb',
    marginBottom: 12,
  },
  title: {
    fontSize: 8,
    color: '#69789F',
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 8,
    color: '#69789F',
    marginRight: 8,
  },
  text: {
    fontSize: 8,
    color: '#000',
  },
  textSmall: {
    fontSize: 8,
  },
  textRed: {
    fontSize: 8,
    color: '#B8433D',
  },
  riskDot: {
    width: 4,
    height: 4,
    borderRadius: '50%',
  },
});

const getRiskColor = (riskValue: number): string => {
  if (riskValue <= 25) return '#3D7A21';
  if (riskValue >= 75) return '#B8433D';
  return '#ffb400';
};

export const Header: FC<Props> = ({
  network,
  address,
  risk,
  balance,
  categoryName,
  transaction,
  activity,
  cluster,
}) => {
  const { t } = useTranslation();
  const getActivityPeriod = useActivityPeriod();

  return (
    <>
      <Text style={styles.address}>{address}</Text>
      <View style={styles.groupHorizontal}>
        {/* RISK */}
        {!!risk && (
          <View style={styles.groupVertical}>
            <Text style={styles.title}>{t('strings.riskscore')}:</Text>
            <View style={[styles.groupHorizontal, { alignItems: 'center' }]}>
              <Text
                style={[
                  styles.riskDot,
                  { backgroundColor: getRiskColor(risk), marginRight: '4px' },
                ]}
              ></Text>
              <Text style={styles.textSmall}>{risk}%</Text>
            </View>
          </View>
        )}
        {/* CLUSTER */}
        {!!cluster && (
          <View style={styles.groupVertical}>
            <Text style={styles.title}>{t('mention.cluster')}:</Text>
            <Text style={[styles.textRed, { color: '#034AB3' }]}>
              {String(cluster?.owner) || 'Entity'}
            </Text>
          </View>
        )}
        {/* CATEGORY */}
        {!!categoryName && (
          <View style={styles.groupVertical}>
            <Text style={styles.title}>{t('strings.reported')}:</Text>
            <Text style={styles.textRed}>{categoryName}</Text>
          </View>
        )}

        {/* TRANSACTION */}
        {!!transaction?.total && (
          <View style={styles.groupVertical}>
            <Text style={styles.title}>{t('strings.transactions')}:</Text>
            <View style={styles.groupBetween}>
              <Text style={styles.subtitle}>{t('strings.total')}:</Text>
              <Text style={styles.textSmall}>{format(transaction.total, 0)}</Text>
            </View>
            <View style={styles.groupBetween}>
              <Text style={styles.subtitle}>{t('strings.sent')}:</Text>
              <Text style={styles.textSmall}>{format(transaction.totalSent, 0)}</Text>
            </View>
            <View style={styles.groupBetween}>
              <Text style={styles.subtitle}>{t('strings.received')}:</Text>
              <Text style={styles.textSmall}>{format(transaction.totalReceived, 0)}</Text>
            </View>
            <View style={styles.groupBetween}>
              <Text style={styles.subtitle}>{t('titles.transactionsExcluded')}</Text>
            </View>
          </View>
        )}

        {/* BALANCE */}
        {!!balance?.balance && (
          <View style={styles.groupVertical}>
            <Text style={styles.title}>{t('strings.balance')}:</Text>
            <View style={styles.groupBetween}>
              <Text style={styles.subtitle}>{t('strings.total')}:</Text>
              <Text style={styles.textSmall}>{`${
                balance.balance
              } ${network.toUpperCase()}`}</Text>
            </View>
            <View style={styles.groupBetween}>
              <Text style={styles.subtitle}>{t('strings.sent')}:</Text>
              <Text style={styles.textSmall}>{`${
                balance.balanceOut
              } ${network.toUpperCase()}`}</Text>
            </View>
            <View style={styles.groupBetween}>
              <Text style={styles.subtitle}>{t('strings.received')}:</Text>
              <Text style={styles.textSmall}>{`${
                balance.balanceIn
              } ${network.toUpperCase()}`}</Text>
            </View>
            <View style={styles.groupBetween}>
              <Text style={styles.subtitle}>{t('titles.transactionsExcluded')}</Text>
            </View>
          </View>
        )}

        {/* ACTIVITY */}
        {!!activity && (
          <View style={[styles.groupVertical, { borderWidth: 0 }]}>
            <Text style={styles.title}>{t('titles.activity')}:</Text>
            <Text style={styles.textSmall}>
              {getActivityPeriod({ first: activity.first, last: activity.last })}
            </Text>
          </View>
        )}
      </View>
    </>
  );
};
