import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationRU from './locales/ru/translation.json';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationPT from './locales/pt/translation.json';
import {
  LANGS,
  LangType,
  PROJECT_TYPE,
  isProd,
  ProjectType,
  LANG_STORAGE_KEY,
} from '@shared/libs';

const resourcesPlainchain: Resource = {
  ru: {
    translation: translationRU,
  },
};

const resourcesMatchsystems: Resource = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
};

const resources: Record<ProjectType, Resource> = {
  matchsystems: resourcesMatchsystems,
  plainchain: resourcesPlainchain,
};

i18n.on('languageChanged', (lng) => {
  localStorage.setItem(LANG_STORAGE_KEY, lng);
});

export const languageInstall = (): LangType => {
  const storageLang = localStorage.getItem(LANG_STORAGE_KEY) as LangType;
  if (storageLang && LANGS.includes(storageLang)) return storageLang;
  switch (PROJECT_TYPE) {
    case 'matchsystems':
      return 'en';
    case 'plainchain':
      return 'ru';
    default:
      return 'en';
  }
};

// @ts-ignore
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    lng: languageInstall(),
    debug: !isProd,
    resources: resources[PROJECT_TYPE],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
    saveMissingTo: 'all',
  });
// @ts-ignore
window.i18n = i18n;

export default i18n;
