import { gql } from '@apollo/client';
import { userFragment } from './fragments';

export const me = gql`
  ${userFragment}
  query me {
    me {
      id
      name
      email
      isActive
      createdAt
      updatedAt
      billing {
        ...BillingFields
      }
    }
  }
`;
