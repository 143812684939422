import i18n from 'i18next';

export const headers = () => [
  { title: i18n.t('titles.date'), value: 'date', width: '6%' },
  { title: i18n.t('links.category'), value: 'category', width: '8%' },
  { title: i18n.t('titles.source'), value: 'source', width: '10%' },
  { title: i18n.t('titles.source_address'), value: 'source_address', width: '10%' },
  { title: i18n.t('titles.label'), value: 'label', width: '10%' },
  { title: i18n.t('titles.proofs'), value: 'proofs', width: '10%' },
];
