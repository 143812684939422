import {
  Redirect,
  RendererFunction,
  RouterEngine,
  RouterEngineInterface,
} from 'react-justanother-router';
import { routes } from './routes';
import { fetchUser, hasPermissions, loadUser } from '@utils/auth';
import { RouterHistory } from '@utils/RouterHistory';
import React, { createElement } from 'react';
import { RouterName } from './types';
import { history } from './history';

type RenderRouteProps = {
  layout?: string;
  auth?: boolean;
};
export const router: RouterEngineInterface = new RouterEngine(routes);
export const renderer: RendererFunction<RenderRouteProps> = (
  match,
): JSX.Element | null => {
  const props = {
    query: match.query,
    params: match.params,
  };

  const user = loadUser();
  if (
    (match.props?.auth && !user) ||
    (match.props?.permissions && !hasPermissions(match.props?.permissions))
  ) {
    return <Redirect to={RouterName.AuthLogin} />;
  }
  if (match.props?.auth)
    fetchUser(null, () => {
      history.navigate(router.urlFor(RouterName.AuthLogin));
    });

  RouterHistory.add({
    name: match.name!,
    isLoginPage: !!match.props?.login,
    ...props,
  });

  const target = createElement(match.component, props);
  if (match.props?.layout) {
    return createElement(match.props?.layout, props, target);
  }

  return target;
};
