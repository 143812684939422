import React, { FC, useContext, useMemo, useState, ChangeEvent } from 'react';
import {
  Dialog,
  Table,
  Typography,
  WithCloseModal,
  LoaderOverlay,
  Button,
  DialogFooter,
  RadioButtons,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import sdk from '@aml/sdk';
import styles from '../Category/styles.module.scss';
import { RiskDot } from '@component/NewRisk';
import { AppContext } from '../../contexts';
import cls from './marking.module.scss';

interface Props {
  onCallback: (category: { id: number; name: string }) => void;
}

declare global {
  interface ModalProps {
    dialogCategoryList: Props;
  }
}

export const DialogCategoryList: FC<WithCloseModal<Props>> = ({
  onClose,
  onCallback,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<{ id: number; name: string }>();
  const { data: categoryDict, isLoading } =
    sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  const headers = [
    { title: '', value: 'check' },
    { title: t('links.category'), value: 'name' },
    { title: t('strings.riskscore'), value: 'risk', width: '120px' },
    { title: t('mention.description'), value: 'description', width: '55%' },
  ];

  const { language } = useContext(AppContext);

  const categoryList = useMemo(
    () =>
      (categoryDict ? Object.values(categoryDict.params || {}) : []).sort(
        (curItem, nextItem) => (curItem.name || '').localeCompare(nextItem.name || ''),
      ),
    [categoryDict],
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked({
      id: Number(e.target.id),
      name: e.target.name,
    });
  };

  const items = useMemo(
    () =>
      categoryList.map(({ number, name, color, risk, i18n }) => {
        return {
          check: (
            <>
              <RadioButtons
                id={String(number)}
                name={name}
                checked={checked?.id === number && checked?.name === name}
                onChange={onChange}
              />
            </>
          ),
          name: (
            <div className={styles.category__dotItem}>
              <div className={styles.category__dot} style={{ background: color }} />
              <Typography variant="body-14" color="neutrals-8">
                {name}
              </Typography>
            </div>
          ),
          risk: (
            <div className={styles.category__dotItem}>
              <RiskDot risk={Number(risk || 0)} />
              <Typography variant="body-14" color="neutrals-8">
                {risk || 0}%
              </Typography>
            </div>
          ),
          description: (language === 'ru' ? i18n?.ru : i18n?.en) || '-',
        };
      }),
    [categoryList, checked],
  );

  const checkedCategory = () => {
    if (checked) {
      onCallback(checked);
    }

    onClose();
  };

  return (
    <Dialog
      width={1274}
      className={cls.marking__categories}
      title={
        <Typography tag="h3" variant="head-24" color="on-surface-primary-1">
          {t('links.category')}
        </Typography>
      }
      footerSlot={
        <DialogFooter
          fullWidth
          className={cls.marking__dialogFooter}
          startSlot={
            <Button fullWidth onClick={checkedCategory}>
              {t('marking.saveCategory')}
            </Button>
          }
        />
      }
      closed
    >
      <div className={cls.marking__categories}>
        <Table items={items} headers={headers} />
      </div>
      <LoaderOverlay show={isLoading} />
    </Dialog>
  );
};
