import { FC, useRef } from 'react';
import { Typography, ArrowBottomLongIcon, Loader } from '@rubin-dev/goblin';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useGraphIntegration } from '../../../../libs/hooks/useGraphIntegration';
import { useTranslation } from 'react-i18next';

export type GraphsListImportButtonProps = {
  refetch(): void;
};
export const GraphsListImportButton: FC<GraphsListImportButtonProps> = ({ refetch }) => {
  const { t } = useTranslation();
  const [{ importJSON }, { isLoading }] = useGraphIntegration();
  const importRef = useRef<HTMLInputElement>(null);
  const handleChangeFileInput = async (file: File) => {
    await importJSON(file);
    refetch();
    if (importRef.current) importRef.current.value = '';
  };
  return (
    <>
      <input
        ref={importRef}
        type="file"
        accept="application/JSON"
        style={{ display: 'none' }}
        onChange={(e) => handleChangeFileInput(e.target.files![0])}
      />
      <Typography
        className={cx(styles.graphImport, isLoading && styles.graphImport_loading)}
        variant="body-16"
        onClick={() => importRef.current?.click()}
      >
        {isLoading ? <Loader size={20} /> : <ArrowBottomLongIcon />}
        {t('strings.importJson')}
      </Typography>
    </>
  );
};
