import { FC } from 'react';
import { SearchIcon } from '@rubin-dev/goblin';
import styles from './styles/markup.module.scss';

interface Props {
  onClick: () => void;
}

export const SubmitButton: FC<Props> = ({ onClick }) => {
  return <SearchIcon className={styles['markup__btn-icon']} onClick={onClick} />;
};
