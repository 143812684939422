import { FC, useMemo, useState } from 'react';
import {
  OrderDirection,
  useExplorerFindWalletAddressesCountQuery,
  useExplorerFindWalletAddressesQuery,
  WalletAddress,
  WalletAddressesOrderField,
} from '@apolloGenerated';
import { Meta } from '@graph/models';
import { Toggle, LoaderOverlay, TableController, Spacer } from '@rubin-dev/goblin';
import { renderCellAmount, renderCellHash, renderCellToken } from '@shared/ui';
import { SortParams, useSidebarData } from '@graph-sidebar-module/shared';
import {
  headersAddresses,
  PAGE_SIZE,
  TabsAddressesConst,
  TABLES_ADDRESSES_TABS,
} from '../../conts';
import styles from './styles.module.scss';

export const ClusterAddresses: FC = () => {
  const { hash, network } = useSidebarData();
  const [tab, setTab] = useState(TabsAddressesConst.ALL);
  const reportedOnly = tab === TabsAddressesConst.REPORTED;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortParams>({
    sort: 'desc',
    sortBy: WalletAddressesOrderField.LastActivity,
  });

  const { data: totalData } = useExplorerFindWalletAddressesCountQuery({
    variables: {
      network,
      filter: { wid: hash, reportedOnly },
    },
  });
  const { data, previousData, loading } = useExplorerFindWalletAddressesQuery({
    variables: {
      network,
      filter: {
        wid: hash,
        pageSize: PAGE_SIZE,
        page,
        reportedOnly,
        order: sort.sort
          ? {
              field: sort.sortBy as WalletAddressesOrderField,
              direction: sort.sort as OrderDirection,
            }
          : null,
      },
    },
  });
  const rowTemplate = (walletAddress: WalletAddress) => {
    return {
      hashUniq: walletAddress.address,
      hash: renderCellHash({
        hash: walletAddress.address,
        type: 'address',
        network,
      }),
      token: renderCellToken({ token: Meta.network }),
      balance: renderCellAmount({
        amount: walletAddress.balance || '0',
        network,
      }),
      transactions: renderCellToken({ token: String(walletAddress.transactions || 0) }),
    };
  };
  const items = useMemo(
    () =>
      (
        (data?.explorerFindWalletAddresses.edge ||
          previousData?.explorerFindWalletAddresses.edge ||
          []) as WalletAddress[]
      ).map(rowTemplate),
    [data, rowTemplate],
  );
  const total = useMemo(
    () => totalData?.explorerFindWalletAddressesCount.total,
    [totalData],
  );

  return (
    <div className={styles.clusterAddresses}>
      {loading && <LoaderOverlay show />}
      <Toggle
        initValue={tab}
        items={TABLES_ADDRESSES_TABS()}
        onChange={setTab}
        size="small"
      />
      <Spacer size={12} />
      <TableController
        data={items}
        headers={headersAddresses()}
        total={Number(total || 0)}
        pageSize={PAGE_SIZE}
        initSort={sort.sort}
        initSortBy={sort.sortBy}
        onChangePage={setPage}
        onSort={(sort, sortBy) => setSort({ sort, sortBy })}
      />
    </div>
  );
};
