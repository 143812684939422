import React, { FC, useCallback, useEffect, useState, ChangeEvent } from 'react';
import { useSubscriptionStore } from '../../libs/store';
import { useTranslation } from 'react-i18next';
import { getNetworkEnum } from '@helpers/address';
import { Button, Modal, Typography, Divider, Checkbox } from '@rubin-dev/goblin';
import styles from './styles.module.scss';
import cx from 'classnames';

export type SubscribeButtonType = {
  network: string;
  address: string;
  subscribeCallback: () => void;
};

export const SubscribeButton: FC<SubscribeButtonType> = ({
  network,
  address,
  subscribeCallback,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const { loading, isSubscribed, isSubscribedAction, subscribeAction } =
    useSubscriptionStore();

  useEffect(() => {
    isSubscribedAction(getNetworkEnum(network), address);
  }, [network, address]);

  const onSubscribe = useCallback(async () => {
    if (checked) {
      localStorage.setItem('confirm', '1');
    }

    setIsOpen(false);
    await subscribeAction(getNetworkEnum(network), address);
    subscribeCallback();
  }, [network, address, checked]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const openDialog = () => {
    if (localStorage.getItem('confirm')) {
      onSubscribe();
      return;
    }

    setIsOpen(true);
  };

  return (
    <>
      {!loading && (
        <>
          {!isSubscribed && (
            <Button color={'primary'} onClick={openDialog}>
              {t('buttons.markupRequests')}
            </Button>
          )}
          <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <div className={styles['subscribe']}>
              <div className={cx(styles['group'], styles['group_middle'])}>
                <div className={styles['group']}>
                  <Typography variant={'head-20'} className={styles['title']}>
                    {t('markup.confirmRequest')}
                  </Typography>
                  <Typography variant={'body-14'} className={styles['text-neutrals-7']}>
                    {t('markup.informBalance')}:
                  </Typography>
                </div>
                <div className={styles['controls']}>
                  <Button color={'primary'} size={'large'} onClick={onSubscribe}>
                    {t('buttons.apply')}
                  </Button>
                  <Button variant={'outlined'} size={'large'}>
                    {t('titles.cancel')}
                  </Button>
                </div>
              </div>
              <div className={styles['footer']}>
                <Divider />
                <Checkbox
                  checked={checked}
                  onChange={onChange}
                  label={t('markup.repeatQuestions')}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
