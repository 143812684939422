import React, { FC } from 'react';
import { Divider, TabItem, Tabs } from '@rubin-dev/goblin';
import { useRouter } from 'react-justanother-router';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../../../router';
import styles from './styles.module.scss';
import { Container } from '@shared/ui';

export enum ExplorerTabsEnum {
  Analyzer = 'Analyzer',
  Graphs = 'Graphs',
}
export type ExplorerTabsProps = {
  initTab: ExplorerTabsEnum;
};
export const ExplorerTabs: FC<ExplorerTabsProps> = ({ initTab }) => {
  const { t } = useTranslation();
  const { history, router } = useRouter();

  const handleChangeTab = (tab: ExplorerTabsEnum) => {
    history.navigate(router.urlFor(RouterName[tab]));
  };

  return (
    <div className={styles.explorerTabs}>
      <Container className={styles.explorerTabs__tabs}>
        <Tabs initValue={initTab} onChange={handleChangeTab}>
          <TabItem value={ExplorerTabsEnum.Analyzer} label={t('titles.explorer')} />
          <TabItem value={ExplorerTabsEnum.Graphs} label={t('titles.graphs')} />
        </Tabs>
      </Container>
      <Divider className={styles.explorerTabs__hr} />
    </div>
  );
};
