import { Network } from '@apolloGenerated';
import {
  isValidBtcAddress,
  isValidBtcTransaction,
  isValidEthAddress,
  isValidEthTransaction,
  isValidTronAddress,
  isValidTronTransaction,
} from '@aml/validation';

export const isValidAddress = (address: string, network: Network): boolean => {
  switch (network) {
    case Network.Btc:
      return isValidBtcAddress(address);
    case Network.Eth:
      return isValidEthAddress(address);
    case Network.Tron:
      return isValidTronAddress(address);
  }
  return false;
};

export const isValidTransaction = (txid: string, network: Network): boolean => {
  switch (network) {
    case Network.Btc:
      return isValidBtcTransaction(txid);
    case Network.Eth:
      return isValidEthTransaction(txid);
    case Network.Tron:
      return isValidTronTransaction(txid);
  }
  return false;
};
