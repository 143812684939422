import React, { FC, useState } from 'react';
import { WithCloseModal } from '@rubin-dev/goblin';
import { MentionComponent } from '@component/Mention';
import { detectNetworkByAddress } from '@aml/validation';
import styles from './styles/details.module.scss';
export type MarkupDetails = {
  query: {
    query: string;
    id?: number;
  };
};
export const DetailsScreen: FC<WithCloseModal<MarkupDetails>> = ({ query }) => {
  const [network] = useState<string>(String(detectNetworkByAddress(query.query)));

  return (
    <>
      <div className={styles['details']}>
        <section className={styles['details__section']}>
          <div className={styles['markup__content']}>
            <MentionComponent address={query.query} network={network} id={query?.id} />
          </div>
        </section>
      </div>
    </>
  );
};
