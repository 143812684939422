import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GraphWindow,
  Transaction,
  useSidebarData,
  useSidebarWindow,
} from '@graph-sidebar-module/shared';
import { TransactionDetailTable } from '@graph-sidebar-module/features/TransactionDetail';
import { TransactionDetailInfo } from '@graph-sidebar-module/entities/TransactionDetail';
import { DriverHeader, Spacer } from '@rubin-dev/goblin';

export type TransactionDetailWindowProps = {
  transaction: Transaction;
  onBack(): void;
};
export const TransactionDetailWindow: FC<TransactionDetailWindowProps> = ({
  transaction,
  onBack,
}) => {
  const { t } = useTranslation();
  const [{ onHide }, { isShow }] = useSidebarWindow();
  const { network, isLoading } = useSidebarData();

  return (
    <GraphWindow
      isOpen={isShow}
      onClose={onHide}
      width={664}
      isLoading={isLoading}
      headerSlot={<DriverHeader titleSlot={t('titles.trdetails')} onBack={onBack} />}
    >
      <TransactionDetailInfo txid={transaction.txid} network={network} />
      <Spacer size={20} />
      <TransactionDetailTable transaction={transaction} />
    </GraphWindow>
  );
};
