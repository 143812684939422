export const downloadFile = (content: string, fileName: string, contentType: string) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
};
export const readTextFile = (file: string | URL, callback: (text: string) => void) => {
  const rawFile = new XMLHttpRequest();
  rawFile.overrideMimeType('application/json');
  rawFile.open('GET', file, true);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4 && rawFile.status == 200) callback(rawFile.responseText);
  };
  rawFile.send(null);
};

export const getJsonFromFile = (
  file: File,
  onError?: () => void,
): Promise<Record<PropertyKey, any>> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = function () {
      try {
        resolve(JSON.parse(reader.result as string));
      } catch (e) {
        onError && onError();
        reject(e);
      }
    };

    reader.onerror = function () {
      onError && onError();
      reject(reader.error);
    };
  });
};
