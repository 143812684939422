import { Node } from './Node';
import { NodeItem } from '@graph/types';
import { AddressTable } from '@graph/models';
import { Annotation } from '@graph/models/Annotation/Annotation';

export class NodeList {
  static hashList: Record<string, Node> = {};
  static tableHashList: Record<string, AddressTable> = {};

  static create(addressItem: Node, addressTable: AddressTable, rewrite?: boolean) {
    const address = addressItem.hash;
    if (NodeList.hashList[address] && !rewrite) return;
    NodeList.hashList[address] = addressItem;
    NodeList.tableHashList[addressItem.tableHash] = addressTable;
  }

  static remove(hash: string) {
    const tableItem = NodeList.hashList[hash];
    if (!tableItem) return;
    delete NodeList.hashList[hash];
    delete NodeList.tableHashList[tableItem.hash];
  }

  static getTableDataByHash(hash: string): AddressTable {
    const tableDataHash = NodeList.hashList[hash]?.tableHash;
    return NodeList.tableHashList[tableDataHash];
  }

  static getDataForGraph(currentHash: string): NodeItem[] {
    const addressItems: Node[] = Object.values(NodeList.hashList);
    return addressItems.reduce<NodeItem[]>((acc, el) => {
      const annotationNodes: Annotation[] = Object.values(el.annotation);
      el.setNode();
      if (currentHash === el.hash) {
        el.activateNode();
        el.rerenderNode();
      }
      if (el.node) acc.push(el.node);
      if (annotationNodes.length) {
        const annotations = this.getAnnotationListForNodeGraph(annotationNodes);
        acc.push(...annotations);
      }
      return acc;
    }, []);
  }

  private static getAnnotationListForNodeGraph(
    annotationNodes: Annotation[],
  ): NodeItem[] {
    const acc: NodeItem[] = [];
    annotationNodes.forEach((el) => {
      const node = el.getNode();
      if (node) acc.push(node);
    });
    return acc;
  }

  static getCountAddresses() {
    return Object.keys(NodeList.hashList).length;
  }
}
