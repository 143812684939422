import { FC } from 'react';
import { Fade, Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { useGraphStatusStore } from '@graph/libs/store';

export const GraphSaveStatus: FC<{ className?: string }> = ({ className }) => {
  const { isSaving } = useGraphStatusStore();
  const { t } = useTranslation();
  return (
    <Fade isOpen={isSaving}>
      <Typography className={className} variant="body-12" color="on-secondary-1">
        {t('titles.saving')}...
      </Typography>
    </Fade>
  );
};
