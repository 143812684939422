import { gql } from '@apollo/client';
import { jwtFragment } from './fragments';

export const restoreConfirm = gql`
  ${jwtFragment}
  mutation restoreConfirm($input: RestoreConfirmInput!) {
    restoreConfirm(input: $input) {
      jwt {
        ...JwtFields
      }
      errors
    }
  }
`;
