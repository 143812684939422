import { NewVisualizationParams } from '../../types/graph';
import { TableType } from '@graph/types';
import { getAddressWithCorrectCase } from '@helpers/address';
import { Network } from '@apolloGenerated';

type InitHashes = Pick<NewVisualizationParams, 'uuid' | 'address' | 'txid'>;

export const detectInitType = ({ uuid, txid }: InitHashes): TableType => {
  if (uuid) return TableType.UUID;
  if (txid) return TableType.Transaction;
  return TableType.Address;
};
export const detectInitHash = (
  { uuid, txid, address }: InitHashes,
  network: Network,
): string => {
  return uuid! || txid! || getAddressWithCorrectCase(address!, network);
};
