import React, { FC, PropsWithChildren } from 'react';
import { LayoutUser } from '../LayoutUser/LayoutUser';
import { PageNotFound } from '../../../../screen';
import { hasPermission } from '@utils/auth';
import { Button } from '@rubin-dev/goblin';
import { USER_ADMIN } from '@constants/permissions.const';
import { useTranslation } from 'react-i18next';
import { useModal } from '@hooks/useModal';

type ExplorerAddressLayoutProps = {
  params: {
    address: string;
    network: string;
  };
};

export const LayoutUserCreate: FC<PropsWithChildren<ExplorerAddressLayoutProps>> = ({
  children,
}) => {
  const { t } = useTranslation();
  const openEditUserDialog = useModal('editUserDialog');

  if (!hasPermission(USER_ADMIN)) {
    return <PageNotFound />;
  }

  const openDialog = () => {
    openEditUserDialog({ isCreate: true });
  };

  return (
    <LayoutUser
      headerSlot={
        <>
          {hasPermission(USER_ADMIN) && (
            <Button
              onClick={openDialog}
              // to={RouterName.UserCreate}
              color={'secondary'}
            >
              {t('titles.createUser')}
            </Button>
          )}
        </>
      }
    >
      {children}
    </LayoutUser>
  );
};
