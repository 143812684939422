import React, { FC, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@rubin-dev/goblin';
import { useExplorerFindMentionsForAddressLazyQuery, useMeQuery } from '@apolloGenerated';
import { getNetworkEnum } from '@helpers/address';

interface Props {
  address: string;
  network: string;
}

export const MentionsTitle: FC<Props> = ({ address, network }) => {
  const { t } = useTranslation();

  const [fetchMentions, { data, loading }] = useExplorerFindMentionsForAddressLazyQuery();

  const { data: user } = useMeQuery({ fetchPolicy: 'network-only' });

  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );

  useLayoutEffect(() => {
    if (expireDate) {
      fetchMentions({
        variables: {
          network: getNetworkEnum(network),
          address,
        },
      });
    }
  }, [expireDate, address]);

  const totalMemo = useMemo(() => {
    return data?.explorerFindMentionsForAddress.edge.length || 0;
  }, [data?.explorerFindMentionsForAddress.edge]);

  return (
    <>
      {t('titles.mentions')}{' '}
      {loading ? <Loader size={16} /> : totalMemo > 0 ? `(${totalMemo})` : ''}
    </>
  );
};
