import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

export type ExplorerSearchExampleProps = {
  hash: string;
  onClick(): void;
};
export const ExplorerSearchExample: FC<ExplorerSearchExampleProps> = ({
  hash,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Typography
      className={styles.searchExample}
      variant="body-14"
      color="on-surface-primary-2"
    >
      {t('strings.example')}:{' '}
      <Typography
        className={styles.searchExample__help}
        variant="body-14"
        color="primary-1"
        tag="span"
        onClick={onClick}
        role="link"
        tabIndex={0}
      >
        {hash}
      </Typography>
    </Typography>
  );
};
