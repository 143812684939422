import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const CodeIcon: FC<IconProps> = ({ width = 24, height = 24, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...rest}
    >
      <rect
        x="0.399902"
        width="24"
        height="24"
        rx="4"
        fill="url(#paint0_linear_4005_1243)"
      />
      <path
        d="M15.7332 17L20.7332 12L15.7332 7"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06665 7L4.06665 12L9.06665 17"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4005_1243"
          x1="12.6999"
          y1="24"
          x2="12.6999"
          y2="0.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6DD4ED" />
          <stop offset="0.251884" stopColor="#1EBEDE" />
          <stop offset="0.478941" stopColor="#0FBDE0" />
          <stop offset="0.765383" stopColor="#42C9EB" />
          <stop offset="1" stopColor="#85D6F8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
