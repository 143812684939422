import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { Button, CloseIcon, PlusIcon, TextField } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import cls from './contentMarking.module.scss';
import cx from 'classnames';
import { detectNetworkByAddress } from '@aml/validation';

interface Props {
  otherAddresses?: string[];

  onChange(values: string[]): void;
}

export const OtherAddresses: FC<Props> = ({ onChange, otherAddresses }) => {
  const { t } = useTranslation();

  const [listAddresses, setListAddresses] = useState<string[]>(['']);

  useEffect(() => {
    if (!otherAddresses?.length) return;

    setListAddresses(otherAddresses);
  }, [otherAddresses]);

  const addNewAddress = () => {
    setListAddresses([...listAddresses, '']);
  };

  const onChangeAddress = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    listAddresses[index] = event.target.value;

    onChange(listAddresses);
  };

  const deleteAddress = (index: number) => {
    const newList = [...listAddresses];
    newList.splice(index, 1);

    setListAddresses(newList);
  };

  return (
    <div className={cx(cls.group, cls.group_column, cls.group_fullWidth)}>
      {listAddresses &&
        listAddresses.map((item, index) => (
          <div key={item + index} className={cx(cls.group, cls.group_fullWidth)}>
            <TextField
              name="otherAddresses"
              label={t('mention.anotherAddresses')}
              fullWidth
              error={
                item && !detectNetworkByAddress(item) ? t('errors.invalid_address') : ''
              }
              value={item}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onChangeAddress(event, index)
              }
            />
            {index === 0 ? (
              <Button
                prependIcon={PlusIcon}
                type="button"
                size="small"
                color="primary"
                onClick={addNewAddress}
              />
            ) : (
              <Button
                prependIcon={CloseIcon}
                type="button"
                size="small"
                color="danger"
                onClick={() => deleteAddress(index)}
              />
            )}
          </div>
        ))}
    </div>
  );
};
