import { ToggleItem } from '@rubin-dev/goblin';
import i18n from '../../../../../../../i18n/i18n';

export enum TabsAddressesConst {
  ALL = 'all',
  REPORTED = 'reported',
}
export const TABLES_ADDRESSES_TABS = (): ToggleItem<TabsAddressesConst>[] => [
  { value: TabsAddressesConst.ALL, label: i18n.t('strings.all') },
  {
    value: TabsAddressesConst.REPORTED,
    label: i18n.t('strings.reported'),
  },
];
