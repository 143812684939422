import styles from './styles.module.scss';
import { PropsWithNativeTypes } from '@rubin-dev/goblin';
import cx from 'classnames';
import { FC } from 'react';

export interface ColorItemProps {
  color: string;
  active?: boolean;
}
export const ColorItem: FC<PropsWithNativeTypes<ColorItemProps, HTMLDivElement>> = ({
  color,
  active,
  className,
  ...rest
}) => {
  return (
    <div
      className={cx(styles.colorItem, active && styles.colorItem_active, className)}
      {...rest}
    >
      <div className={styles.colorItem__dot} style={{ backgroundColor: `${color}` }} />
    </div>
  );
};
