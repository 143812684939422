import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  renderStatus,
  extendsRender,
  renderStatusDetails,
  extendControlRender,
  POLLING_INTERVAL,
} from './constants';
import { Maybe } from '@apolloGenerated';
import { DateTime } from 'luxon';
import { isArray } from '@apollo/client/utilities';
import { useTranslation } from 'react-i18next';
import sdk from '@aml/sdk';
import { HeaderControls } from './HeaderControls';
import {
  Informer,
  LoaderOverlay,
  SortType,
  Spacer,
  TableController,
} from '@rubin-dev/goblin';
import styles from './transactions.module.scss';
import { renderCellDate, renderCellHash } from '@shared/ui';
import { AppContext } from '../../contexts';
import { DashboardFilters } from './TransactionsScreen';
import { DashboardOrder } from './types';

type CleanTableProps = {
  initFilters: DashboardFilters;
};
const pageSize = 10;
export const CleanDataTable: FC<PropsWithChildren<CleanTableProps>> = ({
  children,
  initFilters: { hash: initHash, dateFrom: initDateFrom, dateTo: initDateTo },
}) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  const headers = [
    { title: '', value: 'extendControl', width: 40 },
    { title: t('titles.date'), value: 'date', sortable: true, width: 120 },
    { title: t('titles.hash'), value: 'hash', width: 130 },
    { title: t('titles.blockchain'), value: 'token' },
    { title: t('titles.status'), value: 'status', width: 80 },
    { title: renderStatusDetails(), value: 'status_details', width: 144 },
    { title: '', value: 'extendsRender', width: 0, className: styles.extends },
  ];

  const [page, setPage] = useState(1);
  const [orderBy, setSortBy] = useState<Maybe<DashboardOrder>>(DashboardOrder.Asc);
  const [date, setDate] = useState<DateTime[]>(
    initDateFrom && initDateTo
      ? [DateTime.fromISO(initDateFrom), DateTime.fromISO(initDateTo)]
      : [],
  );
  const [hash, setHash] = useState<string>(initHash || '');
  const [extendRow, setExtendRow] = useState<Maybe<number>>(null);
  const [params, setParams] = useState<{
    hash?: string;
    page?: number;
    pageSize?: number;
    from?: string;
    to?: string;
    status?: 'CLEAN' | 'BLOCKED' | 'OVER_LIMIT';
    orderBy?: DashboardOrder;
  }>({});

  const { data, isLoading } = sdk.dashboard.queries.useGetTransactionList(
    params.hash,
    params.page,
    params.pageSize,
    params.from,
    params.to,
    params.status,
    params.orderBy,
    {
      refetchInterval: POLLING_INTERVAL,
    },
  );

  useEffect(() => {
    setParams({
      hash,
      page,
      pageSize,
      from: isArray(date) && date?.length > 1 ? date[0].toISODate() : undefined,
      to: isArray(date) && date?.length > 1 ? date[1].toISODate() : undefined,
      status: 'CLEAN',
      orderBy: orderBy || undefined,
    });
  }, [date, page, pageSize, orderBy]);

  const edge = useMemo(() => {
    return (
      data?.transactionList?.map((item) => ({
        hash: renderCellHash({
          hash: item.tx_id || '',
          network: item.network,
          type: 'transaction',
          once: true,
        }),
        date: renderCellDate({ timestamp: item?.updated_at || 0 }),
        token: item.network!.toUpperCase(),
        status: renderStatus(item.status),
        status_details: item.checkedBy!,
        extendsRender: extendRow === item.id ? extendsRender(item) : '',
        extendControl: extendControlRender(extendRow === item.id, () =>
          extendRow ? setExtendRow(null) : setExtendRow(item.id),
        ),
      })) || []
    );
  }, [data?.transactionList, extendRow, language]);
  const total = data?.total || 0;

  const onSubmit = async () => {
    let from = undefined;
    let to = undefined;

    if (isArray(date)) {
      if (date?.length === 1) {
        setDate([date[0], date[0]]);
        from = date[0].toISODate();
        to = date[0].toISODate();
      }

      if (date?.length > 1) {
        setDate([date[0], date[1]]);
        from = date[0].toISODate();
        to = date[1].toISODate();
      }
    }

    setParams({
      hash,
      page,
      pageSize,
      from,
      to,
      status: 'CLEAN',
      orderBy: orderBy || undefined,
    });
  };

  return (
    <>
      <div className="controls">
        <HeaderControls
          date={date}
          hash={hash}
          onChangeHash={setHash}
          onChangeDate={setDate}
          onConfirm={onSubmit}
        />
        {children}
      </div>
      <div className={styles.tableWrapper}>
        <LoaderOverlay show={isLoading} />
        <Spacer size={4} />
        {!edge.length ? (
          <div className={styles.information}>
            <Informer
              type="empty"
              title={t('strings.noresults')}
              subtitle={t('explorer.noResultFound')}
            />
          </div>
        ) : (
          <TableController
            data={edge}
            headers={headers}
            total={total}
            initPage={page}
            initSort={orderBy ? (orderBy.toLowerCase() as SortType) : null}
            initSortBy={'date'}
            onSort={(sort) =>
              setSortBy(
                sort === 'asc'
                  ? DashboardOrder.Asc
                  : sort === 'desc'
                  ? DashboardOrder.Desc
                  : null,
              )
            }
            onChangePage={setPage}
            pageSize={pageSize}
            notResetPage
          />
        )}
      </div>
    </>
  );
};
