export type RouterHistoryItem = {
  name: string;
  isLoginPage: boolean;
  query: object;
  params: object;
};

export class RouterHistory {
  static history: RouterHistoryItem[] = [];

  static add(item: RouterHistoryItem) {
    RouterHistory.history.unshift(item);
  }

  static getPrevRoute(name: string, isAuth?: boolean) {
    return RouterHistory.history.find((el) => {
      if (isAuth) return !el.isLoginPage && name !== el.name;
      return name !== el.name;
    });
  }
}
