import React, { createRef, FC } from 'react';
import { LoaderOverlay, TextField, FormGenericData, useForm } from '@rubin-dev/goblin';
import { SubmitButton } from '../screen/Markup/SubmitButton';
import styles from '../screen/Markup/styles/markup.module.scss';

export type SearchGeneralFormContainerProps =
  FormGenericData<SearchGeneralFormInput> & {};

export type SearchGeneralFormInput = {
  query: string;
};

const defaultValues: SearchGeneralFormInput = {
  query: '',
};

export const FormContainer: FC<SearchGeneralFormContainerProps> = ({
  loading,
  formOnSubmit,
  initialValues,
}): JSX.Element => {
  const ref = createRef<HTMLFormElement>();
  const initialState = {
    ...defaultValues,
    ...initialValues,
  };

  const { onChange, onCmdEnterSubmit, onSubmit, onSubmitTrigger, values } =
    useForm<SearchGeneralFormInput>(formOnSubmit, initialState);

  return (
    <form
      ref={ref}
      className={styles.markup__form}
      onSubmit={onSubmit}
      onKeyDown={onCmdEnterSubmit}
      tabIndex={0}
    >
      <TextField
        id="query"
        name="query"
        value={values.query || ''}
        className={styles.markup__input}
        clearable
        prependSlot={<SubmitButton onClick={onSubmitTrigger} />}
        onChange={onChange}
        autoFocus
        size="large"
        fullWidth
      />
      <LoaderOverlay show={loading} />
    </form>
  );
};
