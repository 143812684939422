import React, { FC } from 'react';
import { TableType } from '@graph/types';
import { LineWindow } from '@graph-sidebar-module/widget/Line';
import { NodeWindow } from '@graph-sidebar-module/widget/Node';
import { DataContextType, DataContext } from '@graph-sidebar-module/shared';

export const Sidebar: FC<Partial<DataContextType>> = (params) => {
  const isNodeWindow =
    params.type === TableType.Address || params.type === TableType.Cluster;
  const isLineWindow = params.type === TableType.Transaction;

  return (
    <DataContext.Provider value={params}>
      {isLineWindow && <LineWindow />}
      {isNodeWindow && <NodeWindow />}
    </DataContext.Provider>
  );
};
