import { gql } from '@apollo/client';

export const registration = gql`
  mutation registration($input: RegistrationInput!) {
    registration(input: $input) {
      state
      errors
    }
  }
`;
