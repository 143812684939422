import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const ArrowRightDirectionIcon: FC<IconProps> = ({
  width = 26,
  height = 10,
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 10"
      fill="none"
      {...rest}
    >
      <path d="M24 5L1 5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21 1L25 5L21 9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
