import {
  AddressTransactionsFilterTxType,
  ClusterClusterTransactionsFilterTxType,
  ExplorerClusterDocument,
  ExplorerClusterQuery,
  ExplorerClusterQueryVariables,
  GraphTransactionsBetweenAddressAndClusterDocument,
  GraphTransactionsBetweenAddressAndClusterQuery,
  GraphTransactionsBetweenAddressAndClusterQueryVariables,
  GraphTransactionsBetweenAddressDocument,
  GraphTransactionsBetweenAddressQuery,
  GraphTransactionsBetweenAddressQueryVariables,
  GraphTransactionsBetweenClusterAndClusterDocument,
  GraphTransactionsBetweenClusterAndClusterQuery,
  GraphTransactionsBetweenClusterAndClusterQueryVariables,
  Maybe,
  Network,
  OrderDirection,
  TransactionsBetweenAddress,
} from '@apolloGenerated';
import { apolloClient } from '../../../../../../../apollo';
import { FEATURE_CLUSTERING } from '@graph/const/config.const';

export type LineType = 'cluster-cluster' | 'cluster-address' | 'address-address';
export type LineParams = {
  to: string;
  from: string;
  type: LineType;
};
export type LineFetchArgs = {
  from: string;
  to: string;
  direction: AddressTransactionsFilterTxType;
  page: number;
  pageSize: number;
  order: Maybe<OrderDirection>;
};
export type LineFetchResponse = {
  items: TransactionsBetweenAddress[];
  total: number;
  totalReceive: number;
  totalSent: number;
};
export class LineDataService {
  private static async fetchAddressesData(
    network: Network,
    { to, from, direction, page, pageSize, order }: LineFetchArgs,
  ): Promise<LineFetchResponse> {
    const { data } = await apolloClient.query<
      GraphTransactionsBetweenAddressQuery,
      GraphTransactionsBetweenAddressQueryVariables
    >({
      query: GraphTransactionsBetweenAddressDocument,
      variables: {
        network,
        filter: {
          srcAddress: from,
          dstAddress: to,
          direction: direction === AddressTransactionsFilterTxType.All ? null : direction,
          page,
          pageSize,
          orderBy: order,
        },
      },
    });

    return {
      items: data.explorerTransactionsBetweenAddress.edge,
      total: data.explorerTransactionsBetweenAddress.total || 0,
      totalSent: data.explorerTransactionsBetweenAddress.totalSent || 0,
      totalReceive: data.explorerTransactionsBetweenAddress.totalReceive || 0,
    };
  }
  private static async fetchClustersData(
    network: Network,
    { from, to, direction, page, pageSize }: LineFetchArgs,
  ): Promise<LineFetchResponse> {
    const clusterClusterDirection: Maybe<ClusterClusterTransactionsFilterTxType> =
      direction === AddressTransactionsFilterTxType.Receives
        ? ClusterClusterTransactionsFilterTxType.Receives
        : ClusterClusterTransactionsFilterTxType.Sent; // @TODO: нужно будет дизайн переработать под то что у кластер кластер нет all
    const { data } = await apolloClient.query<
      GraphTransactionsBetweenClusterAndClusterQuery,
      GraphTransactionsBetweenClusterAndClusterQueryVariables
    >({
      query: GraphTransactionsBetweenClusterAndClusterDocument,
      variables: {
        network,
        filter: {
          from,
          to,
          direction: clusterClusterDirection,
          page,
          pageSize,
        },
      },
    });
    return {
      items: data.explorerTransactionsBetweenClusterAndCluster.edge,
      total: 0,
      totalSent: 0,
      totalReceive: 0,
    };
  }
  private static async fetchClusterAddressData(
    network: Network,
    { from, to, direction, page, pageSize }: LineFetchArgs,
  ): Promise<LineFetchResponse> {
    const { data } = await apolloClient.query<
      GraphTransactionsBetweenAddressAndClusterQuery,
      GraphTransactionsBetweenAddressAndClusterQueryVariables
    >({
      query: GraphTransactionsBetweenAddressAndClusterDocument,
      variables: {
        network,
        filter: {
          from,
          to,
          direction,
          page,
          pageSize,
        },
      },
    });
    return {
      items: data.explorerTransactionsBetweenAddressAndCluster.edge,
      total: 0,
      totalSent: 0,
      totalReceive: 0,
    };
  }
  static async getLineParams(
    network: Network,
    fromAddress: string,
    toAddress: string,
  ): Promise<LineParams> {
    if (!FEATURE_CLUSTERING)
      return {
        type: 'address-address',
        from: fromAddress,
        to: toAddress,
      };
    const getClusterInfo = (id: string) =>
      apolloClient.query<ExplorerClusterQuery, ExplorerClusterQueryVariables>({
        query: ExplorerClusterDocument,
        variables: { network, id },
      });
    const [{ data: fromClusterData }, { data: toClusterData }] = await Promise.all([
      getClusterInfo(fromAddress),
      getClusterInfo(toAddress),
    ]);
    const fromClusterId = fromClusterData?.explorerCluster.cluster?.id;
    const toClusterId = toClusterData?.explorerCluster.cluster?.id;
    const type: LineType =
      fromClusterId && toClusterId
        ? 'cluster-cluster'
        : !fromClusterId && !toClusterId
        ? 'address-address'
        : 'cluster-address';
    return {
      type,
      from: fromClusterId || fromAddress,
      to: toClusterId || toAddress,
    };
  }
  static getLineDataFetcher(type: LineType) {
    switch (type) {
      case 'cluster-cluster':
        return LineDataService.fetchClustersData;
      case 'address-address':
        return LineDataService.fetchAddressesData;
      case 'cluster-address':
        return LineDataService.fetchClusterAddressData;
    }
  }
}
