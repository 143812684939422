import React, { FC } from 'react';
import { ArrowBottomIcon } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

export type TableCellChevronProps = {
  onClick(): void;
};
export const TableCellChevron: FC<TableCellChevronProps> = ({ onClick }) => {
  return (
    <div className={styles.chevronCell} onClick={onClick} role="button" tabIndex={0}>
      <ArrowBottomIcon className={styles.chevronCell__icon} width={24} height={24} />
    </div>
  );
};

export const renderCellChevron = (args: TableCellChevronProps) => (
  <TableCellChevron {...args} />
);
