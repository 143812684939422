import React, { FC, MouseEvent, useRef, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
  ExportDenseIcon,
  stopEvent,
  Typography,
  useOnClickOutside,
} from '@rubin-dev/goblin';
import { useGraphIntegration } from '@graph/libs/hooks';
import styles from './styles.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { CreateHtmlCallback } from '@rubin-dev/octavius';

type Props = {
  createImage?: CreateHtmlCallback['createImage'];
};

export const GraphExportButton: FC<Props> = ({ createImage }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [{ exportJSON }] = useGraphIntegration();

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const handleClickItem = (callback: () => void) => {
    return (e: MouseEvent<HTMLDivElement>) => {
      stopEvent(e);
      callback();
      setIsOpen(false);
    };
  };

  return (
    <div ref={dropdownRef}>
      <Dropdown
        value={isOpen}
        className={styles.graphExportBtn}
        targetSlot={
          <ExportDenseIcon
            className={cx(
              styles.graphExportBtn__target,
              isOpen && styles.graphExportBtn__target_active,
            )}
          />
        }
        onChange={setIsOpen}
        placement="bottom-start"
      >
        <DropdownList width={260} className={styles.graphExportBtn__content}>
          <Typography
            className={styles.graphExportBtn__label}
            variant="body-14"
            color="on-surface-primary-2"
          >
            {t('titles.exportFormatLabel')}:
          </Typography>
          <DropdownItem
            fullWidth
            onClick={
              // Исправить тип в либе
              handleClickItem(createImage as any)
            }
          >
            PNG
          </DropdownItem>
          <DropdownItem onClick={handleClickItem(exportJSON)} fullWidth>
            Json
          </DropdownItem>
        </DropdownList>
      </Dropdown>
    </div>
  );
};
