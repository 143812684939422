import { FC } from 'react';
import cx from 'classnames';
import { isString, PropsWithNativeTypes, Typography } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

export type ListItemProps = {
  label: string | JSX.Element;
  value: JSX.Element | string;
  row?: boolean;
};
export const ListItem: FC<PropsWithNativeTypes<ListItemProps, HTMLDivElement>> = ({
  label,
  value,
  row,
  className,
  ...rest
}) => {
  return (
    <div className={cx(styles.listItem, row && styles.listItem_row, className)} {...rest}>
      <Typography
        tag="div"
        className={styles.listItem__label}
        variant="body-14"
        color="on-surface-primary-2"
      >
        {label}
      </Typography>
      {isString(value) ? (
        <Typography tag="div" variant="head-16" color="on-surface-primary-1">
          {value}
        </Typography>
      ) : (
        <div className={styles.listItem__value}>{value}</div>
      )}
    </div>
  );
};
