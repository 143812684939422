import { DateTime } from 'luxon';

type timestampValue = number | string | undefined | null;

export const timestampToHumanDate = (
  value: timestampValue,
  type?: string | null,
): string | null => {
  if (!value) {
    return null;
  }

  const timestamp = fromTimestamp(value);
  if (!timestamp) {
    return null;
  }

  return DateTime.fromISO(timestamp).toLocaleString({
    ...(type !== 'time' ? { dateStyle: 'medium' } : null),
    ...(type !== 'date' ? { timeStyle: 'medium' } : null),
  });
};

const fromTimestamp = (timestamp: number | string | undefined | null): string | null => {
  const timestampRaw = String(timestamp);
  if (!timestampRaw) {
    return null;
  }

  if (String(timestampRaw).length > 10) {
    return DateTime.fromMillis(parseInt(timestampRaw)).toISO();
  }

  return DateTime.fromSeconds(parseInt(timestampRaw)).toISO();
};

export const formatDate = (value: string, hasType: boolean = false) => {
  if (!value) {
    return;
  }

  if (hasType) return DateTime.fromISO(value).toUTC().toFormat('DD');

  return DateTime.fromISO(value).toUTC().toFormat('TT');
};

export const getFormatDate = (timestamp: number = +new Date()) => {
  return `${timestampToHumanDate(timestamp, 'date')} ${timestampToHumanDate(
    timestamp,
    'time',
  )}`;
};
