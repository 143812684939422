import { createContext } from 'react';
import { AddressCheckedType, Network } from '@apolloGenerated';
import { TableType } from '@graph/types';

export type DataContextType = {
  hash: string;
  network: Network;
  type: TableType;
  checked: AddressCheckedType[];
  isLoading: boolean;
};
export const DataContext = createContext<Partial<DataContextType>>({} as DataContextType);
