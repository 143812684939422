import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  LoaderOverlay,
  useForm,
  FormGenericData,
  Spacer,
} from '@rubin-dev/goblin';

export type AuthRegistrationFormProps = FormGenericData<AuthRegistrationFormInput>;

export type AuthRegistrationFormInput = {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

const defaultValues: AuthRegistrationFormInput = {
  name: '',
  email: '',
  password: '',
  passwordConfirm: '',
};

export const AuthRegistrationForm: FC<AuthRegistrationFormProps> = ({
  loading,
  formOnSubmit,
  initialValues,
}): JSX.Element => {
  const { t } = useTranslation();
  const { errors, onChange, onCmdEnterSubmit, onSubmit, values } =
    useForm<AuthRegistrationFormInput>(formOnSubmit, {
      ...defaultValues,
      ...initialValues,
    });

  return (
    <form onKeyDown={onCmdEnterSubmit} onSubmit={onSubmit}>
      <TextField
        autoFocus
        onChange={onChange}
        name="name"
        id="name"
        initValue={values.name}
        error={errors?.name}
        label={t('titles.yourname')}
        fullWidth
        size="large"
      />
      <Spacer size={12} />
      <TextField
        onChange={onChange}
        label={t('titles.email')}
        name="email"
        id="email"
        initValue={values.email}
        error={errors?.email}
        fullWidth
        size="large"
      />
      <Spacer size={12} />
      <TextField
        type="password"
        onChange={onChange}
        name="password"
        id="password"
        label={t('titles.password')}
        value={values.password}
        error={errors?.password}
        fullWidth
        size="large"
      />
      <Spacer size={12} />
      <TextField
        type="password"
        onChange={onChange}
        name="passwordConfirm"
        id="passwordConfirm"
        label={t('titles.passwordconf')}
        value={values.passwordConfirm}
        error={errors?.passwordConfirm}
        fullWidth
        size="large"
      />
      <Spacer size={12} />
      <Button disabled={loading} type="submit">
        {t('buttons.signUp')}
      </Button>
      <LoaderOverlay show={loading} />
    </form>
  );
};
