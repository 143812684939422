export enum EventName {
  // WINDOW EVENT NAMES
  WINDOW_CLOSE = 'window/close',
  WINDOW_SHOW = 'window/show',

  // TABLE EVENT NAMES
  TABLE_CHANGE_CHECKED = 'table/change-checked',
  TABLE_CLICK_CHEVRON = 'table/click-chevron',

  // GRAPH EVENT NAMES
  GRAPH_CLICK = 'graph/click',
  GRAPH_RIGHT_CLICK = 'graph/right-click',
  GRAPH_NODE_REMOVE = 'graph/node-remove',

  // DATA EVENT NAMES
  DATA_UPDATE = 'data/update',
  DATA_SAVE_POSITION = 'data/save-position',
  DATA_SAVE_CANVAS = 'data/save-canvas',
  DATA_UPDATE_HASH = 'data/update-hash',
  DATA_INIT_HASH = 'data/init-hash',
  DATA_ADD_ADDRESS_WITH_CONNECTIONS = 'data/add-address-with-connections',

  // CONTEXT MENU
  MENU_CHANGE_LINK_COLOR = 'menu/change-link-color',
  MENU_LINK_ADD_ANNOTATION = 'menu/link-add-annotation',
  MENU_NODE_ADD_ANNOTATION = 'menu/node-add-annotation',
}
