import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCountLabel,
  GraphWindow,
  useSidebarData,
  useSidebarWindow,
} from '@graph-sidebar-module/shared';
import { useExplorerClusterQuery } from '@apolloGenerated';
import { ListItem } from '@shared/ui';
import {
  CopyHash,
  Divider,
  DriverHeader,
  Spacer,
  TabItem,
  Tabs,
  Typography,
} from '@rubin-dev/goblin';
import { openAddress, openWallet } from '@shared/libs';
import { ClusterInfo } from '@graph-sidebar-module/entities/Cluster';
import {
  ClusterAddresses,
  ClusterTransactions,
} from '@graph-sidebar-module/features/Cluster';

export enum ClusterTab {
  TRANSACTIONS,
  ADDRESSES,
}

export const ClusterWindow: FC = () => {
  const { t } = useTranslation();
  const { hash, network, isLoading } = useSidebarData();
  const [{ onHide }, { isShow }] = useSidebarWindow();
  const [tab, setTab] = useState<ClusterTab>(ClusterTab.TRANSACTIONS);
  const { data: walletData, loading } = useExplorerClusterQuery({
    variables: { network, id: hash },
  });
  const countAddress = walletData?.explorerClusterStats?.stats?.addressCount;
  const countTr = walletData?.explorerClusterStats?.stats?.totalCount;
  const owner = walletData?.explorerCluster?.cluster?.owner || 'Entity';

  return (
    <GraphWindow
      isOpen={isShow}
      onClose={onHide}
      width={664}
      isLoading={isLoading}
      headerSlot={
        <DriverHeader
          titleSlot={
            <Typography
              className="cursor-pointer"
              variant="head-24"
              color="text-link"
              onClick={() => openWallet(network, hash)}
            >
              {owner}
            </Typography>
          }
          subtitleSlot={
            <ListItem
              label={`${t('titles.rootAddress')}:`}
              value={
                <CopyHash
                  label={hash.slice(0, 8) + '...'}
                  hash={hash}
                  onClickHash={() => openAddress(network, hash)}
                  variant="body-16"
                  shorted
                />
              }
              row
            />
          }
          // rightSlot={<TokenSelect />}
        />
      }
    >
      <ClusterInfo hash={hash} network={network} />
      <Spacer size={20} />
      <Tabs onChange={setTab}>
        <TabItem
          label={`${t('strings.transactions')}${getCountLabel(countTr, loading)}`}
          value={ClusterTab.TRANSACTIONS}
        />
        <TabItem
          label={`${t('links.addresses')}${getCountLabel(countAddress, loading)}`}
          value={ClusterTab.ADDRESSES}
        />
      </Tabs>
      <Divider style={{ marginTop: '-1px' }} />
      <Spacer size={12} />
      {tab === ClusterTab.TRANSACTIONS && <ClusterTransactions />}
      {tab === ClusterTab.ADDRESSES && <ClusterAddresses />}
    </GraphWindow>
  );
};
