import { TableType } from '@graph/types';
import { LinkItem, NodeItem } from '../../types/graph';

export function isNodeItem(data: Record<PropertyKey, any>): data is NodeItem {
  return data.type === TableType.Address || data.type === TableType.Cluster;
}

export function isLinkItem(data: Record<PropertyKey, any>): data is LinkItem {
  return data.type === TableType.Transaction;
}

export function isLabelNode(data: Record<PropertyKey, any>) {
  if (!data.text && !data.uuid) return false;

  return data.type === TableType.Annotation || data.type === TableType.AnnotationLink;
}
