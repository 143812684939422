import { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const IconMarkup: FC<IconProps> = ({ width = 32, height = 32, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
      {...rest}
    >
      <rect width="32" height="32" rx="10" fill="url(#paint0_linear_1745_87855)" />
      <path
        d="M19.3333 21L24.3333 16L19.3333 11"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 11L7.66675 16L12.6667 21"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1745_87855"
          x1="16.4"
          y1="32"
          x2="16.4"
          y2="0.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6DD4ED" />
          <stop offset="0.251884" stopColor="#1EBEDE" />
          <stop offset="0.478941" stopColor="#0FBDE0" />
          <stop offset="0.765383" stopColor="#42C9EB" />
          <stop offset="1" stopColor="#85D6F8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
