import React, { FC } from 'react';
import { GraphsList } from '@component/NewGraph';
import { Container } from '@shared/ui';

export const GraphsListScreen: FC = () => {
  return (
    <Container>
      <GraphsList />
    </Container>
  );
};
