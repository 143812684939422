import Ajv from 'ajv';
import { Meta } from '../Meta/Meta';
import { NodeList, LinkList, LinkService } from '@graph/models';
import { TableType } from '@graph/types';
import { NodeService } from '../Node/NodeService';
import { Link } from '../Link/Link';
import {
  GraphHashes,
  GraphLink,
  GraphMeta,
  GraphNode,
  GraphNodeInput,
  GraphSaveData,
} from '@apolloGenerated';
import { graphSaveScheme } from '../../const/validate.const';
import { FEATURE_CLUSTERING } from '@graph/const/config.const';
import { Annotation } from '@graph/models/Annotation/Annotation';
import { v4 as uuidv4 } from 'uuid';
export class DataConverter {
  static getConvertedData(): GraphSaveData {
    return {
      meta: this.getConvertedMeta(),
      graph: {
        nodes: this.getConvertedNodes(NodeList.hashList),
        links: this.getConvertedLinks(LinkList.hashList),
      },
    };
  }

  static getConvertedMeta(): GraphMeta {
    return {
      name: Meta.graphName,
      canvas: Meta.canvas,
      current: Meta.current as GraphHashes,
      network: Meta.network,
    };
  }

  private static getConvertedNodes(nodes: typeof NodeList.hashList): GraphNodeInput[] {
    return Object.values(nodes).map((node) => ({
      hash: node.hash,
      total: node.total,
      totalIn: node.totalIn,
      totalOut: node.totalOut,
      balance: node.balance,
      hasMention: node.hasMention,
      category: node.category,
      risk: node.risk,
      tableHash: node.tableHash,
      reportedCategory: node.reportedCategory,
      cluster: node.cluster,
      coords: node.coords,
      checked: NodeList.getTableDataByHash(node.hash).checked,
      annotations: Object.values(node.annotation)
        .map((el) => ({
          x: el.coords?.x || 0,
          y: el.coords?.y || 0,
          text: el.getText()!,
        }))
        .filter((el) => el.text),
    }));
  }

  private static getConvertedLinks(links: typeof LinkList.hashList): GraphLink[] {
    return Object.values(links).map((node) => ({
      target: node.target,
      source: node.source,
      amount: node.amount,
      color: node.color,
      annotation: node.annotation.getText(),
    }));
  }

  static fillData(uuid: string, data: Partial<GraphSaveData>) {
    Meta.uuid = uuid;
    if (data.meta) this.fillMeta(data.meta);
    if (data.graph) {
      this.fillNodes(data.graph.nodes);
      this.fillLinks(data.graph.links);
    }
  }
  static fillMeta(meta: GraphMeta) {
    Meta.graphName = meta.name;
    if (meta.current) Meta.current = meta.current;
    Meta.network = meta.network;
    if (meta.canvas) Meta.canvas = meta.canvas;
  }
  static fillNodes(nodes: GraphNode[]) {
    nodes.map((el) => {
      const getType = () => {
        if (!FEATURE_CLUSTERING) return TableType.Address;
        return el.cluster ? TableType.Cluster : TableType.Address;
      };
      NodeService.createNode(
        {
          hash: el.hash,
          balance: String(el.balance),
          counts: {
            received: String(el.totalIn),
            total: String(el.total),
            sent: String(el.totalOut),
          },
          addressRisk: { risk: el.risk, categoryNumber: Number(el.category) },
          cluster: el.cluster,
          type: getType(),
          checked: el.checked,
          coords: el.coords!,
          annotation: el.annotations,
        },
        true,
      );
    });
  }

  static fillLinks(links: GraphLink[]) {
    links.map((el) => {
      LinkList.addLink(
        new Link(el.target, el.source, {
          color: el.color,
          amount: el.amount,
          annotation: new Annotation(
            uuidv4(),
            LinkService.generateLinkHash(el.target, el.source),
            TableType.Transaction,
            { text: el.annotation },
          ),
        }),
        true,
      );
    });
  }

  static validate(data: Record<PropertyKey, any>): data is GraphSaveData {
    const ajv = new Ajv();
    const validate = ajv.compile(graphSaveScheme);
    const result = validate(data);
    if (validate?.errors?.length) {
      console.error(ajv.errorsText(validate.errors));
      console.debug(validate?.errors);
    }
    return result;
  }
}
