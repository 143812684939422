import React, { FC } from 'react';
import { Typography } from '@rubin-dev/goblin';

export type TableCellTokenProps = {
  token: string;
};
export const TableCellToken: FC<TableCellTokenProps> = ({ token }) => {
  return (
    <Typography title={token} variant={'body-14'} ellipsis>
      {token}
    </Typography>
  );
};
export const renderCellToken = (args: TableCellTokenProps) => (
  <TableCellToken {...args} />
);
