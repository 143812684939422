import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  useQuery,
  useMutation,
  useQueryClient,
  type QueryClient,
  type UseMutationOptions,
  type UseQueryOptions,
  type MutationFunction,
  type UseMutationResult,
  type UseQueryResult,
} from '@tanstack/react-query';
export type CalculateItem = {
  number?: number;
  total?: string;
  total_human?: string;
  percent?: number;
  percent_raw?: number;
  risk?: number;
  risk_raw?: number;
  acceptable?: number;
  name?: string;
  color?: string;
  i18n?: I18n;
};
export type CalculatedRisk = {
  total?: string;
  total_human?: string;
  risk?: number;
  risk_raw?: number;
  items?: {
    [key: string]: CalculateItem;
  };
};
export type Cluster = {
  cluster?: string;
  owner?: string;
  category_name?: string;
  category_number?: number;
  risk?: number;
};
export type CreateMentionReq = {
  uuid?: string;
  address?: string;
  link?: string;
  label?: string;
  owner?: string;
  level?: number;
  mention_source_id?: string;
  version?: number;
  category_number?: number;
  description?: string;
  name?: string;
  otherAddresses?: {
    [key: string]: string;
  };
  is_paid?: boolean;
};
export type CreateMentionResp = {
  mention?: Mention;
};
export type DeleteMentionByUUIDResp = {};
export type Dict = {
  version?: number;
  params?: {
    [key: string]: DictDictItem;
  };
};
export type DictItemDictDescription = {
  ru?: string;
  en?: string;
};
export type DictDictItem = {
  name?: string;
  risk?: number;
  number?: number;
  skip?: boolean;
  risk_threshold?: number;
  rp_rate?: number;
  i18n?: DictItemDictDescription;
  color?: string;
};
export type FindMentionResp = {
  mention?: Mention;
};
export type GoogleProtobufAny = {
  [key: string]: any;
};
export type I18n = {
  ru?: string;
  en?: string;
};
export type Mention = {
  id?: string;
  link?: string;
  mention_label?: string;
  owner?: string;
  created_at?: string;
  category_number?: number;
  address?: string;
  domain?: string;
  has_screenshots?: boolean;
  description?: string;
  name?: string;
  otherAddresses?: {
    [key: string]: string;
  };
  category_name?: string;
  risk_color?: string;
  mention_source?: MentionSource;
  report_id?: string;
  is_paid?: boolean;
  uuid?: string;
};
export type MentionListByAddressResp = {
  edge?: Mention[];
};
export type MentionListResp = {
  report?: Mention[];
  pager?: PageInfo;
};
export type MentionSource = {
  id?: string;
  name?: string;
  description?: string;
  level?: number;
  source_address?: string;
  category_number?: number;
  version?: number;
};
export type PageInfo = {
  page?: number;
  count?: number;
  total?: string;
};
export type Report = {
  id?: string;
  address?: string;
  version?: number;
  category_id?: number;
  level?: number;
  created_at?: string;
  is_paid?: boolean;
  mention_id?: string;
};
export type ReportListResp = {
  report?: Report[];
  pager?: PageInfo;
};
export type ReportResp = {
  report?: Report;
  mention?: Mention;
};
export type ReportedRisk = {
  category_name?: string;
  category_number?: number;
  risk?: number;
};
export type RiskResponse = {
  risk?: CalculatedRisk;
  dict?: Dict;
  total?: string;
  total_human?: string;
};
export type ScreenshotResp = {
  screenshot?: string[];
};
export type Status = {
  code?: number;
  message?: string;
  details?: GoogleProtobufAny[];
};
export type SummaryAddressRiskResponse = {
  risk?: number;
  calculated?: CalculatedRisk;
  reported?: ReportedRisk;
  cluster?: Cluster;
  category?: string[];
  dict?: Dict;
};
export type VersionsResponse = {
  versions?: number[];
};
export type AxiosConfig = {
  paramsSerializer?: AxiosRequestConfig['paramsSerializer'];
};
export type Config = {
  mutations?: MutationConfigs;
  axios?: AxiosConfig;
};
export function initialize(axios: AxiosInstance, config?: Config) {
  const requests = makeRequests(axios, config?.axios);
  return {
    requests,
    queries: makeQueries(requests),
    mutations: makeMutations(requests, config?.mutations),
  };
}
function useRapiniMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  config?: (
    queryClient: QueryClient,
  ) => Pick<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'onSuccess' | 'onSettled' | 'onError'
  >,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { onSuccess, onError, onSettled, ...rest } = options ?? {};
  const queryClient = useQueryClient();
  const conf = config?.(queryClient);
  const mutationOptions: typeof options = {
    onSuccess: (data: TData, variables: TVariables, context: TContext) => {
      conf?.onSuccess?.(data, variables, context);
      onSuccess?.(data, variables, context);
    },
    onError: (error: TError, variables: TVariables, context?: TContext) => {
      conf?.onError?.(error, variables, context);
      onError?.(error, variables, context);
    },
    onSettled: (
      data: TData | undefined,
      error: TError | null,
      variables: TVariables,
      context?: TContext,
    ) => {
      conf?.onSettled?.(data, error, variables, context);
      onSettled?.(data, error, variables, context);
    },
    ...rest,
  };
  return useMutation({ mutationFn, ...mutationOptions });
}
function nullIfUndefined<T>(value: T): NonNullable<T> | null {
  return typeof value === 'undefined' ? null : (value as NonNullable<T> | null);
}
export const queryKeys = {
  riskServiceAddressRisk: (id: string, version?: number) =>
    ['riskServiceAddressRisk', id, nullIfUndefined(version)] as const,
  riskServiceAddressVersions: (id: string) => ['riskServiceAddressVersions', id] as const,
  riskServiceCurrentDict: () => ['riskServiceCurrentDict'] as const,
  riskServiceMentionList: (
    filter_start_at?: string,
    filter_end_at?: string,
    pager_page?: number,
    pager_page_size?: number,
  ) =>
    [
      'riskServiceMentionList',
      nullIfUndefined(filter_start_at),
      nullIfUndefined(filter_end_at),
      nullIfUndefined(pager_page),
      nullIfUndefined(pager_page_size),
    ] as const,
  riskServiceMentionListByAddress: (address: string) =>
    ['riskServiceMentionListByAddress', address] as const,
  riskServiceFindMention: (id: string) => ['riskServiceFindMention', id] as const,
  riskServiceReportList: (
    filter_start_at?: string,
    filter_end_at?: string,
    pager_page?: number,
    pager_page_size?: number,
  ) =>
    [
      'riskServiceReportList',
      nullIfUndefined(filter_start_at),
      nullIfUndefined(filter_end_at),
      nullIfUndefined(pager_page),
      nullIfUndefined(pager_page_size),
    ] as const,
  riskServiceReport: (address: string) => ['riskServiceReport', address] as const,
  riskServiceScreenshot: (uuid: string) => ['riskServiceScreenshot', uuid] as const,
  riskServiceTransactionRisk: (id: string, version?: number) =>
    ['riskServiceTransactionRisk', id, nullIfUndefined(version)] as const,
  riskServiceTransactionVersions: (id: string) =>
    ['riskServiceTransactionVersions', id] as const,
  riskServiceSummaryAddressRisk: (id: string, version?: number) =>
    ['riskServiceSummaryAddressRisk', id, nullIfUndefined(version)] as const,
  riskServiceVersionDict: (version: number) =>
    ['riskServiceVersionDict', version] as const,
} as const;
export type QueryKeys = typeof queryKeys;
function makeRequests(axios: AxiosInstance, config?: AxiosConfig) {
  return {
    riskServiceAddressRisk: (id: string, version?: number) =>
      axios
        .request<RiskResponse>({
          method: 'get',
          url: `/address/${id}`,
          params: {
            ...(version !== undefined ? { version } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    riskServiceAddressVersions: (id: string) =>
      axios
        .request<VersionsResponse>({
          method: 'get',
          url: `/address/${id}/versions`,
        })
        .then((res) => res.data),
    riskServiceCurrentDict: () =>
      axios
        .request<Dict>({
          method: 'get',
          url: `/current`,
        })
        .then((res) => res.data),
    riskServiceMentionList: (
      filterstart_at?: string,
      filterend_at?: string,
      pagerpage?: number,
      pagerpage_size?: number,
    ) =>
      axios
        .request<MentionListResp>({
          method: 'get',
          url: `/mention`,
          params: {
            ...(filterstart_at !== undefined ? { filterstart_at } : undefined),
            ...(filterend_at !== undefined ? { filterend_at } : undefined),
            ...(pagerpage !== undefined ? { pagerpage } : undefined),
            ...(pagerpage_size !== undefined ? { pagerpage_size } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    riskServiceCreateMention: (payload: CreateMentionReq) =>
      axios
        .request<CreateMentionResp>({
          method: 'post',
          url: `/mention/create`,
          data: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data),
    riskServiceMentionListByAddress: (address: string) =>
      axios
        .request<MentionListByAddressResp>({
          method: 'get',
          url: `/mention/list/${address}`,
        })
        .then((res) => res.data),
    riskServiceDeleteMentionByUUID: (uuid: string) =>
      axios
        .request<DeleteMentionByUUIDResp>({
          method: 'delete',
          url: `/mention/uuid/${uuid}`,
        })
        .then((res) => res.data),
    riskServiceFindMention: (id: string) =>
      axios
        .request<FindMentionResp>({
          method: 'get',
          url: `/mention/${id}`,
        })
        .then((res) => res.data),
    riskServiceReportList: (
      filterstart_at?: string,
      filterend_at?: string,
      pagerpage?: number,
      pagerpage_size?: number,
    ) =>
      axios
        .request<ReportListResp>({
          method: 'get',
          url: `/report`,
          params: {
            ...(filterstart_at !== undefined ? { filterstart_at } : undefined),
            ...(filterend_at !== undefined ? { filterend_at } : undefined),
            ...(pagerpage !== undefined ? { pagerpage } : undefined),
            ...(pagerpage_size !== undefined ? { pagerpage_size } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    riskServiceReport: (address: string) =>
      axios
        .request<ReportResp>({
          method: 'get',
          url: `/report/${address}`,
        })
        .then((res) => res.data),
    riskServiceScreenshot: (uuid: string) =>
      axios
        .request<ScreenshotResp>({
          method: 'get',
          url: `/screenshot/${uuid}`,
        })
        .then((res) => res.data),
    riskServiceTransactionRisk: (id: string, version?: number) =>
      axios
        .request<RiskResponse>({
          method: 'get',
          url: `/transaction/${id}`,
          params: {
            ...(version !== undefined ? { version } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    riskServiceTransactionVersions: (id: string) =>
      axios
        .request<VersionsResponse>({
          method: 'get',
          url: `/transaction/${id}/versions`,
        })
        .then((res) => res.data),
    riskServiceSummaryAddressRisk: (id: string, version?: number) =>
      axios
        .request<SummaryAddressRiskResponse>({
          method: 'get',
          url: `/v2/address/${id}`,
          params: {
            ...(version !== undefined ? { version } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    riskServiceVersionDict: (version: number) =>
      axios
        .request<Dict>({
          method: 'get',
          url: `/version/${version}`,
        })
        .then((res) => res.data),
  } as const;
}
export type Requests = ReturnType<typeof makeRequests>;
export type Response<T extends keyof Requests> = Awaited<ReturnType<Requests[T]>>;
function makeQueries(requests: Requests) {
  return {
    useRiskServiceAddressRisk: (
      id: string,
      version?: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceAddressRisk'>,
          unknown,
          Response<'riskServiceAddressRisk'>,
          ReturnType<QueryKeys['riskServiceAddressRisk']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceAddressRisk'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceAddressRisk(id, version),
        queryFn: () => requests.riskServiceAddressRisk(id, version),
        ...options,
      }),
    useRiskServiceAddressVersions: (
      id: string,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceAddressVersions'>,
          unknown,
          Response<'riskServiceAddressVersions'>,
          ReturnType<QueryKeys['riskServiceAddressVersions']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceAddressVersions'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceAddressVersions(id),
        queryFn: () => requests.riskServiceAddressVersions(id),
        ...options,
      }),
    useRiskServiceCurrentDict: (
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceCurrentDict'>,
          unknown,
          Response<'riskServiceCurrentDict'>,
          ReturnType<QueryKeys['riskServiceCurrentDict']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceCurrentDict'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceCurrentDict(),
        queryFn: () => requests.riskServiceCurrentDict(),
        ...options,
      }),
    useRiskServiceMentionList: (
      filter_start_at?: string,
      filter_end_at?: string,
      pager_page?: number,
      pager_page_size?: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceMentionList'>,
          unknown,
          Response<'riskServiceMentionList'>,
          ReturnType<QueryKeys['riskServiceMentionList']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceMentionList'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceMentionList(
          filter_start_at,
          filter_end_at,
          pager_page,
          pager_page_size,
        ),
        queryFn: () =>
          requests.riskServiceMentionList(
            filter_start_at,
            filter_end_at,
            pager_page,
            pager_page_size,
          ),
        ...options,
      }),
    useRiskServiceMentionListByAddress: (
      address: string,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceMentionListByAddress'>,
          unknown,
          Response<'riskServiceMentionListByAddress'>,
          ReturnType<QueryKeys['riskServiceMentionListByAddress']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceMentionListByAddress'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceMentionListByAddress(address),
        queryFn: () => requests.riskServiceMentionListByAddress(address),
        ...options,
      }),
    useRiskServiceFindMention: (
      id: string,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceFindMention'>,
          unknown,
          Response<'riskServiceFindMention'>,
          ReturnType<QueryKeys['riskServiceFindMention']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceFindMention'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceFindMention(id),
        queryFn: () => requests.riskServiceFindMention(id),
        ...options,
      }),
    useRiskServiceReportList: (
      filter_start_at?: string,
      filter_end_at?: string,
      pager_page?: number,
      pager_page_size?: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceReportList'>,
          unknown,
          Response<'riskServiceReportList'>,
          ReturnType<QueryKeys['riskServiceReportList']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceReportList'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceReportList(
          filter_start_at,
          filter_end_at,
          pager_page,
          pager_page_size,
        ),
        queryFn: () =>
          requests.riskServiceReportList(
            filter_start_at,
            filter_end_at,
            pager_page,
            pager_page_size,
          ),
        ...options,
      }),
    useRiskServiceReport: (
      address: string,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceReport'>,
          unknown,
          Response<'riskServiceReport'>,
          ReturnType<QueryKeys['riskServiceReport']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceReport'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceReport(address),
        queryFn: () => requests.riskServiceReport(address),
        ...options,
      }),
    useRiskServiceScreenshot: (
      uuid: string,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceScreenshot'>,
          unknown,
          Response<'riskServiceScreenshot'>,
          ReturnType<QueryKeys['riskServiceScreenshot']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceScreenshot'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceScreenshot(uuid),
        queryFn: () => requests.riskServiceScreenshot(uuid),
        ...options,
      }),
    useRiskServiceTransactionRisk: (
      id: string,
      version?: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceTransactionRisk'>,
          unknown,
          Response<'riskServiceTransactionRisk'>,
          ReturnType<QueryKeys['riskServiceTransactionRisk']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceTransactionRisk'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceTransactionRisk(id, version),
        queryFn: () => requests.riskServiceTransactionRisk(id, version),
        ...options,
      }),
    useRiskServiceTransactionVersions: (
      id: string,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceTransactionVersions'>,
          unknown,
          Response<'riskServiceTransactionVersions'>,
          ReturnType<QueryKeys['riskServiceTransactionVersions']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceTransactionVersions'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceTransactionVersions(id),
        queryFn: () => requests.riskServiceTransactionVersions(id),
        ...options,
      }),
    useRiskServiceSummaryAddressRisk: (
      id: string,
      version?: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceSummaryAddressRisk'>,
          unknown,
          Response<'riskServiceSummaryAddressRisk'>,
          ReturnType<QueryKeys['riskServiceSummaryAddressRisk']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceSummaryAddressRisk'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceSummaryAddressRisk(id, version),
        queryFn: () => requests.riskServiceSummaryAddressRisk(id, version),
        ...options,
      }),
    useRiskServiceVersionDict: (
      version: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceVersionDict'>,
          unknown,
          Response<'riskServiceVersionDict'>,
          ReturnType<QueryKeys['riskServiceVersionDict']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceVersionDict'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceVersionDict(version),
        queryFn: () => requests.riskServiceVersionDict(version),
        ...options,
      }),
  } as const;
}
type MutationConfigs = {
  useRiskServiceCreateMention?: (
    queryClient: QueryClient,
  ) => Pick<
    UseMutationOptions<
      Response<'riskServiceCreateMention'>,
      unknown,
      Parameters<Requests['riskServiceCreateMention']>[0],
      unknown
    >,
    'onSuccess' | 'onSettled' | 'onError'
  >;
  useRiskServiceDeleteMentionByUUID?: (
    queryClient: QueryClient,
  ) => Pick<
    UseMutationOptions<
      Response<'riskServiceDeleteMentionByUUID'>,
      unknown,
      unknown,
      unknown
    >,
    'onSuccess' | 'onSettled' | 'onError'
  >;
};
function makeMutations(requests: Requests, config?: Config['mutations']) {
  return {
    useRiskServiceCreateMention: (
      options?: Omit<
        UseMutationOptions<
          Response<'riskServiceCreateMention'>,
          unknown,
          Parameters<Requests['riskServiceCreateMention']>[0],
          unknown
        >,
        'mutationFn'
      >,
    ) =>
      useRapiniMutation<
        Response<'riskServiceCreateMention'>,
        unknown,
        Parameters<Requests['riskServiceCreateMention']>[0]
      >(
        (payload) => requests.riskServiceCreateMention(payload),
        config?.useRiskServiceCreateMention,
        options,
      ),
    useRiskServiceDeleteMentionByUUID: (
      uuid: string,
      options?: Omit<
        UseMutationOptions<
          Response<'riskServiceDeleteMentionByUUID'>,
          unknown,
          unknown,
          unknown
        >,
        'mutationFn'
      >,
    ) =>
      useRapiniMutation<Response<'riskServiceDeleteMentionByUUID'>, unknown, unknown>(
        () => requests.riskServiceDeleteMentionByUUID(uuid),
        config?.useRiskServiceDeleteMentionByUUID,
        options,
      ),
  } as const;
}
