import React, { FC } from 'react';
import { Checkbox } from '@rubin-dev/goblin';

export type TableCellCheckboxProps = {
  checked?: boolean;
  onChange?(val: boolean): void;
  disabled?: boolean;
};
export const TableCellCheckbox: FC<TableCellCheckboxProps> = ({
  checked,
  onChange,
  disabled,
}) => {
  return (
    <Checkbox
      checked={checked || false}
      onChange={(val) => {
        onChange && onChange(val.target.checked);
      }}
      fullWidth
      disabled={disabled}
    />
  );
};

export const renderCellCheckbox = (args: TableCellCheckboxProps) => (
  <TableCellCheckbox {...args} />
);
