import React, { FC, useMemo } from 'react';
import { timestampToHumanDate } from '@utils/timestamp';
import { useTranslation } from 'react-i18next';
import { openTransaction } from '@shared/libs';
import { CopyHash, Typography } from '@rubin-dev/goblin';
import {
  Network,
  useExplorerFindBlockByTransactionQuery,
  useExplorerFindTransactionByHashQuery,
  useMeQuery,
} from '@apolloGenerated';
import styles from './styles.module.scss';
import { Blur, ListItem } from '@shared/ui';
import sdk from '@aml/sdk';
import { RiskDot } from '@component/NewRisk';
import { RouterName } from '../../../../../../../../router';

interface TransactionDetailInfoProps {
  txid: string;
  network: Network;
}

export const TransactionDetailInfo: FC<TransactionDetailInfoProps> = ({
  txid,
  network,
}) => {
  const { data: user } = useMeQuery({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();
  const { data: trData } = useExplorerFindTransactionByHashQuery({
    variables: {
      id: txid,
      network,
    },
  });
  const { data: blockData } = useExplorerFindBlockByTransactionQuery({
    variables: {
      network,
      txid,
    },
  });
  const { data: riskData } =
    sdk.risk[network].queries.useRiskServiceTransactionRisk(txid);
  const risk = riskData?.risk?.risk;
  const timestamp = trData?.explorerFindTransactionByHash.transaction?.timestamp;
  const block = blockData?.explorerFindBlockByTransaction?.node?.height;
  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );

  return (
    <div className={styles.trDetailInfo}>
      <ListItem label={`${t('titles.blockchain')}:`} value={network.toUpperCase()} row />
      {timestamp && (
        <ListItem
          label={'Date (UTC):'}
          value={`${timestampToHumanDate(timestamp, 'date')}, 
            ${timestampToHumanDate(timestamp, 'time')}`}
          row
        />
      )}
      <ListItem
        label={`${t('titles.hash')}:`}
        value={
          <CopyHash
            label={txid.slice(0, 8) + '...'}
            hash={txid}
            variant="head-16"
            onClickHash={() => openTransaction(network, txid)}
          />
        }
        row
      />
      {!isNaN(Number(risk)) && (
        <ListItem
          label={`${t('strings.riskscore')}:`}
          value={
            expireDate ? (
              <Typography
                className={styles.trDetailInfo__risk}
                variant="head-16"
                color="on-surface-primary-1"
              >
                <RiskDot risk={+risk!} size={8} />
                {risk}%
              </Typography>
            ) : (
              <Blur to={RouterName.TariffAnalyzer}>
                <RiskDot risk={Number(risk)} size={8} />
                000%
              </Blur>
            )
          }
          row
        />
      )}
      {block && <ListItem label={`${t('links.block')}:`} value={String(block)} row />}
    </div>
  );
};
