import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import {
  BillingKey,
  useBillingKeyCreateMutation,
  useBillingKeyListQuery,
} from '@apolloGenerated';
import { useModal } from '@hooks/useModal';
import { renderKey, renderRemove } from './renderTableSlot';
import * as gqlTypes from '../../../../apolloGenerated';
import * as gqlQueries from '../../../../query';
import { Typography, TableController, LoaderOverlay } from '@rubin-dev/goblin';

const PAGE_SIZE = 10;
export const headers = [
  { value: 'key' },
  {
    value: 'action',
  },
  { value: 'hashUniq', key: true, hide: true },
];
export const ApiKeys: FC = () => {
  const { t } = useTranslation();
  const openRemoveModal = useModal('removeApiKey');
  const [page, setPage] = useState<number>(1);
  const variables = {
    page,
    pageSize: PAGE_SIZE,
  };
  const refetchQueries = [
    {
      query: gqlQueries.billingKeyList,
      variables,
    },
  ];
  const [createKey] = useBillingKeyCreateMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const [removeKey] = gqlTypes.useBillingKeyRemoveMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const {
    data: curData,
    previousData,
    loading,
  } = useBillingKeyListQuery({
    variables,
    fetchPolicy: 'network-only',
  });
  const data = curData || previousData;
  const rowTemplate = (item: BillingKey) => ({
    key: renderKey(item.key),
    action: renderRemove(() => {
      openRemoveModal({
        onSubmit() {
          removeKey({ variables: { key: item.key }, onCompleted: updatePageAfterRemove });
        },
      });
    }),
    hashUniq: item.key,
  });

  const updatePageAfterRemove = () => {
    const countPage = Math.ceil((total - 1) / variables.pageSize);
    if (countPage < variables.page) setPage(countPage);
  };
  const total = data?.billingKeyList.total || 0;
  const items = useMemo(
    () => (data?.billingKeyList.edge || []).map(rowTemplate),
    [data?.billingKeyList],
  );
  const hasKeys = !!items?.length;

  return (
    <div className={styles.apiKeys}>
      <div className={styles.apiKeys__head}>
        <div className={styles.apiKeys__headText}>
          <Typography variant="head-16" color="on-surface-primary-1">
            {t('titles.keyApi')}
          </Typography>
          {!hasKeys && (
            <Typography variant="body-16" color="on-secondary-2">
              {t('strings.apiKeyInfo')}
            </Typography>
          )}
        </div>
        <Typography
          variant="body-14"
          className={styles.apiKeys__button}
          onClick={() => createKey()}
        >
          + {t('buttons.createApiKey')}
        </Typography>
      </div>
      {hasKeys && (
        <div className={styles.apiKeys__content}>
          <LoaderOverlay show={loading} />
          <TableController
            data={items}
            headers={headers}
            total={total}
            onChangePage={setPage}
            initPage={variables.page}
            pageSize={variables.pageSize}
            scrollToTop={false}
          />
        </div>
      )}
    </div>
  );
};
