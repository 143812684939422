import React, { FC, PropsWithChildren } from 'react';
import { PageNotFound } from '../../../../screen';
import { getNetworkEnum } from '@helpers/address';
import { LayoutExplorer } from './LayoutExplorer';
import * as gqlTypes from '../../../../apolloGenerated';

type LayoutExplorerBlockProps = {
  params: {
    height: string;
    network: string;
  };
};

export const LayoutExplorerBlock: FC<PropsWithChildren<LayoutExplorerBlockProps>> = ({
  children,
  params: { network, height },
}) => {
  const { data, loading } = gqlTypes.useExplorerFindTransactionsWithBlockQuery({
    variables: {
      network: getNetworkEnum(network),
      height: parseInt(height, 10),
      page: 1,
      pageSize: 10,
    },
    skip: !height,
  });

  if (!data?.explorerFindBlockByHeight?.node && !loading) {
    return <PageNotFound />;
  }

  return <LayoutExplorer params={{ network, value: height }}>{children}</LayoutExplorer>;
};
