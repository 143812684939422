import React, { FC, useEffect, useMemo, useState } from 'react';
import { Typography } from '@rubin-dev/goblin';
import cx from 'classnames';
import { amount as formatAmount } from '@aml/amount';
import { MIN_AMOUNT_VISIBLE } from '../../const';
import { Network } from '@apolloGenerated';
import styles from './styles.module.scss';

export type LineAmountProps = {
  amount: number;
  active?: boolean;
  network: Network;
  color?: string;
};
export const LineAmount: FC<LineAmountProps> = ({
  amount,
  active,
  network,
  color,
}): JSX.Element => {
  const [isInitAnimation, setIsInitAnimation] = useState<boolean>(false);
  let timer: ReturnType<typeof setTimeout>;
  const [isChanged, setIsChanged] = useState(false);
  const formattedAmount = useMemo(() => {
    if (amount === 0) return '0';
    if (amount < MIN_AMOUNT_VISIBLE[network]) return `<${MIN_AMOUNT_VISIBLE[network]}`;
    return formatAmount(amount, network, false);
  }, [amount]);
  useEffect(() => {
    if (!isInitAnimation) {
      setIsInitAnimation(true);
      return;
    }
    if (!active) return;
    timer && clearTimeout(timer);
    setIsChanged(true);
    timer = setTimeout(() => setIsChanged(false), 1000);
    return () => clearTimeout(timer);
  }, [amount]);

  return (
    <div
      className={cx(
        styles.lineAmount,
        active && styles.lineAmount_active,
        isChanged && styles.lineAmount_change,
      )}
    >
      <div className={styles.lineAmount__content}>
        <Typography
          className={styles.lineAmount__text}
          variant="body-16"
          style={{ color }}
        >
          {formattedAmount}
        </Typography>
      </div>
    </div>
  );
};
