import { createContext } from 'react';
import { LangType } from '@shared/libs';

export type AppContextType = {
  languages: readonly LangType[];
  language: LangType;
  setLang(language: LangType): void;
};
const AppContext = createContext<AppContextType>({} as AppContextType);

export { AppContext };
