import { Maybe, Network } from '@apolloGenerated';
import { useCallback, useEffect, useState } from 'react';
import {
  LineDataService,
  LineFetchArgs,
  LineFetchResponse,
} from '../../models/LineDataService';

type BetweenTransactionsData = LineFetchResponse;
export type UseBetweenTransactions = {
  data: Maybe<BetweenTransactionsData>;
  loading: boolean;
};
export type UseBetweenTransactionsVars = LineFetchArgs;
export const useBetweenTransactions = (
  network: Network,
  variables: UseBetweenTransactionsVars,
): UseBetweenTransactions => {
  const [data, setData] = useState<UseBetweenTransactions['data']>(null);
  const [loading, setLoading] = useState<UseBetweenTransactions['loading']>(false);

  const fetchBetweenTransactions = useCallback(
    async ({
      from: fromAddress,
      to: toAddress,
      ...params
    }: UseBetweenTransactionsVars) => {
      try {
        setLoading(true);
        const { to, from, type } = await LineDataService.getLineParams(
          network,
          fromAddress,
          toAddress,
        );
        const getLineData = LineDataService.getLineDataFetcher(type);
        const lineData = await getLineData(network, { from, to, ...params });
        setData(lineData);
      } catch (e) {
        console.error(e); // add error handling
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData],
  );

  useEffect(() => {
    fetchBetweenTransactions(variables);
  }, [variables]);

  return { data, loading };
};
