import { EventName, useGraphStore } from '@graph/libs';
import { Transaction } from '@graph-sidebar-module/shared';
import { useCallback } from 'react';

export type UseSidebarEventsType = {
  onChangeChecked(val: boolean, item: Transaction): void;
  onClickChevron(item: Transaction): void;
};
export const useSidebarEvents = (): UseSidebarEventsType => {
  const { sendEvent } = useGraphStore();
  const handleChangeChecked: UseSidebarEventsType['onChangeChecked'] = useCallback(
    (value: boolean, item) => {
      sendEvent({
        type: EventName.TABLE_CHANGE_CHECKED,
        params: { value, item },
      });
    },
    [sendEvent],
  );

  const handleClickChevron: UseSidebarEventsType['onClickChevron'] = useCallback(
    (item) => {
      sendEvent({
        type: EventName.TABLE_CLICK_CHEVRON,
        params: { item },
      });
    },
    [sendEvent],
  );

  return {
    onChangeChecked: handleChangeChecked,
    onClickChevron: handleClickChevron,
  };
};
