import axios, { AxiosInstance } from 'axios';
import { injectAuthInterceptor } from './interceptor';
import { getNewTokenDirect, loadUser, logoutUser } from '@utils/auth';
import { history, router } from '../../src/router';
import { RouterName } from '../../src/router';

interface CreateSdkOptions {
  injectAuth?: boolean;
}
export const createAxiosSdkInstance = (
  baseURL: string,
  { injectAuth }: CreateSdkOptions = {},
): AxiosInstance => {
  const instance = axios.create({
    baseURL,
  });
  if (injectAuth)
    injectAuthInterceptor(
      instance,
      () => {
        const user = loadUser();
        return user?.accessToken || '';
      },
      async () => getNewTokenDirect(),
      () => {
        logoutUser();
        history.navigate(router.urlFor(RouterName.AuthLogin));
      },
    );
  return instance;
};
