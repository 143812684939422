import React, { FC, useState } from 'react';
import {
  Typography,
  WithCloseModal,
  Dialog,
  Button,
  DialogFooter,
  Checkbox,
} from '@rubin-dev/goblin';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { openMarkup } from '@shared/libs';

export type ViewDetails = {
  address: string;
  details?: boolean;
  id?: number;
};
declare global {
  interface ModalProps {
    modalViewDetails: ViewDetails;
  }
}
export const ViewDetailsModal: FC<WithCloseModal<ViewDetails>> = ({
  address,
  details = false,
  onClose,
  id,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);
  const onChange = (checked: boolean) => {
    setChecked(checked);
    if (!checked) return;

    localStorage.setItem('confirm_check', '1');
  };

  const onConfirm = () => {
    openMarkup(address, details, id);
    onClose();
  };

  return (
    <Dialog
      width={500}
      title={
        <div className={styles.mentions__confirm}>
          <Typography tag="h3" variant="head-24" color="on-surface-primary-1">
            {t('markup.confirmRequest')}
          </Typography>
          <Typography variant={'body-16'} color={'on-surface-primary-1'}>
            {t('markup.informBalance')}: 1
          </Typography>
        </div>
      }
      closed
      footerSlot={
        <DialogFooter
          position="end"
          startSlot={
            <Button size="large" color="secondary" fullWidth onClick={onClose}>
              {t('titles.cancel')}
            </Button>
          }
          endSlot={
            <Button size="large" fullWidth onClick={onConfirm}>
              {t('buttons.ok')}
            </Button>
          }
        />
      }
    >
      {!details && (
        <Checkbox
          checked={checked || false}
          onChange={(val) => {
            onChange && onChange(val.target.checked);
          }}
          label={t('markup.noMoreWarning')}
        />
      )}
    </Dialog>
  );
};
