import React, { FC } from 'react';
import { Network } from '@apolloGenerated';
import { amount as formatAmount } from '@aml/amount';
import { useTranslation } from 'react-i18next';
import { Button, CopyHash } from '@rubin-dev/goblin';
import { ListItem } from '@shared/ui';

interface TransactionDetailBodyProps {
  token?: string;
  sendingAddress?: string;
  receivingAddress?: string;
  amount: string;
  network: Network;

  onClickVisualization(): void;

  onClickAddress?(address: string): void;
}
export const TransactionDetailBody: FC<TransactionDetailBodyProps> = ({
  token,
  sendingAddress,
  receivingAddress,
  network,
  amount,
  onClickAddress,
  onClickVisualization,
}) => {
  const { t } = useTranslation();
  return (
    <div className={'b-transaction-detail-body'}>
      <div className="b-transaction-detail-body__content">
        {sendingAddress && (
          <ListItem
            label={`${t('strings.sendingAddress')}:`}
            value={
              <CopyHash
                hash={sendingAddress}
                shorted
                variant="head-16"
                onClickHash={() => onClickAddress && onClickAddress(sendingAddress)}
              />
            }
            row
          />
        )}
        {receivingAddress && (
          <ListItem
            label={`${t('strings.receivingAddress')}:`}
            value={
              <CopyHash
                hash={receivingAddress}
                shorted
                variant="head-16"
                onClickHash={() => onClickAddress && onClickAddress(receivingAddress)}
              />
            }
            row
          />
        )}
        {token && (
          <ListItem label={`${t('titles.token')}:`} value={token.toUpperCase()} row />
        )}
        <ListItem
          label={`${t('strings.amount')}:`}
          value={!isNaN(+amount) ? formatAmount(amount, network, false) : '-'}
          row
        />
      </div>
      <div>
        <Button onClick={onClickVisualization} variant="outlined">
          {t('titles.visualization')}
        </Button>
      </div>
    </div>
  );
};
