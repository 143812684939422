import React, { FC, useState, useEffect, useMemo } from 'react';
import { Tabs, TabItem } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { FinderTab } from './FinderTab';
import styles from './styles/markup.module.scss';
import { RequestsTab } from './RequestsTab';
import * as gqlTypes from '@apolloGenerated';
import { useSubscriptionStore } from '@component/Mention';
import { useMeQuery } from '@apolloGenerated';
import { hasPermission } from '@utils/auth';
import { SUBSCRIPTION_EXPLORER_MARKUP } from '@constants/permissions.const';
import { PageNotFound } from '../StaticPage/PageNotFound';

enum TabsEnum {
  FINDER = 'finder',
  REQUESTS = 'requests',
}

export type PropsType = {
  user?: gqlTypes.User;
  query: {
    query: string;
  };
};
export const MarkupScreen: FC<PropsType> = ({ query, user }) => {
  const { t } = useTranslation();

  if (user && !hasPermission(SUBSCRIPTION_EXPLORER_MARKUP)) {
    return <PageNotFound />;
  }

  const { isSubscribed } = useSubscriptionStore();
  const { refetch: fetchMe } = useMeQuery({ fetchPolicy: 'network-only' });

  const [tab, setTab] = useState(TabsEnum.FINDER);

  const { data, refetch } = gqlTypes.useSubscriptionListQuery({
    variables: {
      page: 1,
      pageSize: 10,
    },
  });

  const updateSubscriptionList = () => {
    refetch({
      page: 1,
      pageSize: 10,
    });
  };

  useEffect(() => {
    if (updateSubscriptionList) {
      updateSubscriptionList();
    }
  }, [isSubscribed]);

  const handleChangeTab = (val: TabsEnum) => {
    setTab(val);
  };

  const requestsTabLabel = useMemo(() => {
    if (!data?.subscriptionList) return t('markup.requests');
    return `${t('markup.requests')} (${data?.subscriptionList?.total})`;
  }, [data?.subscriptionList?.total]);

  return (
    <>
      <div className={styles['markup']}>
        <section className={styles['markup__section']}>
          <div className={styles['markup__tabs-wrapper']}>
            <Tabs onChange={handleChangeTab} className={styles['markup__tabs']}>
              <TabItem value={TabsEnum.FINDER} label={t('markup.finder')} />
              <TabItem value={TabsEnum.REQUESTS} label={requestsTabLabel} />
            </Tabs>
          </div>
          <div className={styles['markup__content']}>
            {tab === TabsEnum.FINDER ? (
              <FinderTab
                query={query}
                onCheck={fetchMe}
                checkCount={user?.billing.markup}
                onClick={() => handleChangeTab(TabsEnum.REQUESTS)}
              />
            ) : (
              <div className={styles['markup__container']}>
                <RequestsTab />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};
