import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const EmailIcon: FC<IconProps> = ({ width = 32, height = 32, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="16" cy="16" r="16" fill="#B8433D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.78325 13.1302C7.78325 13.0097 7.79 12.8908 7.80313 12.7738C7.83344 12.5037 8.13375 12.3801 8.37063 12.5133L14.0379 15.701C15.2565 16.3864 16.7443 16.3864 17.9629 15.701L23.6294 12.5137C23.8664 12.3804 24.1667 12.5041 24.1969 12.7742C24.2101 12.891 24.2168 13.0097 24.2168 13.13V19.872C24.2168 21.6174 22.8019 23.0323 21.0565 23.0323H10.9435C9.19811 23.0323 7.78319 21.6173 7.7832 19.872L7.78325 13.1302ZM21.0564 9.96973C21.8641 9.96972 22.601 10.2727 23.1598 10.7712C23.3549 10.9454 23.2971 11.2502 23.0692 11.3785L17.3431 14.5992C16.5094 15.0682 15.4913 15.0682 14.6576 14.5992L8.93126 11.3783C8.70323 11.25 8.64544 10.9452 8.84069 10.771C9.39932 10.2727 10.136 9.96988 10.9435 9.96987L21.0564 9.96973Z"
        fill="white"
      />
    </svg>
  );
};
