import React, { FC, useLayoutEffect, useMemo } from 'react';
import {
  Button,
  CopyHash,
  Informer,
  TableController,
  Typography,
  LoaderOverlay,
  TrashIcon,
  EditIcon,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import {
  Mention,
  Network,
  useExplorerFindMentionsForAddressLazyQuery,
} from '@apolloGenerated';
import { getNetworkEnum } from '@helpers/address';
import { formatDate } from '@utils/timestamp';
import cls from './contentMarking.module.scss';
import { RiskDot } from '@component/NewRisk';
import sdk from '@aml/sdk';
import i18n from 'i18next';
import { useNavigate } from 'react-justanother-router';
import { RouterName } from '../../router';
import { useModal } from '@hooks/useModal';

interface Props {
  address?: string;
  network?: string;
}

export const ContentMarkingComponent: FC<Props> = ({ address, network }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();

  const [fetchMentions, { data, loading }] = useExplorerFindMentionsForAddressLazyQuery();

  const { data: categoryDict } = sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  const openDialog = useModal('dialogMarkingDelete');

  const headers = () => [
    { title: i18n.t('titles.date'), value: 'date', width: '204px' },
    { title: i18n.t('links.category'), value: 'category', width: '328px' },
    { title: i18n.t('titles.source'), value: 'source', width: '258px' },
    { title: i18n.t('titles.source_address'), value: 'source_address', width: '252px' },
    { title: i18n.t('titles.proofs'), value: 'proofs', width: '160px' },
    { title: '', value: 'actions', width: '120px' },
  ];

  useLayoutEffect(() => {
    if (!address || !network) return;

    fetchMentions({
      variables: {
        network: getNetworkEnum(network),
        address,
      },
      fetchPolicy: 'network-only',
    });
  }, [address, network, categoryDict]);

  const mentions = useMemo(
    () => data?.explorerFindMentionsForAddress?.edge,
    [data?.explorerFindMentionsForAddress?.edge],
  );

  const renderDate = (timestamp: string) => {
    return (
      <div>
        <Typography variant="body-14">{formatDate(timestamp, true)}</Typography>
        <Typography variant="body-12" color="on-secondary-2">
          {formatDate(timestamp, false)}
        </Typography>
      </div>
    );
  };

  const openAddress = (sourceAddress: string) => {
    const url = sourceAddress.match(/^https?:/) ? sourceAddress : '//' + sourceAddress;

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderCategory = (itemNumber: number, color?: string) => {
    const findCategory = Object.values(categoryDict?.params || {})?.find(
      (item) => item.number === itemNumber,
    );

    return (
      <div>
        <div className={cls.group}>
          {color && <RiskDot risk={findCategory?.risk ? findCategory?.risk : 0.1} />}
          {findCategory?.name || ' '}
        </div>
      </div>
    );
  };

  const renderSourceAddressLink = (sourceAddress?: string) => {
    if (!sourceAddress?.length) return '';

    return (
      <CopyHash hash={sourceAddress} onClickHash={() => openAddress(sourceAddress)} />
    );
  };

  const openDetails = (id: number) => {
    navigate(RouterName.MarkingDetails, {}, { query: address, id });
  };

  const renderProofs = (id: number) => {
    return (
      <Button size={'small'} variant={'outlined'} onClick={() => openDetails(id)}>
        {t('titles.viewDetails')}
      </Button>
    );
  };

  const filterMentions = useMemo(() => {
    if (!mentions?.length) return [];
    return mentions.filter((item) => item?.reportId || item?.isPaid);
  }, [mentions, data]);

  const openEdit = (id: number) => {
    navigate(RouterName.MarkingEdit, {}, { id, address, network });
  };

  const onSuccess = async () => {
    if (!address || !network) return;

    await fetchMentions({
      variables: {
        network: getNetworkEnum(network),
        address,
      },
      fetchPolicy: 'network-only',
    });

    navigate(RouterName.Marking, {}, { query: address });
  };

  const openModal = (uuid: string, network: Network) => {
    openDialog({ uuid, network, onSuccess });
  };

  const renderAction = (id: number, uuid: string) => {
    return (
      <div className={cls.group}>
        <Button
          variant={'outlined'}
          prependIcon={EditIcon}
          icon
          size={'small'}
          onClick={() => openEdit(id)}
        />
        <Button
          variant={'outlined'}
          prependIcon={TrashIcon}
          icon
          size={'small'}
          onClick={() => openModal(uuid, network as Network)}
        />
      </div>
    );
  };

  const list = useMemo(() => {
    return (
      filterMentions.map((item) => ({
        date: renderDate(item.createdAt),
        category: renderCategory(
          item.risk?.categoryNumber || item.source.categoryNumber,
          item.risk?.color,
        ),
        source: item?.source?.name || 'MatchSystems Reported',
        source_address: renderSourceAddressLink(item.source.sourceAddress),
        proofs: renderProofs(item?.id),
        actions: renderAction(item?.id, (item as Mention)?.uuid),
      })) || []
    );
  }, [data, filterMentions, categoryDict]);

  if (!network && address) {
    return (
      <div className={cls.informer}>
        <Informer type={'empty'} title={''} subtitle={t('marking.notFound')} />
        <Button to={RouterName.MarkingCreate}>{t('marking.add')}</Button>
      </div>
    );
  }

  if (!list.length && address) {
    return (
      <div className={cls.informer}>
        <Informer type="empty" title={t('strings.noresults')} subtitle={''} />
      </div>
    );
  }

  if (!list.length) {
    return (
      <div className={cls.informer}>
        <Informer type={'info'} title={''} subtitle={t('titles.searchDisplay')} />
      </div>
    );
  }

  return (
    <>
      <div className={cls.table}>
        <TableController headers={headers()} data={list} />
      </div>
      <LoaderOverlay show={loading} />
    </>
  );
};
