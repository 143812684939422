import React, { FC } from 'react';
import * as gqlTypes from '../../apolloGenerated';
import { Redirect } from 'react-justanother-router';
import { getNetworkEnum } from '@helpers/address';
import { useTranslation } from 'react-i18next';
import { AppError } from '../../component';
import { Loader } from '@rubin-dev/goblin';

type ExplorerBlockTransactionsScreenProps = {
  params: {
    network: string;
    hash: string;
  };
};

export const ExplorerBlockTransactionsScreen: FC<
  ExplorerBlockTransactionsScreenProps
> = ({ params }) => {
  const { t } = useTranslation();

  const { data, loading, error } = gqlTypes.useExplorerFindBlockByHashQuery({
    variables: {
      network: getNetworkEnum(params.network),
      hash: params.hash,
    },
  });

  if (error) {
    return <AppError error={t('errors.reloading')} />;
  }

  if (loading) {
    return <Loader />;
  }

  const height = data?.explorerFindBlockByHash?.node?.height ?? null;

  return height ? (
    <Redirect to="explorer_block" params={{ height, network: params.network }} />
  ) : null;
};
