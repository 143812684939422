import React, { FC, PropsWithChildren } from 'react';
import { useMeQuery } from '@apolloGenerated';
import styles from './styles.module.scss';
import { LoaderOverlay } from '@rubin-dev/goblin';
import { LayoutUser } from '@component/Layout';

export const LayoutGraph: FC<PropsWithChildren> = ({ children }) => {
  const { loading } = useMeQuery();

  if (loading) return <LoaderOverlay show={true} />;

  return (
    <LayoutUser>
      <div className={styles.graphLayout}>{children}</div>
    </LayoutUser>
  );
};
