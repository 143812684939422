import { LinkList } from './LinkList';
import { Link } from './Link';

export class LinkService {
  static getLinksByHash(hash: string): Link[] {
    return Object.values(LinkList.hashList).filter((item) => item.hash.includes(hash));
  }

  static generateLinkHash(from: string, to: string): string {
    return [from, to].join();
  }
  static getAddressesByHash(hash: string): { source: string; target: string } {
    const [source, target] = hash.split(',');
    return { source, target };
  }
}
