import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const TgIcon: FC<IconProps> = ({ width = 32, height = 32, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="16" cy="16" r="16" fill="#419FD9" />
      <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_3526_89957)" />
      <path
        d="M7.52765 15.5725C12.0895 13.5432 15.1315 12.2054 16.6536 11.559C20.9994 9.71341 21.9024 9.39282 22.491 9.38212C22.6204 9.37991 22.9099 9.41266 23.0974 9.56799C23.2557 9.69915 23.2992 9.87633 23.3201 10.0007C23.3409 10.125 23.3669 10.4083 23.3462 10.6297C23.1107 13.1561 22.0917 19.2871 21.5733 22.1167C21.354 23.314 20.922 23.7155 20.5039 23.7548C19.5951 23.8402 18.9051 23.1416 18.0249 22.5525C16.6476 21.6307 15.8696 21.0569 14.5327 20.1574C12.9876 19.1178 13.9892 18.5465 14.8697 17.6127C15.1001 17.3684 19.1041 13.6499 19.1816 13.3126C19.1913 13.2704 19.2003 13.1132 19.1088 13.0301C19.0173 12.9471 18.8822 12.9755 18.7848 12.9981C18.6466 13.0301 16.4464 14.5149 12.1841 17.4526C11.5596 17.8904 10.9939 18.1038 10.4871 18.0926C9.92833 18.0803 8.85354 17.77 8.05455 17.5049C7.07455 17.1796 6.29567 17.0076 6.36349 16.4553C6.39882 16.1675 6.78687 15.8733 7.52765 15.5725Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3526_89957"
          x1="16"
          y1="0"
          x2="16"
          y2="31.7626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
