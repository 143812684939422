import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormGenericData,
  LoaderOverlay,
  Spacer,
  TextField,
  useForm,
} from '@rubin-dev/goblin';

export type AuthRestoreFormProps = FormGenericData<AuthRestoreFormInput>;

export type AuthRestoreFormInput = {
  email: string;
};

const defaultValues: AuthRestoreFormInput = {
  email: '',
};

export const AuthRestoreForm: FC<AuthRestoreFormProps> = ({
  loading,
  formOnSubmit,
  initialValues,
}): JSX.Element => {
  const { t } = useTranslation();
  const { errors, onCmdEnterSubmit, onChange, onSubmit, values } =
    useForm<AuthRestoreFormInput>(formOnSubmit, {
      ...defaultValues,
      ...initialValues,
    });

  return (
    <form onKeyDown={onCmdEnterSubmit} onSubmit={onSubmit}>
      <TextField
        label={t('titles.email')}
        size="large"
        fullWidth
        autoFocus
        onChange={onChange}
        name="email"
        id="email"
        value={values.email.toLowerCase()}
        error={errors?.email}
      />
      <Spacer size={12} />
      <Button type="submit">{t('titles.restorepasswd')}</Button>
      <LoaderOverlay show={loading} />
    </form>
  );
};
