import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  MouseEvent as MouseEvent$1,
  ElementType,
  ChangeEvent,
} from 'react';
import {
  Button,
  CloseDenseIcon,
  FilePlus,
  InfoIcon,
  TextField,
  Tooltip,
  Typography,
  LoaderOverlay,
  useForm,
  FormGenericData,
} from '@rubin-dev/goblin';
import cls from '@component/Marking/contentMarking.module.scss';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  Network,
  Screenshot,
  useExplorerFindMentionLazyQuery,
  useExplorerScreenshotRemoveMutation,
} from '@apolloGenerated';
import { getNetworkEnum } from '@helpers/address';
import { useModal } from '@hooks/useModal';
import { OtherAddresses } from '@component/Marking/OtherAddresses';
import { detectNetworkByAddress } from '@aml/validation';
import sdk from '@aml/sdk';

type FormInput = {
  hotWallet: boolean;
  owner: string;
  address: string;
  name: string;
  description: string;
  ref: string;
};

type Props = {
  id?: number;
  network?: Network;
  title?: string;
  address?: string;
  isEdit?: boolean;
} & FormGenericData<MarkingForm>;

export type MarkingForm = {
  uuid?: string;
  address?: string;
  link?: string;
  network?: Network;
  owner: string;
  name: string;
  categoryName: string;
  categoryNumber: number;
  level: number;
  mentionSourceID?: number;
  description: string;
  otherAddresses: string[];
  screenshots?: File[];
} & FormGenericData<FormInput>;

const defaultValues = {
  uuid: '',
  address: '',
  link: '',
  owner: '',
  categoryNumber: 0,
  level: 0,
  description: '',
  otherAddresses: [],
  screenshots: [],
};

export const FormMarking: FC<Props> = ({
  id,
  network,
  title,
  address,
  initialValues,
  formOnSubmit,
  isEdit = false,
}) => {
  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [screenshots, setScreenshots] = useState<Screenshot[]>([]);

  const { data: categoryDict } = sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  const initialState = {
    ...defaultValues,
    ...initialValues,
  } as MarkingForm;

  const openDialog = useModal('dialogCategoryList');

  const [fetchFindMention, { data: findMention, loading }] =
    useExplorerFindMentionLazyQuery();

  const [mutate] = useExplorerScreenshotRemoveMutation();

  const { onChange, values, setBatchValues, onSubmitTrigger } = useForm<MarkingForm>(
    formOnSubmit,
    initialState,
  );

  useEffect(() => {
    if (!id || !network) return;

    fetchFindMention({
      variables: {
        id,
        network: getNetworkEnum(network),
      },
      fetchPolicy: 'network-only',
    });
  }, [id, network, address]);

  useEffect(() => {
    const mention = findMention?.explorerFindMention?.mention;

    setBatchValues({
      uuid: mention?.uuid,
      address: mention?.address || address,
      link: mention?.link,
      owner: mention?.owner,
      categoryNumber: mention?.categoryNumber,
      // level: mention?.categoryNumber,
      description: mention?.description,
      otherAddresses: mention?.otherAddresses?.map((item) => item.address) || [],
    });

    if (categoryDict?.params) {
      setCategoryName(
        Object.values(categoryDict?.params).find(
          (item) => item.number === mention?.categoryNumber,
        )?.name || '',
      );
    }

    setScreenshots(findMention?.explorerFindMention?.screenshots as Screenshot[]);
  }, [findMention, categoryDict]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setBatchValues({
        screenshots: [...files, ...acceptedFiles],
      });

      setFiles([...files, ...acceptedFiles]);
    },
  });

  const onCloseImg = async (
    e: MouseEvent$1<ElementType, MouseEvent$1>,
    index: number,
  ) => {
    e.stopPropagation();

    if (files.length > 0) {
      const newList = [...files];
      newList.splice(index, 1);

      setFiles(newList);
      return;
    }

    if (screenshots?.length) {
      const newList = [...screenshots];

      if (isEdit && screenshots[index]) {
        await mutate({
          variables: {
            req: {
              uuid: values.uuid,
              path: screenshots[index].path,
            },
          },
        });
      }

      newList.splice(index, 1);

      setScreenshots(newList);
      return;
    }
  };

  const renderImage = (file: string, index: number) => (
    <>
      <Typography
        variant="head-16"
        tag="button"
        color="surface-1"
        //@ts-ignore
        type="button"
        className={cls.btnClose}
        onClick={(e) => onCloseImg(e, index)}
      >
        <CloseDenseIcon />
      </Typography>
      <img
        alt={''}
        className={cls.image}
        src={file}
        onLoad={() => {
          URL.revokeObjectURL(file);
        }}
      />
    </>
  );

  const hasError = useMemo(() => {
    if (!values?.otherAddresses) return false;

    return values?.otherAddresses.some((item) => !detectNetworkByAddress(item));
  }, [values]);

  const thumbsImages = useMemo(() => {
    return [...files, ...(screenshots ? screenshots : [])].map((file, index) => (
      <div key={index} className={cls.image_wrapper}>
        {renderImage(
          Object.hasOwn(file, 'image')
            ? `data:image/png;base64,${(file as Screenshot).image}`
            : URL.createObjectURL(file as Blob),
          index,
        )}
      </div>
    ));
  }, [screenshots, files]);

  const onCallbackCategory = (params: { name: string; id: number }) => {
    setCategoryName(params?.name);
    setBatchValues({ categoryNumber: params?.id });
  };

  const getCategory = () => {
    openDialog({ onCallback: onCallbackCategory });
  };

  const onChangeOtherAddresses = (otherAddresses: string[]) => {
    setBatchValues({ otherAddresses });
  };

  const onChangeLevel = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (value < 0) {
      setBatchValues({ level: 0 });
    }

    if (value > 255) {
      setBatchValues({ level: 255 });
    }
  };

  return (
    <div className={cls.wrapper}>
      <div>
        <Typography variant={'head-32'} color={'on-surface-primary-1'}>
          {title}
        </Typography>
        {address && isEdit && (
          <Typography variant={'head-16'} color={'on-surface-primary-1'}>
            {t('titles.address')}: {address}
          </Typography>
        )}
      </div>
      <form
        className={cls.form}
        onSubmit={(e) => {
          setFieldError(false);
          e.preventDefault();

          if (!values.link || !detectNetworkByAddress(values?.address || '')) {
            setFieldError(true);
          }

          onSubmitTrigger();
        }}
      >
        {!isEdit && (
          <div className={cls.group}>
            <TextField
              id="address"
              name="address"
              placeholder={t('titles.address')}
              onChange={onChange}
              error={
                fieldError && !detectNetworkByAddress(values?.address || '')
                  ? t('errors.invalid_address')
                  : undefined
              }
              value={values?.address?.trim()}
              fullWidth
            />
            <Tooltip
              className={cls.tooltip}
              placement="right-end"
              label={t('tooltips.enterFullAddress')}
            >
              <InfoIcon color={'#A3A9BD'} />
            </Tooltip>
          </div>
        )}
        <div className={cls.group}>
          <div className={cx(cls.group, cls.field)} onClick={getCategory}>
            <TextField placeholder={t('links.category')} value={categoryName} fullWidth />
          </div>
          <Tooltip
            className={cls.tooltip}
            placement="right-end"
            label={t('tooltips.category')}
          >
            <InfoIcon color={'#A3A9BD'} />
          </Tooltip>
        </div>
        <div className={cls.group}>
          <TextField
            id="level"
            name="level"
            label={t('mention.level')}
            fullWidth
            type="number"
            disabled
            value="160"
            onChange={onChangeLevel}
          />
          <Tooltip
            className={cls.tooltip}
            placement="right-end"
            label={t('tooltips.level')}
          >
            <InfoIcon width={24} height={24} color={'#A3A9BD'} />
          </Tooltip>
        </div>
        <div className={cls.group}>
          <OtherAddresses
            otherAddresses={values?.otherAddresses}
            onChange={onChangeOtherAddresses}
          />
          <Tooltip
            className={cls.tooltip}
            placement="right-end"
            label={t('tooltips.additionalAddresses')}
          >
            <InfoIcon width={24} height={24} color={'#A3A9BD'} />
          </Tooltip>
        </div>
        <div className={cls.group}>
          <TextField
            id="name"
            name="owner"
            label={t('strings.owner')}
            fullWidth
            onChange={onChange}
            value={
              values?.name?.trim() || findMention?.explorerFindMention.mention?.owner
            }
          />
          <Tooltip
            className={cls.tooltip}
            placement="right-end"
            label={t('tooltips.nameOwner')}
          >
            <InfoIcon width={24} height={24} color={'#A3A9BD'} />
          </Tooltip>
        </div>
        <div className={cls.group}>
          <TextField
            id="description"
            name="description"
            label={t('mention.description')}
            fullWidth
            onChange={onChange}
            value={
              values?.description?.trim() ||
              findMention?.explorerFindMention.mention?.description
            }
          />
          <Tooltip
            className={cls.tooltip}
            placement="right-end"
            label={t('tooltips.provide')}
          >
            <InfoIcon width={24} height={24} color={'#A3A9BD'} />
          </Tooltip>
        </div>
        <div className={cls.group}>
          <TextField
            id="link"
            name="link"
            label={'Ref*'}
            fullWidth
            error={fieldError && !values?.link ? t('errors.required_field') : undefined}
            onChange={onChange}
            value={values?.link?.trim() || findMention?.explorerFindMention.mention?.link}
          />
          <Tooltip
            className={cls.tooltip}
            placement="right-end"
            label={t('tooltips.info')}
          >
            <InfoIcon width={24} height={24} color={'#A3A9BD'} />
          </Tooltip>
        </div>
        <div className={cx(cls.group, cls.group_start)}>
          <div {...getRootProps({ className: cls.dropzone })}>
            <input {...getInputProps()} />
            <div className={cx(cls.group, cls.group_column)}>
              <FilePlus />
              <Typography variant={'body-14'} color={'on-surface-primary-1'}>
                {t('titles.upload')}
              </Typography>
            </div>
            <div className={cls.images}>{thumbsImages}</div>
          </div>
          <div className={cls.tooltip}>
            <Tooltip
              className={cls.tooltip}
              placement="right-end"
              label={t('tooltips.provideScreenshot')}
            >
              <InfoIcon width={24} height={24} color={'#A3A9BD'} />
            </Tooltip>
          </div>
        </div>
        <Button className={cls.btn} fullWidth type={'submit'} disabled={hasError}>
          {t('buttons.save')}
        </Button>
      </form>
      <LoaderOverlay show={loading} />
    </div>
  );
};
