import React, { FC, PropsWithChildren } from 'react';
import { LayoutUser } from '../../../Layout';
import { BillingCheckCounter } from '@component/billing';
import { Spacer } from '@rubin-dev/goblin';
import { hasPermission } from '@utils/auth';
import { PageNotFound } from '../../../../screen';
import { SUBSCRIPTION_EXPLORER_MARKUP } from '@constants/permissions.const';

interface LayoutMarkupProps {
  params: {
    address: string;
    network: string;
  };
}

export const LayoutMarkup: FC<PropsWithChildren<LayoutMarkupProps>> = ({ children }) => {
  if (!hasPermission(SUBSCRIPTION_EXPLORER_MARKUP)) return <PageNotFound />;

  return (
    <LayoutUser headerSlot={<BillingCheckCounter billingKey="markup" />}>
      {children}
      <Spacer size={24} />
    </LayoutUser>
  );
};
