import React, { FC } from 'react';
import { getNetworkEnum } from '@helpers/address';
import { useExplorerClusterQuery } from '@apolloGenerated';
import { BalanceCard } from '@graph-sidebar-module/shared';

type ClusterBalanceCardProps = {
  wid: string;
  network: string;
};
export const ClusterBalanceCard: FC<ClusterBalanceCardProps> = ({ wid, network }) => {
  const { data: walletData, loading } = useExplorerClusterQuery({
    variables: { network: getNetworkEnum(network), id: wid },
  });
  const stats = walletData?.explorerClusterStats?.stats;

  return (
    <BalanceCard
      network={network}
      loading={loading}
      total={Number(stats?.totalAmount || 0)}
      totalIn={Number(stats?.receivedAmount || 0)}
      totalOut={Number(stats?.sentAmount || 0)}
    />
  );
};
