import React, { FC, useState } from 'react';
import {
  AppError,
  AuthRegistrationForm,
  AuthRegistrationFormInput,
} from '../../component';
import { useRegistrationMutation } from '@apolloGenerated';
import { Link } from 'react-justanother-router';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import { FormCallback, Paper, Spacer, toaster, Typography } from '@rubin-dev/goblin';

export const AuthRegistrationScreen: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [mutate, { loading, error, reset }] = useRegistrationMutation();

  const formOnSubmit: FormCallback<AuthRegistrationFormInput> = async (
    data,
    setFormErrors,
    setBatchValues,
  ) => {
    try {
      const resp = await mutate({
        variables: {
          input: data,
        },
      });

      const formErrors = resp.data?.registration?.errors || {};
      if (Object.keys(formErrors).length > 0) {
        toaster.error({ title: 'validation error' });
        setFormErrors(formErrors);
      } else {
        setBatchValues({
          email: '',
          password: '',
        });
        setSuccess(true);
      }
      reset();
    } catch (e) {
      toaster.error({ title: t('errors.reloading') });
      console.error(e);
    }
  };

  return (
    <>
      <Link to={RouterName.AuthLogin} className="b-auth__back">
        &larr; {t('titles.back')}
      </Link>
      <Paper>
        <Typography variant="head-24" color="on-surface-primary-1">
          {t('titles.registration')}
        </Typography>
        <Spacer size={8} />
        {error && <AppError error={error.message} />}
        {success ? (
          <div>{t('strings.thankForRegister')}</div>
        ) : (
          <AuthRegistrationForm loading={loading} formOnSubmit={formOnSubmit} />
        )}
      </Paper>
    </>
  );
};
