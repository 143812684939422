import { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { Button, Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../../../router';
import { useNavigate, useRouter } from 'react-justanother-router';
import cx from 'classnames';
import { LayoutUser } from '@component/Layout';
import { IconAnalyzer, IconApi, IconMarkup } from '@shared/ui';

export const LayoutTariff: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  const findPath = (name: string) => {
    const { urlFor } = useNavigate();
    const { history } = useRouter();

    const isActive = String(history.location.pathname).startsWith(urlFor(name));

    return isActive ? 'active' : '';
  };

  return (
    <LayoutUser>
      <section className={styles['tariff']}>
        <div className={styles['tariff__wrapper']}>
          <div className={styles['tariff__header']}>
            <Typography
              variant={'head-32'}
              tag={'h1'}
              className={styles['tariff__title']}
              color={'on-surface-primary-1'}
            >
              {t('titles.pricingPlans')}
            </Typography>
            <div className={styles['tariff__controls']}>
              <Button
                variant={'outlined'}
                prependIcon={() => IconAnalyzer({ width: 32, height: 32 })}
                to={RouterName.TariffAnalyzer}
                className={cx(
                  styles['tariff__btn'],
                  styles[findPath(RouterName.TariffAnalyzer)],
                )}
              >
                <Typography variant={'head-16'} color={'on-surface-primary-1'}>
                  {t('titles.analyzer')}
                </Typography>
              </Button>
              <Button
                variant={'outlined'}
                prependIcon={() => IconMarkup({ width: 32, height: 32 })}
                to={RouterName.TariffMarkup}
                className={cx(
                  styles['tariff__btn'],
                  styles[findPath(RouterName.TariffMarkup)],
                )}
              >
                <Typography variant={'head-16'} color={'on-surface-primary-1'}>
                  {t('titles.markup')}
                </Typography>
              </Button>
              <Button
                variant={'outlined'}
                prependIcon={() => IconApi({ width: 32, height: 32 })}
                to={RouterName.TariffApi}
                className={cx(
                  styles['tariff__btn'],
                  styles[findPath(RouterName.TariffApi)],
                )}
              >
                <Typography variant={'head-16'} color={'on-surface-primary-1'}>
                  {t('links.api')}
                </Typography>
              </Button>
            </div>
          </div>
          <div className={styles['tariff__content']}>{children}</div>
        </div>
      </section>
    </LayoutUser>
  );
};
