import React, { FC } from 'react';
import { getRiskColor } from '@helpers/address';
import styles from './risk-score.module.scss';

type RiskDotProps = {
  size?: number;
  risk?: number;
  color?: string;
};
export const RiskDot: FC<RiskDotProps> = ({ risk = 0.1, size = 8, color }) => {
  return (
    <span
      className={styles.riskScore__circle}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        background: color ? color : getRiskColor(risk),
      }}
    />
  );
};
