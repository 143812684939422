import React, { FC, PropsWithChildren } from 'react';
import styles from './blur.module.scss';
import { RouterName } from '../../../router';
import { Link } from 'react-justanother-router';

interface Props {
  to?: RouterName;
  target?: '_blank' | '_self' | '_parent' | '_top';
  value?: string;
  params?: object;
}
export const Blur: FC<PropsWithChildren<Props>> = ({
  to,
  target = '_blank',
  params,
  children,
  value,
}) => {
  return (
    <div className={styles.blur} data-value={value}>
      {children &&
        (to ? (
          <Link to={to} target={target} params={params} className={styles.blur__link}>
            {children}
          </Link>
        ) : (
          children
        ))}
    </div>
  );
};
