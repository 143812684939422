import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GraphWindow,
  useSidebarData,
  useSidebarWindow,
} from '@graph-sidebar-module/shared';
import { LineTable } from '@graph-sidebar-module/features/Line';
import { ListItem } from '@shared/ui';
import { DriverHeader, Spacer, Typography } from '@rubin-dev/goblin';
import { LineInfo } from '@graph-sidebar-module/entities/Line';

export const LineWindow: FC = () => {
  const { t } = useTranslation();
  const { hash, network, isLoading } = useSidebarData();
  const [{ onHide }, { isShow }] = useSidebarWindow();

  return (
    <GraphWindow
      headerSlot={
        <DriverHeader
          titleSlot={t('links.transaction')}
          subtitleSlot={
            <ListItem
              label={`${t('titles.blockchain')}:`}
              value={
                <Typography variant="body-16" color="on-surface-primary-1">
                  {network}
                </Typography>
              }
              row
            />
          }
          // rightSlot={<TokenSelect />}
        />
      }
      isOpen={isShow}
      onClose={onHide}
      width={712}
      isLoading={isLoading}
    >
      <LineInfo hash={hash} network={network} />
      <Spacer size={28} />
      <LineTable />
    </GraphWindow>
  );
};
