import React, { FC, useContext, useMemo } from 'react';
import {
  Divider,
  Dropdown,
  DropdownItem,
  HelpCircleIcon,
  Paper,
  Typography,
  FileText,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { AppContext } from '../../../contexts';
import {
  translateLinks,
  translateLinksRU,
} from '@shared/ui/InstructionsSelect/constants';
import { RouterName } from '../../../router';
import { useNavigate } from 'react-justanother-router';

export const InstructionsSelect: FC = () => {
  const { language } = useContext(AppContext);
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const openAddress = (link: string) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const getListLinks = useMemo(() => {
    const translateData = {
      en: translateLinks(),
      es: translateLinks(),
      pt: translateLinks(),
      ru: translateLinksRU(),
    };

    return translateData[language as keyof typeof translateData];
  }, [language]);

  return (
    <Dropdown
      targetSlot={<HelpCircleIcon color={'#fff'} className={styles.instructions__icon} />}
      placement="right-end"
      width={260}
      zIndex={1000}
    >
      <Paper px={4} py={4} radius={4}>
        <Typography
          variant={'body-16'}
          color="on-surface-primary-2"
          className={styles.instructions__item}
        >
          <FileText color={'#A3A9BD'} />
          {t('titles.instructions')}:
        </Typography>
        <Divider className={styles.instructions__divider} />
        {getListLinks &&
          getListLinks.map((item) => (
            <DropdownItem
              key={item.title}
              fullWidth
              onClick={() => openAddress(item.link)}
            >
              <Typography variant={'body-16'} color="on-surface-primary-1">
                {item.title}
              </Typography>
            </DropdownItem>
          ))}
        <DropdownItem
          onClick={() => navigate(RouterName.CategoryListAll)}
          fullWidth
          label={t('titles.categoryList')}
        />
      </Paper>
    </Dropdown>
  );
};
