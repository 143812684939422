import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogFooter,
  Typography,
  WithCloseModal,
} from '@rubin-dev/goblin';

type RemoveApiKeyProps = {
  onSubmit(): void;
};
declare global {
  interface ModalProps {
    removeApiKey: RemoveApiKeyProps;
  }
}
export const RemoveApiKeyModal: FC<WithCloseModal<RemoveApiKeyProps>> = ({
  onSubmit,
  onClose,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('titles.removeApiKey')}
      closed
      width={520}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              {t('titles.cancel')}
            </Button>
          }
          endSlot={
            <Button
              fullWidth
              onClick={() => {
                onClose();
                onSubmit();
              }}
              color="danger"
            >
              {t('buttons.remove')}
            </Button>
          }
        />
      }
    >
      <Typography variant="body-16">{t('strings.apiKeyWarn')}</Typography>
    </Dialog>
  );
};
