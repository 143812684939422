import { PropsWithChildren, FC, JSX } from 'react';
import { Typography } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

interface Props {
  title: string;
  link: JSX.Element;
}

export const ItemContact: FC<PropsWithChildren<Props>> = ({ children, title, link }) => {
  return (
    <div className={styles.item}>
      <div>{children}</div>
      <div>
        <Typography variant={'body-14'}>{title}</Typography>
        {link}
      </div>
    </div>
  );
};
