import { Link } from './Link';
import { LinkItem } from '@graph/types';
import { LinkService } from './LinkService';
import { Node, NodeList } from '@graph/models';
import { TableType } from '@graph/types';
import { ACTIVE_COLOR, COLORS } from '@graph/const/address.const';

export class LinkList {
  static hashList: Record<string, Link> = {};

  static addLink(link: Link, rewrite?: boolean) {
    const linkHash = link.hash;
    if (LinkList.hashList[linkHash] && !rewrite) return;
    LinkList.hashList[link.hash] = link;
  }

  static removeLink(linkHash: string) {
    delete LinkList.hashList[linkHash];
    delete LinkList.hashList[linkHash.split(',').reverse().join()];
  }

  static getLinksForGraph(currentHash: string): LinkItem[] {
    const linksItems: Link[] = Object.values(LinkList.hashList);
    return linksItems.reduce<LinkItem[]>((acc, el) => {
      el.setLink(currentHash);
      if (currentHash === el.hash || el.hash.split(',').reverse().join() === currentHash)
        el.activateLink();
      if (el.link) acc.push(el.link);
      return acc;
    }, []);
  }

  static getAnnotationLinksForGraph(currentHash: string): LinkItem[] {
    const linksItems: Node[] = Object.values(NodeList.hashList);
    return linksItems.reduce<LinkItem[]>((acc, el) => {
      const annotations = Object.values(el.annotation);
      annotations.forEach((item) => {
        const text = item.getText();
        if (text)
          acc.push({
            source: item.hash as any,
            target: item.parentHash as any,
            color: currentHash === el.hash ? ACTIVE_COLOR : COLORS.large,
            label: {},
            direction: 'target',
            type: TableType.AnnotationLink,
          });
      });
      return acc;
    }, []);
  }

  static getLinkByHashes(target: string, source: string): Link {
    return (
      LinkList.hashList[LinkService.generateLinkHash(source, target)] ||
      LinkList.hashList[LinkService.generateLinkHash(target, source)]
    );
  }
}
