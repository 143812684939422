import {
  AddressByTransaction,
  AddressCheckedType,
  AddressTransactionsFilterTxType,
  Cluster,
  Network,
} from '@apolloGenerated';
import { Transaction } from '@graph/modules';
import getReverseTxType from '@graph/libs/helpers/getReverseTxType';
import { TableType } from '@graph/types';
import { AddressTable } from '@graph/models';

export class Address {
  type: AddressTransactionsFilterTxType;
  address: string;
  hash: string;
  amount: string;
  token: string;
  network: Network;
  cluster?: Cluster;
  checked: boolean = false;
  constructor(
    { token, address, summ, cluster, network }: AddressByTransaction,
    type: AddressTransactionsFilterTxType,
    checked?: boolean,
  ) {
    this.address = address;
    this.type = type;
    this.amount = summ;
    this.token = token ? token.toUpperCase() : network;
    this.network = network;
    this.hash = cluster?.id || address;
    if (cluster) this.cluster = cluster;
    if (checked) this.checked = checked;
  }

  updateChecked(
    currentHash: string,
    { txid }: Transaction,
    checked: AddressCheckedType[],
  ) {
    this.checked = AddressTable.hasChecked(
      checked,
      AddressTable.getCheckedItem(
        txid,
        getReverseTxType(this.type),
        currentHash,
        this.hash,
      ),
    );
  }

  createTransaction(
    currentHash: string,
    { txid, token, timestamp, amount }: Transaction,
    tableType: TableType,
  ): Transaction {
    const network = this.network;
    return new Transaction(
      {
        txID: txid,
        token,
        timestamp,
        amount: this.type === AddressTransactionsFilterTxType.Sent ? amount : this.amount,
        direction: getReverseTxType(this.type),
        recvAddr: [{ address: this.hash, network }],
        senderAddr: [{ address: currentHash, network }],
      },
      network,
      tableType,
    );
  }
}
