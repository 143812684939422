import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';
import { Network } from '@apolloGenerated';

export type NetworkIconProps = {
  network: Network;
};
export const NetworkIcon: FC<IconProps & NetworkIconProps> = ({
  width = 32,
  height = 32,
  network,
  ...rest
}) => {
  if (network === Network.Btc)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        {...rest}
      >
        <g clipPath="url(#clip0_1130_1918)">
          <path
            d="M32 16C32 19.1645 31.0616 22.2579 29.3035 24.8891C27.5454 27.5203 25.0466 29.5711 22.1229 30.7821C19.1993 31.9931 15.9823 32.3099 12.8786 31.6926C9.77487 31.0752 6.92394 29.5514 4.6863 27.3137C2.44866 25.0761 0.924806 22.2251 0.307443 19.1214C-0.309921 16.0177 0.00693248 12.8007 1.21793 9.87707C2.42894 6.95345 4.4797 4.45459 7.11088 2.69649C9.74207 0.938384 12.8355 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16Z"
            fill="#F7931A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.371 7.30608L15.0481 8.29081L15.8699 5.22754L17.7084 5.72645L16.9186 8.66318L18.4183 9.06608L19.2095 6.09736L21.0801 6.59772L20.2743 9.58245C20.2743 9.58245 23.3288 10.2588 24.0473 12.7432C24.7659 15.2275 22.4677 16.5323 21.7579 16.5817C21.7579 16.5817 24.4343 18.0494 23.515 20.9366C22.5957 23.8239 19.7739 24.3403 16.8052 23.6784L15.9993 26.7737L14.1288 26.2734L14.9506 23.2261L13.467 22.8217L12.6452 25.8908L10.7892 25.3919L11.6124 22.3374L7.83789 21.3192L8.78916 19.2072C8.78916 19.2072 9.85389 19.4981 10.2568 19.5941C10.6597 19.6901 10.9186 19.2712 11.0321 18.8523C11.1455 18.4334 12.8546 11.4908 13.0161 10.9192C13.1775 10.3475 13.1121 9.90099 12.4343 9.72499C11.7564 9.54899 10.8343 9.27408 10.8343 9.27408L11.371 7.30608ZM15.0801 16.5003L14.0619 20.5483C14.0619 20.5483 19.1106 22.3708 19.7564 19.8064C20.4023 17.2421 15.0801 16.5003 15.0801 16.5003ZM15.5484 14.5803L16.5477 10.8712C16.5477 10.8712 20.8706 11.645 20.3383 13.709C19.8059 15.773 17.2575 14.9817 15.5484 14.5803Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1130_1918">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  if (network === Network.Eth)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        {...rest}
      >
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#627EEA"
        />
        <path
          d="M16.498 4V12.87L23.995 16.22L16.498 4Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path d="M16.498 4L9 16.22L16.498 12.87V4Z" fill="white" />
        <path
          d="M16.498 21.9682V27.9952L24 17.6162L16.498 21.9682Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path d="M16.498 27.9952V21.9672L9 17.6162L16.498 27.9952Z" fill="white" />
        <path
          d="M16.498 20.5731L23.995 16.2201L16.498 12.8721V20.5731Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M9 16.2201L16.498 20.5731V12.8721L9 16.2201Z"
          fill="white"
          fillOpacity="0.602"
        />
      </svg>
    );
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...rest}
    >
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#26A17B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9228 17.3833V17.3813C17.8128 17.3893 17.2458 17.4233 15.9808 17.4233C14.9708 17.4233 14.2598 17.3933 14.0098 17.3813V17.3843C10.1218 17.2133 7.21978 16.5363 7.21978 15.7263C7.21978 14.9173 10.1218 14.2403 14.0098 14.0663V16.7103C14.2638 16.7283 14.9918 16.7713 15.9978 16.7713C17.2048 16.7713 17.8098 16.7213 17.9228 16.7113V14.0683C21.8028 14.2413 24.6978 14.9183 24.6978 15.7263C24.6978 16.5363 21.8028 17.2113 17.9228 17.3833ZM17.9228 13.7933V11.4273H23.3368V7.81934H8.59578V11.4273H14.0098V13.7923C9.60978 13.9943 6.30078 14.8663 6.30078 15.9103C6.30078 16.9543 9.60978 17.8253 14.0098 18.0283V25.6103H17.9228V18.0263C22.3158 17.8243 25.6168 16.9533 25.6168 15.9103C25.6168 14.8673 22.3158 13.9963 17.9228 13.7933Z"
        fill="white"
      />
    </svg>
  );
};
