import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Loader, SettingDenseIcon, Divider } from '@rubin-dev/goblin';
import styles from './style.module.scss';
import { Billing, useMeQuery } from '@apolloGenerated';
import { router, history, RouterName } from '../../../../router';
import { ListItem } from '@shared/ui';
import { EditLimitModal } from '../../../../screen/Transactions/EditLimitModal';

interface BillingCheckCounterProps {
  billingKey: keyof Omit<Billing, '__typename'>;
  isDashboard?: boolean;
}

export const BillingCheckCounter: FC<BillingCheckCounterProps> = ({
  billingKey,
  isDashboard,
}) => {
  const { loading, data } = useMeQuery();
  const { t } = useTranslation();

  const [isShowSidebar, setStateSidebar] = useState(false);

  const openTariff = () => {
    const routerName: RouterName =
      billingKey === 'markup'
        ? RouterName.TariffMarkup
        : billingKey === 'transactions'
        ? RouterName.TariffApi
        : RouterName.TariffAnalyzer;
    history.navigate(router.urlFor(routerName));
  };
  const CustomDropdown = () => (
    <Button
      variant="outlined"
      icon
      onClick={() => setStateSidebar(true)}
      prependIcon={SettingDenseIcon}
    />
  );

  return (
    <div className={styles.checkCounter}>
      <div className={styles.checkCounter__group}>
        {isDashboard && (
          <>
            <CustomDropdown />
            <Divider vertical style={{ width: '1px', height: '40px' }} />
          </>
        )}
        <ListItem
          label={`${t('markup.checkCount')}:`}
          value={
            loading ? (
              <Loader />
            ) : (
              <Typography variant="head-16" color="on-surface-primary-1">
                {data?.me.billing[billingKey]}
              </Typography>
            )
          }
        />
      </div>
      <Button onClick={openTariff}>
        {t('markup.buy')} {t('markup.checkCount')}
      </Button>
      {isShowSidebar && <EditLimitModal onClose={setStateSidebar} />}
    </div>
  );
};
