import { useCurrentRoute, useNavigate } from 'react-justanother-router';
import { RouterHistory } from '@utils/RouterHistory';
import { RouterName } from '../../router';
import { loadUser } from '@utils/auth';

export const useGoBack = () => {
  const { currentRoute } = useCurrentRoute();
  const { navigate } = useNavigate();

  return () => {
    const user = loadUser();
    const isAuth = !!user;
    const lastRoute = RouterHistory.getPrevRoute(currentRoute.name!, isAuth);
    if (lastRoute?.name) navigate(lastRoute.name, lastRoute.params, lastRoute.query);
    else navigate(isAuth ? RouterName.HomePage : RouterName.AuthLogin);
  };
};
