import React, { FC, PropsWithChildren } from 'react';
import { LayoutUser } from '../../../Layout';
import { PageNotFound } from '../../../../screen';
import { hasPermission } from '@utils/auth';
import { BillingCheckCounter } from '@component/billing';
import { Spacer } from '@rubin-dev/goblin';

type TransactionsLayoutProps = {
  params: {
    address: string;
    network: string;
  };
};

export const LayoutTransactions: FC<PropsWithChildren<TransactionsLayoutProps>> = ({
  children,
}) => {
  if (!hasPermission('api.dashboard')) return <PageNotFound />;
  return (
    <LayoutUser
      headerSlot={<BillingCheckCounter isDashboard billingKey="transactions" />}
    >
      {children}
      <Spacer size={24} />
    </LayoutUser>
  );
};
