import React, { FC } from 'react';
import {
  useGraphDataController,
  GraphToolbar,
  NewVisualizationParams,
  useGraphStatusStore,
  GraphStatus,
} from '../../../../component/NewGraph';
import { getNetworkEnum } from '@helpers/address';
import { GraphSidebar, GraphVisualization } from '@graph/modules';

export type NewVisualizationScreenProps = {
  params: NewVisualizationParams;
  query: {};
};

export const NewVisualizationScreen: FC<NewVisualizationScreenProps> = ({ params }) => {
  const { isLoading, isInitialized, isCrash } = useGraphStatusStore();
  const { tableData, links, nodes, canvas } = useGraphDataController(params);
  const hasSidebar = !!tableData;

  return (
    <>
      <GraphStatus hasSidebar={hasSidebar} />
      <GraphToolbar hasSidebar={hasSidebar} />
      {isInitialized && !isCrash && (
        <GraphVisualization nodes={nodes} links={links} canvas={canvas} />
      )}
      <GraphSidebar
        hash={tableData?.idN}
        network={getNetworkEnum(params.network)}
        checked={tableData?.checked}
        type={tableData?.mode}
        isLoading={isLoading}
      />
    </>
  );
};
