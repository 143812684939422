import { FC } from 'react';
import { LinkItem } from '@graph/types';
import { Divider, DropdownItem, InteractionIcon } from '@rubin-dev/goblin';
import {
  ColorMenu,
  GraphCustomColor,
} from '@graph-visualization-module/entities/ContextMenu';
import { useMenuLinkEvent } from '../../libs/hooks/useMenuLinkEvent';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

export type LinkMenuProps = {
  link: LinkItem;
  onSubmit(): void;
};

export const LinkMenu: FC<LinkMenuProps> = ({ link, onSubmit }) => {
  const { t } = useTranslation();
  const { onChangeColor, onAddAnnotation } = useMenuLinkEvent(link, onSubmit);

  return (
    <>
      {!link?.annotation && (
        <>
          <DropdownItem
            label={t('buttons.addAnnotation')}
            icon={InteractionIcon}
            fullWidth
            onClick={onAddAnnotation}
          />
          <Divider className={cx(styles.linkMenu__hr, styles.divider)} />
        </>
      )}
      <ColorMenu activeColor={link.color as GraphCustomColor} onSelect={onChangeColor} />
    </>
  );
};
