import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import type {
  CalculateItem,
  FindAddressByHashNode,
  Maybe,
  AddressChart,
} from '@apolloGenerated';
import * as gqlTypes from '../../apolloGenerated';
import { getNetworkEnum } from '@helpers/address';
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  Typography,
  FiltersIcon,
  Spacer,
  Table,
  Badge,
  DropdownList,
  Informer,
} from '@rubin-dev/goblin';
import { BigNumber } from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import styles from './category.module.scss';
import { RiskScoreInformation } from '@component/NewRisk/ui/RiskDot/RiskScoreInformation';
import { RiskDot } from '@component/NewRisk/ui/RiskDot/RiskDot';
import { PieChart } from '@shared/ui';
import sdk from '@aml/sdk';

export type chartDataType = {
  id: number | string;
  Import: number | string | undefined | null;
  Export?: string | null;
  Category: string;
  color?: string;
  percent?: string | number;
  ImportAmount?: number;
  ExportAmount?: string;
  riskImport?: string;
  riskExport?: string;
}[];

export type OtherCategoriesType = {
  exportAmount: number;
  importAmount: number;
  exportPercent: number;
  importPercent: number;
};

type ExplorerCategoriesTableProps = {
  network: string;
  address: string;
  node?: Maybe<FindAddressByHashNode>;
  items?: CalculateItem[];
  hideFilter?: boolean;
  chartData: AddressChart[];
  chartLoading: boolean;
  hasOnlyCharts?: boolean;
  risk?: number | null;
};

export const ExplorerCategoriesTable = forwardRef<
  HTMLDivElement,
  ExplorerCategoriesTableProps
>(
  (
    {
      // eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
      node,
      network,
      address,
      items,
      hideFilter = false,
      chartData,
      chartLoading = false,
      hasOnlyCharts = false,
      risk,
    },
    forwardRef,
  ) => {
    const { t } = useTranslation();
    const { data, error } = gqlTypes.useExplorerAddressCategoriesQuery({
      variables: {
        network: getNetworkEnum(network),
        address,
      },
    });

    const { data: categoryDict, refetch } =
      sdk.risk.BTC.queries.useRiskServiceCurrentDict();

    const [isCheck, setIsCheck] = useState<number[]>([]);
    const [list, setList] = useState<any[]>([]);
    const [category, showCategory] = useState<string>('');
    const [onlyPositive, showOnlyPositive] = useState<boolean>(true);
    const [, setColorPie] = useState<{ [key: string]: string }[]>([]);

    const formattedCategories = useMemo(() => {
      const result = items?.filter((item) => item.number !== 0) || [];

      return [
        {
          number: 0,
          name: 'Unmarked',
          color: '#60A69F',
        },
        ...result,
      ];
    }, [items]);

    const getCategoryDist = (number: number) =>
      categoryDict?.params &&
      Object.values(categoryDict?.params).find((item) => item.number === number);

    const categories = useMemo(() => {
      const charts = chartData;
      const totalAmount = items?.reduce((acc: number, categories) => {
        if (categories.risk && isCheck.includes(categories?.number)) {
          acc += categories.risk;
        }
        return acc;
      }, 0);

      const otherCategories: OtherCategoriesType = {
        exportAmount: 0,
        importAmount: 0,
        exportPercent: 0,
        importPercent: 0,
      };

      const formattedData =
        formattedCategories.reduce((acc: chartDataType, categories) => {
          const findCategory = items?.find((item) => item.number === categories.number);
          const findOtherCircle = charts?.find(
            (item) => categories.number === item.rescat,
          );

          const totalImport = Number(findOtherCircle?.received) || 0;

          const amountPercent: any = findOtherCircle?.percent;
          const diffAmount: number =
            findCategory?.percent_raw && amountPercent
              ? findCategory.percent_raw - amountPercent
              : 0;

          if (findCategory?.percent_raw && findCategory.percent_raw < 1) {
            otherCategories.exportPercent += findCategory.percent_raw;
            otherCategories.exportAmount += parseFloat(findCategory.total_human);
          }

          if (amountPercent < 1) {
            otherCategories.importPercent += parseFloat(amountPercent);
            otherCategories.importAmount += totalImport;
          }

          if (
            acc.find((item) => item.id === findCategory?.number && item.percent === 100)
          ) {
            return acc;
          }

          if (findCategory && !!String(totalAmount)) {
            acc.push({
              id: findCategory.number,
              Import: amountPercent > 1 ? findOtherCircle?.percent : null,
              Export: findCategory.percent > 1 ? String(findCategory?.percent) : null,
              Category: categories?.name,
              percent: findCategory.percent,
              ImportAmount: totalImport,
              ExportAmount: findCategory.total_human,
              color: getCategoryDist(findCategory.number)?.color,
            });
          }

          if (diffAmount > 0 || (!findOtherCircle && isCheck.length > 1)) {
            acc.push({
              id: Number(findCategory?.number),
              Category: String(findCategory?.name),
              Import: diffAmount || findCategory?.percent_raw,
              color: '#fff',
            });
          }

          return acc;
        }, []) || [];

      if (otherCategories.importPercent > 0 || otherCategories.exportPercent > 0) {
        formattedData.push({
          id: 999999,
          Category: 'Empty',
          Import: otherCategories.exportPercent - otherCategories.importPercent,
          color: '#fff',
        });

        formattedData.push({
          id: 999999,
          Import: otherCategories.importPercent ? otherCategories.importPercent : null,
          Export: String(otherCategories.exportPercent),
          Category: 'Other',
          ImportAmount: otherCategories.importAmount,
          ExportAmount: String(otherCategories.exportAmount),
        });
      }
      return formattedData || [];
    }, [
      isCheck,
      items,
      data?.explorerAddressCategories?.categories,
      chartData,
      categoryDict,
    ]);

    const dropDownFilter = useMemo(() => {
      const result: { [key: string]: any } = {
        total: 0,
        total_human: 0,
        risk: 0,
        risk_raw: 0,
        percent: 0,
        percent_raw: 0,
        name: 'Other',
        i18n: {
          en: 'Other',
          ru: 'Other',
        },
      };

      const reduceItems =
        items?.reduce((acc: any[], val) => {
          if (val.percent_raw > 1) {
            acc.push(val);
          }

          if (val.percent_raw > 0 && val.percent_raw < 1) {
            result.total = BigNumber.sum(val.total_human, result.total_human);
            result.risk = BigNumber.sum(result.risk, val.risk);
            result.risk_raw = BigNumber.sum(result.risk_raw, val.risk_raw);
            result.percent = BigNumber.sum(val.percent, result.percent);
            result.percent_raw = BigNumber.sum(val.percent_raw, result.percent_raw);
          }
          return acc;
        }, []) || [];

      if (result.total_human === 0) {
        reduceItems.push({
          id: 999999,
          ...result,
        });
      }

      return reduceItems || [];
    }, [items, categories]);

    const isCheckAll = isCheck.length === dropDownFilter?.length;

    useLayoutEffect(() => {
      refetch();
      setIsCheck(dropDownFilter?.map((item) => item.id || item.number) || []);
    }, [items]);

    useEffect(() => {
      setList(
        categories?.filter((item) => {
          const someFilter = dropDownFilter?.some(
            (filter) => filter.id === item.id || filter.number === item.id,
          );

          return someFilter && isCheck.includes(Number(item.id));
        }) || [],
      );
    }, [isCheck, categories, dropDownFilter]);

    const handleChange = (id: string, checked: boolean) => {
      setIsCheck([...isCheck, Number(id)]);

      if (!checked) setIsCheck(isCheck.filter((check) => check !== Number(id)));
    };

    const handleSelectedAll = () => {
      if (isCheckAll) setIsCheck([]);
      else setIsCheck(dropDownFilter?.map((item) => item.number || item.id) || []);
    };

    const sortItems = useMemo(() => {
      let unmarked: CalculateItem | null | undefined = null;

      let arr =
        items
          ?.map((category: any, i: number, arr: any[]) => {
            if (arr.length === 1) {
              return {
                name: category.name !== '' ? category.name : 'Unmarked',
                ...category,
              };
            }

            if (!unmarked && category.number !== 1 && category.number === 0) {
              unmarked = items?.find((item) => item.number === 0);

              return {
                ...unmarked,
                name: 'Unmarked',
              };
            }

            if (!category) return null;

            return {
              ...category,
              name: category.name,
            };
          })
          .filter((item) => item) || [];

      if (onlyPositive) {
        arr = arr?.filter(
          (item) =>
            Number(item?.risk) > 0.01 ||
            (Number(item?.risk) === 0 && Number(item.percent) > 0.01),
        );
      }

      return arr?.sort((a: any, b: any) => b.total_human - a.total_human);
    }, [items, onlyPositive]);

    const headers = [
      { title: t('links.category'), value: 'name' },
      { title: t('strings.riskscore'), value: 'riskscore' },
      { title: t('strings.percentage'), value: 'percentage' },
    ];

    const onHover = (category: any) => {
      showCategory(category.percent < 1 ? 'Other' : category.categoryName);
    };

    const onMouseLeave = () => {
      showCategory('');
    };

    const renderRiskScore = (risk: number, percent: number): JSX.Element => {
      let riskScore: string | number = 0;

      if (risk === 0 && percent > 0.01) {
        riskScore = 0;
      } else {
        riskScore = risk > 0.01 ? risk : '<0.01';
      }

      return (
        <div className={styles.category__groupRisk}>
          <span>{riskScore}%</span>
        </div>
      );
    };

    const renderCategoryName = (
      name: string,
      color: string,
      number: number,
      risk: number,
    ): JSX.Element => {
      return (
        <div className={styles.category__name}>
          {hideFilter && (
            <Divider
              className={styles.category__divider}
              size={4}
              color={String(color)}
              vertical
            />
          )}
          <RiskDot
            color={name.toLowerCase() === 'unmarked' ? color : ''}
            risk={getCategoryDist(number)?.risk || risk}
          />
          <Typography variant={'body-14'}>{name}</Typography>
        </div>
      );
    };

    const tableItems = useMemo(
      () =>
        sortItems.map((category) => ({
          categoryName: category.name,
          name: renderCategoryName(
            category.name,
            category.color,
            category.number,
            category.risk,
          ),
          percent: category.percent,
          riskscore: renderRiskScore(category.risk, category.percent),
          percentage: `${category.percent > 0.01 ? category.percent : '<0.01'}%`,
        })),
      [sortItems, categoryDict],
    );

    return (
      <div className={styles.category__container}>
        {error ? (
          <div className={styles['category__info-wrapper']}>
            <div className={styles['category__info']}>
              <Informer
                title={''}
                subtitle={t('titles.chartError')}
                color={'#394363'}
                type={'alert'}
                isAlert
              />
            </div>
          </div>
        ) : (
          <>
            {!list.length && !tableItems.length ? (
              <div className={styles['category__info-wrapper']}>
                <div className={styles['category__info']}>
                  <Informer
                    title={''}
                    subtitle={t('titles.noData')}
                    color={'#394363'}
                    type={'empty'}
                  />
                </div>
              </div>
            ) : (
              <>
                {!hideFilter && !hasOnlyCharts && !!risk && risk > 0 && (
                  <div>
                    <Dropdown
                      style={{ zIndex: 10 }}
                      targetSlot={
                        <Badge content={isCheck?.length} hideDot={!isCheck?.length}>
                          <Button
                            variant="outlined"
                            icon
                            size={'small'}
                            prependIcon={() =>
                              FiltersIcon({
                                width: 24,
                                height: 24,
                                ...{ viewBox: '0 0 24 24' },
                              })
                            }
                          ></Button>
                        </Badge>
                      }
                      placement="bottom-start"
                      width={240}
                    >
                      <DropdownList>
                        <DropdownItem
                          active={isCheckAll}
                          onClick={handleSelectedAll}
                          multiple
                          fullWidth
                          size="small"
                        >
                          {t('strings.all')} ({dropDownFilter.length})
                        </DropdownItem>
                        {sortItems.length > 1 && (
                          <DropdownItem
                            active={onlyPositive}
                            onClick={() => showOnlyPositive(!onlyPositive)}
                            multiple
                            fullWidth
                            size="small"
                          >
                            {t('strings.onlyPositive')}
                          </DropdownItem>
                        )}
                        <Spacer size={2} />
                        <Divider />
                        <Spacer size={2} />
                        {dropDownFilter?.map((item) => {
                          const key = item.id || item.number;
                          return (
                            <DropdownItem
                              key={key}
                              active={isCheck.includes(key)}
                              onClick={() =>
                                handleChange(String(key), !isCheck.includes(key))
                              }
                              fullWidth
                              multiple
                              size="small"
                            >
                              <div className={styles.category__groupRisk}>
                                <RiskDot color={item?.color || '#e4e8ee'} />
                                {item.name || 'Unmarked'}
                              </div>
                            </DropdownItem>
                          );
                        })}
                      </DropdownList>
                    </Dropdown>
                  </div>
                )}
                <div className={styles.category__wrapper}>
                  <div className={styles.category__card}>
                    <div ref={forwardRef} className={styles.category__chart}>
                      <PieChart
                        subtitle={' '}
                        data={list}
                        network={network}
                        selectedCategory={category}
                        loading={chartLoading}
                        title={`${t('strings.received')} (${getNetworkEnum(network)})`}
                        getColorsCallback={setColorPie}
                      />
                      <div className={styles.pie__footnote}>
                        {network !== 'btc' && (
                          <Typography variant="body-14" color="on-surface-primary-2">
                            {t(`titles.${network}Info`, 'unknown error')}
                          </Typography>
                        )}
                      </div>
                    </div>
                    {!hasOnlyCharts && (
                      <Table
                        items={tableItems}
                        headers={headers}
                        onMouseEnterRow={(_, category) => onHover(category)}
                        onMouseLeaveRow={() => onMouseLeave()}
                      />
                    )}
                  </div>
                </div>
                {!hasOnlyCharts && <RiskScoreInformation />}
              </>
            )}
          </>
        )}
      </div>
    );
  },
);
