import React, { FC, PropsWithChildren } from 'react';
import { PageNotFound } from '../../../../screen';
import { useExplorerFindBlockByHashQuery } from '../../../../apolloGenerated';
import { LayoutExplorer } from './LayoutExplorer';
import { getNetworkEnum } from '@helpers/address';

type LayoutExplorerBlockTransactionProps = {
  params: {
    hash: string;
    network: string;
  };
};

export const LayoutExplorerBlockTransaction: FC<
  PropsWithChildren<LayoutExplorerBlockTransactionProps>
> = ({ children, params: { network, hash } }) => {
  const { data, loading } = useExplorerFindBlockByHashQuery({
    variables: {
      network: getNetworkEnum(network),
      hash,
    },
    skip: !hash,
  });

  if (!data?.explorerFindBlockByHash?.node && !loading) return <PageNotFound />;

  return <LayoutExplorer params={{ value: hash, network }}>{children}</LayoutExplorer>;
};
