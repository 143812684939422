import { Maybe } from '@rubin-dev/goblin';
import { NodeItem, TableType } from '@graph/types';
import { NodeCoords } from '@apolloGenerated';
import { GraphAnnotation } from '@graph/modules';

export type AnnotationParams = {
  text?: Maybe<string>;
  color?: Maybe<string>;
  coords?: NodeCoords;
};
export type AnnotationType = TableType.Transaction | TableType.Address;
export type WithParentHash<T> = T & { parentHash: string };
export class Annotation {
  public readonly hash: string;
  public readonly parentHash: string;
  public coords?: NodeCoords;
  private color?: Maybe<string>;
  private readonly type: AnnotationType;
  private text?: Maybe<string>;

  constructor(
    hash: string,
    parentHash: string,
    type: AnnotationType,
    params?: AnnotationParams,
  ) {
    this.hash = hash;
    this.parentHash = parentHash;
    this.type = type;
    this.text = params?.text;
    this.color = params?.color;
    this.coords = params?.coords;
  }

  public updateText(text: string) {
    if (!text) this.text = null;
    else this.text = text;
  }

  public clearText() {
    this.text = null;
  }

  public getText() {
    return this.text;
  }

  public getColor() {
    return this.color;
  }

  public getNode(): Maybe<WithParentHash<NodeItem>> {
    if (!this.text) return null;
    const realNodeSize = 1;
    return {
      uuid: this.hash,
      label: {
        dragged: true,
        location: 'top',
        render: (
          <GraphAnnotation
            style={{ transform: `translateY(${realNodeSize * 5}px)`, cursor: 'pointer' }}
            hash={this.hash}
            parentHash={this.parentHash}
            text={this.text}
            type={this.type}
            color={this.getColor() || undefined}
          />
        ),
      },
      size: realNodeSize,
      area: [
        {
          size: realNodeSize,
          fill: 'transparent',
          stroke: '',
          strokeWidth: 0,
        },
      ],
      type: TableType.Annotation,
      fx: this.coords?.x,
      fy: this.coords?.y,
      parentHash: this.parentHash,
    };
  }

  setCoords(x: number, y: number): void {
    this.coords = {
      x,
      y,
    };
  }

  setColor(color: string): void {
    this.color = color;
  }
}
