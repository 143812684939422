import React, { FC, FormEvent, useEffect, useContext, KeyboardEvent } from 'react';
import { useCurrentRoute } from 'react-justanother-router';
import { TextField, SearchIcon, Calendar } from '@rubin-dev/goblin';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { history, router, RouterName } from '../../router';
import { DashboardFilters } from './TransactionsScreen';
import { AppContext } from '../../contexts';
import styles from './transactions.module.scss';

interface Props {
  date?: DateTime[];
  hash?: string;
  onChangeDate?: (date: DateTime[]) => void;
  onChangeHash?: (val: string) => void;

  onConfirm(): void;
}

export const HeaderControls: FC<Props> = ({
  date,
  hash,
  onChangeDate,
  onChangeHash,
  onConfirm,
}) => {
  const {
    currentRoute: { params },
  } = useCurrentRoute();
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onConfirm();
  };

  const updateRouteByFilters = (date: DateTime[]) => {
    const query: DashboardFilters = {};
    if (hash) query.hash = hash;

    if (date?.length === 2) {
      query.dateFrom = date[0].toISODate();
      query.dateTo = date[1].toISODate();
    }

    if (Object.keys(params).length) {
      history.navigate(router.urlFor(RouterName.TransactionsType, { ...params }, query));
    }
  };

  useEffect(() => {
    if (!date) return;

    updateRouteByFilters(date);
  }, [date]);

  const onChangeDateHandler = (date: DateTime[]) => {
    if (!onChangeDate) return;

    if (date.length === 1) {
      onChangeDate([...date, ...date]);
      return;
    }

    onChangeDate(date);
  };

  const onKeyHandler = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return;

    onConfirm();
  };

  return (
    <form className={styles.form__controls} onSubmit={handleSubmit}>
      <Calendar
        key={date?.length}
        locale={language}
        onChange={onChangeDateHandler}
        selectedRange={date}
        selectedDate={date?.length ? date[0] : undefined}
        confirmButton
      />
      <TextField
        name="search"
        value={hash}
        prependSlot={<SearchIcon className="cursor-pointer" onClick={onConfirm} />}
        placeholder={t('titles.insertHash')}
        onChange={(e) => onChangeHash && onChangeHash(e.target.value)}
        onKeyUp={onKeyHandler}
        onClear={() => {
          onChangeHash && onChangeHash('');
          onConfirm();
        }}
        clearable
      />
    </form>
  );
};
