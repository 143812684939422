import { FC } from 'react';
import styles from './styles.module.scss';
import { Button, CheckIcon, Divider, Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';

interface CardProps {
  title: string;
  subtitle?: string;
  hasLabel?: boolean;
  mainAmountInYear: string;
  conditions: string[];
  hideText?: boolean;
  showSelect?: boolean;
}

export const CardTariff: FC<CardProps> = ({
  title,
  hasLabel,
  subtitle,
  mainAmountInYear,
  conditions,
  hideText = false,
  showSelect = true,
}) => {
  const { t } = useTranslation();
  const colorCheck = 'rgba(64, 103, 202, 1)';

  const openMessanger = () => {
    window.open('https://t.me/matchsystems_info', '_blank', 'noopener,noreferrer');
  };
  return (
    <div className={styles['tariffs__card']}>
      {hasLabel && (
        <div className={styles['tariffs__card-label']}>
          <Typography variant={'head-14'} color={'surface-1'}>
            {t('titles.bestChoice')}
          </Typography>
        </div>
      )}
      <div className={styles['tariffs__card-titles']}>
        <Typography variant={'head-24'} color={'on-surface-primary-1'}>
          {title}
        </Typography>
        <div className={styles['tariffs__card-group']}>
          {hideText ? (
            <Typography variant={'head-20'} color={'primary-1'}>
              {mainAmountInYear}
            </Typography>
          ) : (
            <>
              <Typography variant={'head-20'} color={'primary-1'}>
                {mainAmountInYear} USDT
              </Typography>
              <Typography variant={'head-16'} color={'on-secondary-2'}>
                <span>/ {t('titles.year')}</span>
              </Typography>
            </>
          )}
        </div>
        {subtitle && (
          <Typography variant={'body-16'} color={'on-surface-primary-1'}>
            {subtitle}
          </Typography>
        )}
      </div>
      <Divider />
      <div className={styles['tariffs__card-description']}>
        {conditions &&
          conditions.map((item) => {
            return (
              <div key={item} className={styles['tariffs__card-group']}>
                <div className={styles['tariffs__icon-wrapper']}>
                  <CheckIcon width={18} height={18} color={colorCheck} />
                </div>
                <Typography variant={'body-16'} color={'on-surface-primary-1'}>
                  {item}
                </Typography>
              </div>
            );
          })}
      </div>
      {showSelect && (
        <>
          <Divider />
          <Button fullWidth onClick={openMessanger}>
            {t('buttons.select')}
          </Button>
        </>
      )}
    </div>
  );
};
