import { Network as NetworkType, ZoomParamsType } from '../../../../apolloGenerated';
import { GraphHashes } from '../../types/graph';

export class Meta {
  static uuid: string;
  static network: NetworkType;
  static graphName: string;
  static canvas: ZoomParamsType = { originY: 0, originX: 0, zoom: 0 };
  private static _current: GraphHashes;
  static get current() {
    return this._current;
  }
  static set current(val: GraphHashes) {
    this._current = val;
  }
}
