import { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogFooter,
  TextField,
  WithCloseModal,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { useGraphStatusStore } from '../../libs/store';
import { useGraphRenameMutation } from '@apolloGenerated';

export type GraphRenameModalProps = {
  uuid: string;
  initName: string;
  onSuccess?(name: string): void;
};
declare global {
  interface ModalProps {
    renameGraph: GraphRenameModalProps;
  }
}
export const GraphRenameModal: FC<WithCloseModal<GraphRenameModalProps>> = ({
  initName,
  uuid,
  onSuccess,
  onClose,
}) => {
  const { setSaving, isSaving } = useGraphStatusStore();
  const { t } = useTranslation();
  const [name, setName] = useState(initName);
  const [renameMutation, { data }] = useGraphRenameMutation();
  const errorMessage = data?.graphMetaNameUpdate?.errors?.message;
  const isDisableButton = !name || isSaving || initName === name;

  const handleSubmit = async () => {
    setSaving(true);
    const { data } = await renameMutation({ variables: { uuid, name } });
    if (!data?.graphMetaNameUpdate?.errors) {
      onSuccess && onSuccess(name);
      onClose();
    }
    setSaving(false);
  };

  return (
    <Dialog
      width={500}
      title={t('titles.graphName')}
      footerSlot={
        <DialogFooter
          position="end"
          startSlot={
            <Button size="large" color="secondary" fullWidth onClick={onClose}>
              {t('buttons.close')}
            </Button>
          }
          endSlot={
            <Button
              onClick={handleSubmit}
              size="large"
              fullWidth
              disabled={isDisableButton}
            >
              {t('buttons.save')}
            </Button>
          }
        />
      }
    >
      <TextField
        name="name"
        label={t('titles.editName')}
        initValue={name}
        onChange={(e) => setName(e.target.value)}
        size="large"
        fullWidth
        error={
          errorMessage ? t(`errors.graph.${errorMessage}`, t('errors.unknownError')) : ''
        }
      />
    </Dialog>
  );
};
