import { isValidEthAddress } from './eth';
import { isValidTronAddress } from './tron';
import { isValidBtcAddress } from './btc';

export const detectNetworkByAddress = (v: string): string | undefined => {
  if (isValidEthAddress(v)) {
    return 'eth';
  }

  if (isValidTronAddress(v)) {
    return 'tron';
  }

  if (isValidBtcAddress(v)) {
    return 'btc';
  }
};
