import { FC } from 'react';
import { ArrowLeftDenseIcon, Button } from '@rubin-dev/goblin';
import { useGoBack } from '@hooks/useGoBack';

export type GraphBackButtonProps = {};
export const GraphBackButton: FC<GraphBackButtonProps> = () => {
  const goBack = useGoBack();
  return (
    <Button
      icon
      prependIcon={ArrowLeftDenseIcon}
      variant="outlined"
      onClick={() => goBack()}
    />
  );
};
