import React, { FC, PropsWithChildren, useMemo } from 'react';
import { PageNotFound } from '../../../../screen';
import { getNetworkEnum } from '@helpers/address';
import { useExplorerClusterQuery, useMeQuery } from '../../../../apolloGenerated';
import { LayoutExplorer } from './LayoutExplorer';

type LayoutExplorerWalletProps = {
  params: {
    wid: string;
    network: string;
  };
};

export const LayoutExplorerWallet: FC<PropsWithChildren<LayoutExplorerWalletProps>> = ({
  children,
  params: { network, wid },
}) => {
  const { data: user } = useMeQuery({ fetchPolicy: 'network-only' });
  const { loading, data } = useExplorerClusterQuery({
    variables: { network: getNetworkEnum(network), id: wid },
    skip: !wid,
  });

  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );

  if ((!data?.explorerCluster?.cluster || !expireDate) && !loading) {
    return <PageNotFound />;
  }

  return <LayoutExplorer params={{ network, value: wid }}>{children}</LayoutExplorer>;
};
