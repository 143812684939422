import { ToggleItem } from '@rubin-dev/goblin';
import { AddressTransactionsFilterTxType } from '@apolloGenerated';
import i18n from '../../../../../../i18n/i18n';

export const TABLE_TRANSACTION_TABS =
  (): ToggleItem<AddressTransactionsFilterTxType>[] => [
    { value: AddressTransactionsFilterTxType.All, label: i18n.t('strings.all') },
    {
      value: AddressTransactionsFilterTxType.Receives,
      label: i18n.t('strings.received'),
    },
    { value: AddressTransactionsFilterTxType.Sent, label: i18n.t('strings.sent') },
  ];
