import { useModal } from '@hooks/useModal';
import { LinkList, NodeList } from '@graph/models';
import { EventName, useGraphStore } from '@graph/libs';
import { Annotation } from '@graph/models/Annotation/Annotation';

export const useGraphAnnotation = () => {
  const { sendEvent } = useGraphStore();
  const openAnnotationModal = useModal('annotationGraph');
  const handleAddLineAnnotation = (parentHash: string) => {
    const link = LinkList.hashList[parentHash];
    openAnnotationModal({
      text: link.annotation.getText(),
      onSubmit(value) {
        updateAnnotation(link.annotation, value);
      },
    });
  };
  const handleAddNodeAnnotation = (parentHash: string, hash?: string) => {
    const node = NodeList.hashList[parentHash];
    const maybeAnnotation = hash ? node?.annotation[hash] : null;
    openAnnotationModal({
      text: maybeAnnotation?.getText() || '',
      onSubmit(value) {
        const annotation = maybeAnnotation || node.createAnnotation();
        updateAnnotation(annotation, value);
      },
    });
  };
  const handleRemoveLineAnnotation = (hash: string, parentHash: string) => {
    const link = LinkList.hashList[parentHash];
    removeAnnotation(link.annotation);
  };
  const handleRemoveNodeAnnotation = (hash: string, parentHash: string) => {
    const annotation = NodeList.hashList[parentHash].annotation[hash];
    removeAnnotation(annotation);
  };
  const handleUpdateColorLineAnnotation = (
    hash: string,
    parentHash: string,
    color: string,
  ) => {
    const link = LinkList.hashList[parentHash];
    changeColorAnnotation(link.annotation, color);
  };
  const handleUpdateColorNodeAnnotation = (
    hash: string,
    parentHash: string,
    color: string,
  ) => {
    const annotation = NodeList.hashList[parentHash].annotation[hash];
    changeColorAnnotation(annotation, color);
  };

  const updateAnnotation = (annotation: Annotation, text: string) => {
    annotation.updateText(text);
    sendEvent({
      type: EventName.DATA_UPDATE,
    });
  };

  const removeAnnotation = (annotation: Annotation) => {
    annotation.clearText();
    sendEvent({
      type: EventName.DATA_UPDATE,
    });
  };

  const changeColorAnnotation = (annotation: Annotation, color: string) => {
    annotation.setColor(color);
    sendEvent({
      type: EventName.DATA_UPDATE,
    });
  };

  return {
    onAddLineAnnotation: handleAddLineAnnotation,
    onAddNodeAnnotation: handleAddNodeAnnotation,
    onRemoveNodeAnnotation: handleRemoveNodeAnnotation,
    onRemoveLineAnnotation: handleRemoveLineAnnotation,
    onUpdateColorLineAnnotation: handleUpdateColorLineAnnotation,
    onUpdateColorNodeAnnotation: handleUpdateColorNodeAnnotation,
  };
};
