import React, { FC, useContext, useMemo } from 'react';
import { CheckIcon, Dropdown, DropdownItem, DropdownList } from '@rubin-dev/goblin';
import { LangType, useLangListConst } from '@shared/libs';
import { AppContext } from '../../../contexts';
import styles from './styles.module.scss';

export const LangSelect: FC = () => {
  const { setLang, language } = useContext(AppContext);
  const langDict = useLangListConst();
  const langList = Object.entries(langDict);
  const hasLangSelect = langList.length > 1;
  const CurrentLanguage = useMemo(() => langDict[language]?.iconComponent, [language]);

  if (!hasLangSelect) return null;
  return (
    <Dropdown
      targetSlot={
        <div className={styles.langSelect}>{CurrentLanguage && <CurrentLanguage />}</div>
      }
      placement="right-end"
      zIndex={1000}
    >
      <DropdownList width={200}>
        {langList.map(([key, { label, iconComponent }]) => (
          <DropdownItem
            key={key}
            fullWidth
            icon={iconComponent}
            onClick={() => setLang(key as LangType)}
            label={label}
            appendIcon={(props) =>
              key === language ? CheckIcon({ color: 'var(--primary-1)', ...props }) : null
            }
          />
        ))}
      </DropdownList>
    </Dropdown>
  );
};
