import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@rubin-dev/goblin';
import { ProfileProductWrapper } from '../ProfileProductWrapper/ProfileProductWrapper';
import styles from './styles.module.scss';
import { formatNumber } from '@aml/amount';
import { IconMarkup } from '@shared/ui';

export type ProfileMarkupProps = {
  count: number;
};

export const ProfileMarkup: FC<ProfileMarkupProps> = ({ count }) => {
  const { t } = useTranslation();

  return (
    <ProfileProductWrapper title={t('titles.markup')} iconPath={<IconMarkup />}>
      <div className={styles.profileMarkup}>
        <div className={styles.profileMarkup__content}>
          <Typography variant="body-16" color="on-secondary-2">
            {t('strings.checks')}:
          </Typography>
          <Typography variant="head-16" color="on-surface-primary-1">
            {formatNumber(count)}
          </Typography>
        </div>
        {/*<Button className={styles.profileMarkup__button} color="secondary" fullWidth>*/}
        {/*  {t('buttons.topUp')}*/}
        {/*</Button>*/}
      </div>
    </ProfileProductWrapper>
  );
};
