import { FC } from 'react';
import { Network, useExplorerClusterQuery } from '@apolloGenerated';
import { getNetworkEnum } from '@helpers/address';
import { TransactionInfo } from '@graph-sidebar-module/shared';

export type ClusterTransactionInfoProps = {
  wid: string;
  network: Network;
};
export const ClusterTransactionInfo: FC<ClusterTransactionInfoProps> = ({
  wid,
  network,
}) => {
  const { data: walletData, loading } = useExplorerClusterQuery({
    variables: { network: getNetworkEnum(network), id: wid },
  });
  const stats = walletData?.explorerClusterStats?.stats;

  const countSent = Number(stats?.sentCount || 0);
  const countReceived = Number(stats?.receivedCount || 0);
  const totalIn = Number(stats?.receivedAmount || 0);
  const totalOut = Number(stats?.sentAmount || 0);

  return (
    <TransactionInfo
      address={wid}
      network={network}
      loading={loading}
      totalIn={totalIn}
      totalOut={totalOut}
      receivedCount={countReceived}
      sentCount={countSent}
    />
  );
};
