import { bem } from 'classnames-bem';
import React, { FC } from 'react';
import cx from 'classnames';
import i18n from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { StatusField, Transaction, TransactionRisk } from '@aml/sdk/dashboard';
import styles from './transactions.module.scss';
import {
  ArrowBottomIcon,
  Button,
  CheckIcon,
  LockIcon,
  Tooltip,
  Typography,
  InfoIcon,
} from '@rubin-dev/goblin';
import { BigNumber } from 'bignumber.js';

export const POLLING_INTERVAL = 15_000;

export const headers = () => [
  { title: '', value: 'extendControl', width: 40 },
  { title: i18n.t('titles.date'), value: 'date', sortable: true, width: 120 },
  { title: i18n.t('titles.hash'), value: 'hash', width: 130 },
  { title: i18n.t('titles.blockchain'), value: 'token', width: 90 },
  { title: i18n.t('links.category'), value: 'category' },
  {
    title: `${i18n.t('titles.receivingLimit')} %`,
    value: 'receivingLimit',
  },
  { title: i18n.t('titles.status'), value: 'status' },
  { title: '', value: 'actions', width: 121 },
  { title: '', value: 'extendsRender', width: 0, className: styles.extends },
];

export const renderStatusDetails = (): JSX.Element => (
  <div className={'b-app__wrapper-grid'}>
    <span>{i18n.t('titles.statusDetails')}</span>
    <Tooltip
      placement="bottom-end"
      label={
        <div className={styles.tooltip}>
          <Typography variant={'body-16'}>{i18n.t('titles.statusClean')}</Typography>
          <div>
            <div className={styles.tooltip__group}>
              <Typography variant={'head-16'}>{i18n.t('titles.system')}</Typography>
              <span>-</span>
              <Typography variant={'body-16'}>
                {i18n.t('titles.automatically')}
              </Typography>
            </div>
            <div className={styles.tooltip__group}>
              <Typography variant={'head-16'}>{i18n.t('titles.aml')}</Typography>
              <span>-</span>
              <Typography variant={'body-16'}>{i18n.t('titles.manually')}</Typography>
            </div>
          </div>
        </div>
      }
    >
      <InfoIcon id="address-transaction" className="b-graph-window__card-icon" />
    </Tooltip>
  </div>
);

export const renderLimit = (risk: TransactionRisk[]) => (
  <div className={styles['transactions__group-cell']}>
    {risk
      ? risk.map((item, i) => (
          <span key={item.category + i}>
            {item.risk} /{' '}
            <span className={bem('b-text', item.risk > item.limit ? 'error' : '')}>
              {item.limit}
            </span>
          </span>
        ))
      : '-'}
  </div>
);

const ExtendsRenderComponent: FC<Transaction> = ({
  receivingAddresses,
  sendingAddresses,
}) => {
  const receivingAmount = receivingAddresses?.data?.reduce((acc, val) => {
    acc = Number(BigNumber.sum(val.amount, acc).toString(10));
    return acc;
  }, 0);

  const sendingAmount = sendingAddresses?.data?.reduce((acc, val) => {
    acc = Number(BigNumber.sum(val.amount, acc).toString(10));
    return acc;
  }, 0);

  const { t } = useTranslation();

  return (
    <>
      <div className={styles.extends__overlay} />
      <div className={styles.extends__content}>
        <div className={styles.extends__group}>
          <Typography variant="body-14" color="on-secondary-2">
            {t('strings.sendingAmount')}:
          </Typography>
          <Typography variant={'body-14'}>{String(sendingAmount)}</Typography>
        </div>
        <div className={styles.extends__group}>
          <Typography variant="body-14" color="on-secondary-2">
            {t('strings.receivingAmount')}:
          </Typography>
          <Typography variant={'body-14'}>{String(receivingAmount)}</Typography>
        </div>
      </div>
    </>
  );
};
export const extendsRender = (item: any) => {
  return <ExtendsRenderComponent {...item} />;
};
export const extendControlRender = (active: boolean, onClick: () => void) => {
  return (
    <div className={cx(styles.extendControl, active && styles.active)} onClick={onClick}>
      <ArrowBottomIcon />
    </div>
  );
};

const translateStatus = (status: string) => {
  return i18n.t(`titles.${status}`, '');
};

export const renderStatus = (status: string) => {
  const colors = {
    BLOCKED: '#B8433D',
    Accepted: '#09A57F',
    CLEAN: '#09A57F',
    OVER_LIMIT: '#808080',
    UNDEFINED: '#808080',
  } as { [key: string]: string };

  return <span style={{ color: colors[status] }}>{translateStatus(status)}</span>;
};

const ActionRenderComponent: FC<{
  callback?(id?: number, type?: StatusField): void;
  id?: number;
}> = ({ callback, id }) => {
  const { t } = useTranslation();
  return (
    <div className={'b-app__wrapper'} style={{ gap: '8px' }}>
      <div>
        <Tooltip
          placement="bottom-end"
          label={<Typography variant={'body-14'}>{t('titles.CLEAN')}</Typography>}
        >
          <Button
            variant={'outlined'}
            onClick={() => callback && callback(id, 'CLEAN')}
            icon
            size={'small'}
            prependIcon={CheckIcon}
          />
        </Tooltip>
      </div>
      <Tooltip
        placement="bottom-end"
        label={<Typography variant={'body-14'}>{t('titles.block')}</Typography>}
      >
        <Button
          variant={'outlined'}
          onClick={() => callback && callback(id, 'BLOCKED')}
          size={'small'}
          prependIcon={LockIcon}
          icon
        />
      </Tooltip>
    </div>
  );
};
export const renderAction = (
  callback: (id: number, type: StatusField) => void,
  id: number,
) => <ActionRenderComponent callback={callback} id={id} />;

export const renderCategory = (risk: TransactionRisk[]) => (
  <div className={styles['transactions__group-cell']}>
    {risk
      ? risk.map((item, i) => (
          <div key={item.category + i} className={styles['transactions__category']}>
            <i
              className={styles['transactions__marker']}
              style={{ backgroundColor: item?.color }}
            ></i>
            {item.category}
          </div>
        ))
      : '-'}
  </div>
);
