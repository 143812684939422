import { FC } from 'react';
import { NodeItem } from '@graph/types';
import { useMenuNodeEvent } from '../../libs/hooks/useMenuNodeEvent';
import { DropdownItem, InteractionIcon, TrashDenseIcon } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import styles from '@graph-visualization-module/entities/View/ui/Annotation/styles.module.scss';

export type NodeMenuProps = {
  node: NodeItem;
  onSubmit(): void;
};
export const NodeMenu: FC<NodeMenuProps> = ({ node, onSubmit }) => {
  const { t } = useTranslation();
  const { onRemove, onAddAnnotation } = useMenuNodeEvent(node, onSubmit);

  return (
    <div className={styles.annotation__dropdown}>
      <DropdownItem
        label={t('strings.add')}
        fullWidth
        icon={InteractionIcon}
        onClick={onAddAnnotation}
        className={styles.annotation__item}
      />
      <DropdownItem
        label={t('buttons.delete')}
        fullWidth
        icon={TrashDenseIcon}
        onClick={onRemove}
        className={styles.annotation__item}
      />
    </div>
  );
};
