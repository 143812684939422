import React, { FC, useMemo } from 'react';
import { getNetworkEnum } from '@helpers/address';
import { useExplorerAddressTransactionStatsQuery } from '@apolloGenerated';
import { TransactionsCard } from '@graph-sidebar-module/shared';

type AddressTransactionsCardProps = {
  address: string;
  network: string;
};
export const AddressTransactionsCard: FC<AddressTransactionsCardProps> = ({
  address,
  network,
}) => {
  const { data, loading } = useExplorerAddressTransactionStatsQuery({
    variables: { network: getNetworkEnum(network || ''), address: address },
  });
  const stats = useMemo(() => data?.explorerAddressTransactionStats?.stats, [data]);

  const sent = Number(stats?.sent || 0);
  const received = Number(stats?.received || 0);
  const total = Number(stats?.total || 0);

  return (
    <TransactionsCard total={total} sent={sent} received={received} loading={loading} />
  );
};
