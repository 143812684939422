import React, { FC } from 'react';
import { getNetworkEnum } from '@helpers/address';
import { useExplorerClusterQuery } from '@apolloGenerated';
import { TransactionsCard } from '@graph-sidebar-module/shared';

type ClusterTransactionsCardProps = {
  wid: string;
  network: string;
};
export const ClusterTransactionsCard: FC<ClusterTransactionsCardProps> = ({
  wid,
  network,
}) => {
  const { data: walletData, loading } = useExplorerClusterQuery({
    variables: { network: getNetworkEnum(network), id: wid },
  });
  const stats = walletData?.explorerClusterStats?.stats;

  const total = Number(stats?.totalCount || 0);
  const totalSent = Number(stats?.sentCount || 0);
  const totalReceived = Number(stats?.receivedCount || 0);

  return (
    <TransactionsCard
      total={total}
      sent={totalSent}
      received={totalReceived}
      loading={loading}
    />
  );
};
