import { FC, JSX } from 'react';
import { Typography, Divider } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { ItemContact } from './itemContact';
import { TgIcon } from '@shared/ui/icons/TgIcon';
import { EmailIcon } from '@shared/ui/icons/EmailIcon';
import styles from './styles.module.scss';

export const TechnicalWorks: FC = () => {
  const { t } = useTranslation();

  const renderTelegram = (): JSX.Element => {
    return (
      <Typography variant={'body-14'} tag={'div'} color={'primary-1'}>
        <a
          href={'https://t.me/matchsystems_info'}
          target={'_blank'}
          rel={'noopener, noreferrer'}
        >
          @matchsystems_info
        </a>
      </Typography>
    );
  };
  const renderEmail = (): JSX.Element => {
    return (
      <Typography variant={'body-14'} tag={'div'} color={'primary-1'}>
        <a href={'mailto:support@matchsystems.com'}>support@matchsystems.com</a>
      </Typography>
    );
  };
  return (
    <section className={styles.section}>
      <div className={styles.section__main}>
        <Typography variant={'head-32'}>{t('titles.technicalWorks')}</Typography>
        <Typography variant={'head-20'} color={'on-surface-primary-2'}>
          {t('titles.estimated')}
        </Typography>
      </div>
      <footer className={styles.section__footer}>
        <ItemContact title={t('titles.telegram')} link={renderTelegram()}>
          <TgIcon />
        </ItemContact>
        <Divider vertical />
        <ItemContact title={t('titles.email')} link={renderEmail()}>
          <EmailIcon />
        </ItemContact>
      </footer>
    </section>
  );
};
