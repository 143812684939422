import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const RightIcon: FC<IconProps> = ({ width = 24, height = 24, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...rest}
    >
      <rect
        x="0.399902"
        width="24"
        height="24"
        rx="4"
        fill="url(#paint0_linear_4005_1250)"
      />
      <path
        d="M15.7335 4.66602L18.4001 7.33268M18.4001 7.33268L15.7335 9.99935M18.4001 7.33268H8.40015C7.29558 7.33268 6.40015 8.22811 6.40015 9.33268V10.666"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06681 19.334L6.40015 16.6673M6.40015 16.6673L9.06681 14.0007M6.40015 16.6673H16.4001C17.5047 16.6673 18.4001 15.7719 18.4001 14.6673V13.334"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4005_1250"
          x1="12.3999"
          y1="24"
          x2="11.7999"
          y2="1.46337e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.481579" stopColor="#13D879" />
          <stop offset="1" stopColor="#A4E5CA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
