import { initialize as initializeRisk } from './risk';
import { initialize as initDashboard } from './dashboard';
import { createAxiosSdkInstance } from './api';
import { SDK } from './types';

const sdk: SDK = {
  risk: {
    BTC: initializeRisk(createAxiosSdkInstance(import.meta.env.VITE_RISK_BTC_API_URL)),
    ETH: initializeRisk(createAxiosSdkInstance(import.meta.env.VITE_RISK_ETH_API_URL)),
    TRON: initializeRisk(createAxiosSdkInstance(import.meta.env.VITE_RISK_TRON_API_URL)),
  },
  dashboard: initDashboard(
    createAxiosSdkInstance(import.meta.env.VITE_DASHBOARD_API_URL, { injectAuth: true }),
  ),
};
export default sdk;
