import React, { createRef, FC, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormGenericData,
  Loader,
  SearchIcon,
  Select,
  TextField,
  useForm,
} from '@rubin-dev/goblin';
import styles from './styles.module.scss';
import { ExplorerSearchExample } from '@component/explorer/ui/ExplorerSearchExample/ExplorerSearchExample';
import { Network } from '@apolloGenerated';

export type ExplorerFormContainerProps = FormGenericData<ExplorerFormInput> &
  CurrenciesProp & {
    address?: string;
  };

export type ExplorerFormInput = {
  query: string;
  network: string;
  address?: string;
};
export type CurrenciesProp = {
  currencies: string[];
};

const defaultValues: ExplorerFormInput = {
  query: '',
  network: 'BTC',
};

const EXAMPLE_ADDRESSES: Record<Network, string> = {
  [Network.Btc]: '3F2sZ4jbhvDKQdGbHYPC6ZxFXEau2m5Lqj',
  [Network.Tron]: 'TW6VYLMCAxfkArWF5PQpKYqhVKYztwCf4X',
  [Network.Eth]: '0x11069987e8507d0669c870b578cc9f9b4017d127',
};

export const ExplorerSearchForm: FC<ExplorerFormContainerProps> = ({
  currencies,
  loading,
  formOnSubmit,
  initialValues,
  address,
}): JSX.Element => {
  const { t } = useTranslation();

  const ref = createRef<HTMLFormElement>();
  const initialState = {
    ...defaultValues,
    ...initialValues,
  };

  const {
    onChange,
    onCmdEnterSubmit,
    onSubmit,
    values,
    setBatchValues,
    setFormErrors,
    reset,
    onSubmitTrigger,
  } = useForm<ExplorerFormInput>(formOnSubmit, initialState);

  const callback = useCallback((query: string, network: string) => {
    const input = { query, network };
    setBatchValues(input);
    formOnSubmit(input, setFormErrors, setBatchValues, reset);
  }, []);

  useLayoutEffect(() => {
    if (address) {
      initialState.query = address;
    }
  });

  return (
    <div className={styles.explorer}>
      <form
        ref={ref}
        className={styles.explorer__form}
        onSubmit={onSubmit}
        onKeyDown={onCmdEnterSubmit}
      >
        <div>
          <Select
            initValue={values.network}
            items={currencies}
            onChangeNative={onChange}
            renderValue={(val) => `${val}`}
            name="network"
            fullWidth
            placeholder={t('titles.blockchain')}
            hideOnSelect
          />
        </div>
        <div>
          <TextField
            id="query"
            name="query"
            value={values.query.trim() || ''}
            placeholder={t('strings.insertaddress')}
            fullWidth
            onChange={onChange}
            onClear={() => setBatchValues({ query: '' })}
            autoFocus={true}
            clearable={!!values.query}
            prependSlot={
              loading ? (
                <Loader />
              ) : (
                <SearchIcon className="cursor-pointer" onClick={onSubmitTrigger} />
              )
            }
            appendSlot={
              !values.query ? (
                <>
                  {values.network === 'BTC' && (
                    <ExplorerSearchExample
                      hash={EXAMPLE_ADDRESSES[Network.Btc]}
                      onClick={() => callback(EXAMPLE_ADDRESSES[Network.Btc], 'BTC')}
                    />
                  )}
                  {values.network === 'ETH' && (
                    <ExplorerSearchExample
                      hash={EXAMPLE_ADDRESSES[Network.Eth]}
                      onClick={() => callback(EXAMPLE_ADDRESSES[Network.Eth], 'ETH')}
                    />
                  )}
                  {values.network === 'TRON' && (
                    <ExplorerSearchExample
                      hash={EXAMPLE_ADDRESSES[Network.Tron]}
                      onClick={() => callback(EXAMPLE_ADDRESSES[Network.Tron], 'TRON')}
                    />
                  )}
                </>
              ) : (
                <></>
              )
            }
          />
        </div>
      </form>
    </div>
  );
};
