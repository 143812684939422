import * as gqlTypes from '../../apolloGenerated';
import { Cluster } from '../../apolloGenerated';
import { getSplicedString } from '@utils/string';
import { Network } from '../../apolloGenerated';

export const getShortAddress = (
  value: string,
  beforeSymbols = 4,
  afterSymbols = 4,
): string => `${value.slice(0, beforeSymbols)}...${value.slice(-afterSymbols)}`;

export const getNetworkEnum = (network: string): gqlTypes.Network => {
  return network.toUpperCase() as gqlTypes.Network;
};

export const getRiskColor = (riskValue: number): string => {
  if (riskValue <= 25) return 'var(--success-2)';
  if (riskValue >= 75) return 'var(--error-2)';
  return 'var(--orange-1)';
};

export const getClusterLabel = (cluster: Cluster) => {
  if (cluster?.owner)
    return cluster.owner.length > 14
      ? `${cluster.owner?.slice(0, 10)}...`
      : cluster.owner || '';
  return getSplicedString(cluster.id);
};

const networkLowerCase: Network[] = [Network.Eth];
export const getAddressWithCorrectCase = (address: string, network: Network): string => {
  if (networkLowerCase.includes(network)) return address.toLowerCase();
  return address;
};
