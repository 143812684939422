import React, { FC, useEffect, useState } from 'react';
import * as gqlTypes from '../../apolloGenerated';
import { AppError } from '../../component';
import { Redirect } from 'react-justanother-router';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import { permissionsList } from './constants';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  Loader,
  Spacer,
  TextField,
} from '@rubin-dev/goblin';
import { Container } from '@shared/ui';

export interface IProps {}

export const UserCreateScreen: FC<IProps> = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [permissions, setPermissions] = useState<string[]>([]);

  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);

  const [mutate, { error, loading, data }] = gqlTypes.useCreateMutation({});

  useEffect(() => {
    setPermissions(isCheck);
  }, [isCheck, isCheckAll]);

  const onSubmit = async () => {
    await mutate({
      variables: {
        input: {
          email,
          name,
          permissions,
        },
      },
    });
  };

  const handleSelectedAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(permissionsList);

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (id: string, checked: boolean) => {
    setIsCheck((prevState) => [...prevState, id]);

    if (!checked) {
      setIsCheck((prevState) => prevState.filter((check) => check !== id));
    }
  };

  if (data && !data.create.errors) {
    return <Redirect to={RouterName.UserList} />;
  }

  return (
    <Container>
      <Spacer size={8} />
      {loading ? (
        <Loader />
      ) : (
        <form
          className="b-event-create-form"
          onSubmit={onSubmit}
          style={{ maxWidth: '50%' }}
        >
          <TextField
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={t('titles.name')}
            size="large"
            fullWidth
            error={data?.create.errors.name}
          />
          <Spacer size={12} />
          <TextField
            name="email"
            value={email.toLowerCase()}
            onChange={(e) => setEmail(e.target.value)}
            label={t('titles.email')}
            size="large"
            fullWidth
            error={data?.create.errors.email}
          />
          <Spacer size={12} />
          <Dropdown
            targetSlot={
              <Button variant="outlined" type="button">
                {t('titles.permissions')}
                {!!isCheck?.length && <span className="b-badge">{isCheck?.length}</span>}
              </Button>
            }
            placement="bottom-start"
            width={300}
          >
            <DropdownList>
              <DropdownItem
                active={isCheckAll}
                onClick={handleSelectedAll}
                multiple
                fullWidth
              >
                {t('strings.all')} ({permissionsList.length})
              </DropdownItem>
              {permissionsList?.map((item) => (
                <DropdownItem
                  key={item}
                  active={isCheck.includes(item)}
                  onClick={() => handleClick(item, !isCheck.includes(item))}
                  multiple
                  fullWidth
                >
                  {item}
                </DropdownItem>
              ))}
            </DropdownList>
          </Dropdown>
          <Spacer size={8} />
          {error && <AppError error={t('errors.reloading')} />}
          <Button type="submit" disabled={!isCheck.length || email === '' || name === ''}>
            {t('titles.createUser')}
          </Button>
        </form>
      )}
    </Container>
  );
};
