import { FC, useEffect, useState } from 'react';
import { TableType } from '@graph/types';
import { Transaction, useSidebarData } from '@graph-sidebar-module/shared';
import { TransactionDetailWindow } from '../TransactionDetailWindow/TransactionDetailWindow';
import { AddressWindow } from '../AddressWindow/AddressWindow';
import { ClusterWindow } from '../ClusterWindow/ClusterWindow';
import { Maybe } from '@apolloGenerated';
import { EventName, useGraphStore } from '@graph/libs';

export const NodeWindow: FC = () => {
  const { event } = useGraphStore();
  const [trDetail, setTrDetail] = useState<Maybe<Transaction>>(null);
  const { type } = useSidebarData();

  useEffect(() => {
    switch (event?.type) {
      case EventName.TABLE_CLICK_CHEVRON:
        setTrDetail(event.params!.item);
        break;
      case EventName.GRAPH_CLICK:
        setTrDetail(null);
        break;
    }
  }, [event]);

  return (
    <>
      {type === TableType.Cluster && <ClusterWindow />}
      {type === TableType.Address && <AddressWindow />}
      {trDetail && (
        <TransactionDetailWindow
          transaction={trDetail}
          onBack={() => setTrDetail(null)}
        />
      )}
    </>
  );
};
