import React, { FC } from 'react';
import { Typography } from '@rubin-dev/goblin';
import { timestampToHumanDate } from '@utils/timestamp';

export type TableCellDateProps = {
  timestamp: number | string;
};
export const TableCellDate: FC<TableCellDateProps> = ({ timestamp }) => {
  return (
    <div>
      <Typography variant="body-14">{timestampToHumanDate(timestamp, 'date')}</Typography>
      <Typography variant="body-12" color="on-secondary-2">
        {timestampToHumanDate(timestamp, 'time')}
      </Typography>
    </div>
  );
};
export const renderCellDate = (args: TableCellDateProps) => <TableCellDate {...args} />;
