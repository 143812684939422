import { FC } from 'react';
import styles from './styles.module.scss';
import { GraphBackButton } from './shared/GraphBackButton/GraphBackButton';
import { GraphNameInput } from './shared/GraphNameInput/GraphNameInput';
import { GraphExportButton } from './shared/GraphExportButton/GraphExportButton';
import { GraphSaveStatus } from './shared/GraphSaveStatus/GraphSaveStatus';
import { GraphSidebarButton } from './shared/GraphSidebarButton/GraphSidebarButton';
import { useGraphStatusStore } from '../../libs/store';
import { GraphInput } from '@graph/ui/GraphToolbar/shared/GraphInput/GraphInput';
import { GraphLoader } from '@graph/ui/GraphStatus/shared/GraphLoader/GraphLoader';
import { useConvertHtmlToImagePng } from '@rubin-dev/octavius';

export type GraphToolbarProps = {
  hasSidebar: boolean;
};
export const GraphToolbar: FC<GraphToolbarProps> = ({ hasSidebar }) => {
  const { isInitialized } = useGraphStatusStore();

  // @ts-ignore пофиксить типы в либе
  const [createImage, loading] = useConvertHtmlToImagePng({});

  if (loading) {
    return <GraphLoader background={'rgba(0,0,0,0.8)'} />;
  }

  return (
    <div className={styles.graphToolbar}>
      <div className={styles.graphToolbar__left}>
        <div className={styles.graphToolbar__leftTop}>
          <GraphBackButton />
          {isInitialized && (
            <div className={styles.graphToolbar__wrapper}>
              <GraphSaveStatus className={styles.graphToolbar__saveLoader} />
              <GraphNameInput
                appendSlot={
                  <GraphExportButton
                    //@ts-ignore доработать типы в либе
                    createImage={createImage}
                  />
                }
              />
              <GraphInput />
            </div>
          )}
        </div>
      </div>
      <div className={styles.graphToolbar__right}>
        {hasSidebar && <GraphSidebarButton />}
      </div>
    </div>
  );
};
