import { LinkItem } from '@graph/types';
import { EventName, useGraphStore } from '@graph/libs';
import { GraphCustomColor } from '@graph/modules';
import { ChangeLinkColorEventParams } from '@graph/libs/store';
import { useCallback } from 'react';

export type useMenuLinkEvent = {
  onChangeColor(color: ChangeLinkColorEventParams['color']): void;
  onAddAnnotation(): void;
};
export type LinkEvents = 'change-color' | 'add-annotation';
export const useMenuLinkEvent = (
  item: LinkItem,
  callback?: () => void,
): useMenuLinkEvent => {
  const { sendEvent } = useGraphStore();

  const handleChangeColor = useCallback(
    (color: GraphCustomColor) => {
      sendEvent({
        type: EventName.MENU_CHANGE_LINK_COLOR,
        params: {
          color,
          source: item.source.uuid,
          target: item.target.uuid,
        },
      });
    },
    [sendEvent, item],
  );
  const handleAddAnnotation = useCallback(() => {
    sendEvent({
      type: EventName.MENU_LINK_ADD_ANNOTATION,
      params: {
        source: item.source.uuid,
        target: item.target.uuid,
      },
    });
  }, [item, sendEvent]);

  const handleEvent = useCallback(
    (event: LinkEvents) => (params?: any) => {
      switch (event) {
        case 'change-color':
          handleChangeColor(params);
          break;
        case 'add-annotation':
          handleAddAnnotation();
          break;
      }
      callback && callback();
    },
    [callback, handleChangeColor],
  );

  return {
    onChangeColor: handleEvent('change-color'),
    onAddAnnotation: handleEvent('add-annotation'),
  };
};
