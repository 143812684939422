import React, { FC } from 'react';
import { format } from '@aml/amount';
import { useTranslation } from 'react-i18next';
import { LoaderOverlay } from '@rubin-dev/goblin';
import { CardItem, ListItem } from '@shared/ui';
import { TransactionsTooltip } from '@component/Address';

type TransactionsCardProps = {
  sent: number;
  received: number;
  total: number;
  loading?: boolean;
};
export const TransactionsCard: FC<TransactionsCardProps> = ({
  sent,
  total,
  received,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <CardItem
      rightSlot={
        <TransactionsTooltip
          total={format(total, 0)}
          totalSent={format(sent, 0)}
          totalReceive={format(received, 0)}
        />
      }
    >
      <LoaderOverlay show={loading} />
      <ListItem label={`${t('strings.transactions')}:`} value={format(total, 0)} />
    </CardItem>
  );
};
