import { FC, PropsWithChildren, useLayoutEffect, useRef } from 'react';
import { preventDefault } from '@rubin-dev/goblin';

export const EventsController: FC<PropsWithChildren> = ({ children }) => {
  const wrapRef = useRef<HTMLDivElement>(null);

  const preventContextMenuEvent = (event: Event) => {
    preventDefault(event);
    return false;
  };

  useLayoutEffect(() => {
    wrapRef.current?.addEventListener('contextmenu', preventContextMenuEvent);
    return () => {
      wrapRef.current?.removeEventListener('contextmenu', preventContextMenuEvent);
    };
  }, []);

  return <div ref={wrapRef}>{children}</div>;
};
