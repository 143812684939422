import React, { FC } from 'react';
import { Divider, LoaderOverlay } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

type TransactionDetailContainerProps = {
  sendingSlot: JSX.Element;
  receivingSlot: JSX.Element;
  isLoading?: boolean;
};
export const TransactionDetailContainer: FC<TransactionDetailContainerProps> = ({
  sendingSlot,
  receivingSlot,
  isLoading,
}) => {
  return (
    <div className={styles.trDetailContainer}>
      {isLoading && <LoaderOverlay show />}
      {sendingSlot}
      <Divider vertical />
      {receivingSlot}
    </div>
  );
};
