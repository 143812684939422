import { create } from 'zustand';
import i18n from 'i18next';
import {
  Network,
  SubscribedQuery,
  SubscribedQueryVariables,
  SubscribeMutation,
  SubscribeMutationVariables,
  UnsubscribeMutation,
  UnsubscribeMutationVariables,
} from '@apolloGenerated';
import { apolloClient } from '../../../../apollo';
import { subscribe, subscribed, unsubscribe } from '../../../../query';
import { Button, toaster } from '@rubin-dev/goblin';
const captureError = (e: Error): void => {
  console.error(e);
  throw e;
};
type SubscriptionState = {
  loading: boolean;
  isSubscribed: boolean;
  isSubscribedAction(network: Network, address: string): void;
  subscribeAction(network: Network, address: string): void;
  unsubscribeAction(network: Network, address: string): void;
};

export const useSubscriptionStore = create<SubscriptionState>()((set) => ({
  loading: true,
  isSubscribed: false,
  subscribeAction: async (network: Network, address: string) => {
    set(() => ({ loading: true }));

    await apolloClient.mutate<SubscribeMutation, SubscribeMutationVariables>({
      mutation: subscribe,
      variables: {
        input: { network, address },
      },
    });

    set(() => ({
      loading: false,
      isSubscribed: true,
    }));

    toaster.success({ title: i18n.t('explorer.subscription_subscribed') });
  },
  unsubscribeAction: async (network: Network, address: string) => {
    set(() => ({ loading: true }));

    const callback = async () => {
      await apolloClient.mutate<SubscribeMutation, SubscribeMutationVariables>({
        mutation: subscribe,
        variables: {
          input: { network, address },
        },
      });

      set(() => ({
        loading: false,
        isSubscribed: true,
      }));
    };

    await apolloClient.mutate<UnsubscribeMutation, UnsubscribeMutationVariables>({
      mutation: unsubscribe,
      variables: {
        input: { network, address },
      },
    });

    set(() => ({
      loading: false,
      isSubscribed: false,
    }));

    toaster.success({
      title: i18n.t('titles.remove_address'),
      contentSlot: (
        <Button size="small" onClick={callback} variant="outlined">
          {i18n.t('titles.cancel')}
        </Button>
      ),
    });
  },
  isSubscribedAction: async (network: Network, address: string) => {
    set(() => ({ loading: true }));

    const { data, error } = await apolloClient.query<
      SubscribedQuery,
      SubscribedQueryVariables
    >({
      query: subscribed,
      variables: {
        input: { network, address },
      },
      fetchPolicy: 'network-only',
    });

    if (error) {
      captureError(error);
    }

    set(() => ({
      loading: false,
      isSubscribed: data.subscribed,
    }));
  },
}));
