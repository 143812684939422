import { FC } from 'react';
import {
  ContextMenuWrapper,
  useContextMenuStore,
} from '@graph-visualization-module/entities/ContextMenu';
import { LinkMenu, NodeMenu } from '@graph-visualization-module/features/ContextMenu';
import { LinkItem, NodeItem } from '@graph/types';
import { LabelNodeMenu } from '@graph-visualization-module/features/ContextMenu/ui/LabelNodeMenu/LabelNodeMenu';

export const ContextMenu: FC = () => {
  const { item, onClose } = useContextMenuStore();

  return (
    <ContextMenuWrapper
      nodeSlot={<NodeMenu node={item as NodeItem} onSubmit={onClose} />}
      linkSlot={<LinkMenu link={item as LinkItem} onSubmit={onClose} />}
      labelSlot={
        <LabelNodeMenu
          node={item as NodeItem & { parentHash: string; text: string }}
          onSubmit={onClose}
        />
      }
    />
  );
};
