import cx from 'classnames';
import styles from './styles.module.scss';
import { CopyHash, TrashIcon } from '@rubin-dev/goblin';

export const renderKey = (key: string): JSX.Element => {
  return (
    <div className={styles.apiKeys__td}>
      <CopyHash hash={key} />
    </div>
  );
};

export const renderRemove = (onClick: () => void): JSX.Element => {
  return (
    <div className={cx(styles.apiKeys__td, styles.apiKeys__td_action)}>
      <TrashIcon className={styles.apiKeys__remove} onClick={onClick} />
    </div>
  );
};
