import { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import { Maybe } from '@apolloGenerated';

export enum ApolloErrors {
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  CORRUPTED_TOKEN = 'CORRUPTED_TOKEN',
  EXPIRED_REFRESH_TOKEN = 'EXPIRED_REFRESH_TOKEN',
  MISSING_AUTH_TOKEN = 'MISSING_AUTH_TOKEN',
}

export class ApolloErrorService {
  static getError(
    graphQLErrors?: GraphQLErrors,
    ...strError: string[]
  ): Maybe<GraphQLError> {
    return graphQLErrors?.find((err) => strError.includes(err.message)) || null;
  }

  static hasError(graphQLErrors?: GraphQLErrors, ...strError: string[]): boolean {
    return !!ApolloErrorService.getError(graphQLErrors, ...strError);
  }

  static debugConsoleErrors({
    graphQLErrors,
    networkError,
  }: {
    graphQLErrors?: GraphQLErrors;
    networkError?: NetworkError;
  }) {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) console.error(`[Network error]: ${networkError}`);
  }
}
