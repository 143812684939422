import { Network, useExplorerClusterForAddressLazyQuery } from '@apolloGenerated';
import { EventName, useGraphStatusStore, useGraphStore } from '../../store';
import { Node, NodeList } from '@graph/models';
import { NodeService } from '@graph/models/Node/NodeService';
import { RiskCategories } from '@graph/models/Risk/RiskCategories';
import { TableType } from '@graph/types/table';
import { useCallback } from 'react';
import { FEATURE_CLUSTERING } from '@graph/const/config.const';

export const useGraphNodeData = () => {
  const { sendEvent } = useGraphStore();
  const { isLoading, setLoading } = useGraphStatusStore();

  const [getClusterData] = useExplorerClusterForAddressLazyQuery();

  const emitUpdateClusterHash = useCallback(
    (wid: string) => {
      const isFirstNode = NodeList.getCountAddresses() <= 1;
      if (isFirstNode)
        sendEvent({
          type: EventName.DATA_UPDATE_HASH,
          params: { type: TableType.Cluster, hash: wid },
        });
    },
    [sendEvent],
  );

  const fetchNodeData = useCallback(
    async (hash: string, network: Network): Promise<Node> => {
      setLoading(true);
      const { data } = await getClusterData({
        variables: { address: hash, network },
      });
      const cluster = data?.explorerClusterForAddress.cluster;
      const hasCluster = !!cluster?.id && FEATURE_CLUSTERING;
      const address = hasCluster ? cluster?.id : hash;
      if (!NodeList.hashList[address]) {
        const getNodeData = NodeService.getNodeDataCreator(
          hasCluster ? 'cluster' : 'address',
        );
        const createArgs = await getNodeData(address, network);
        createArgs && NodeService.createNode(createArgs);

        if (hasCluster) emitUpdateClusterHash(address);
      }
      setLoading(false);
      return NodeList.hashList[address!];
    },
    [getClusterData, setLoading],
  );

  const handleNodeData = useCallback(
    async (hash: string, network: Network): Promise<Node> => {
      await RiskCategories.init(network);
      const node = await fetchNodeData(hash, network);
      sendEvent({ type: EventName.DATA_UPDATE }, true);
      return node;
    },
    [fetchNodeData, sendEvent],
  );

  return {
    isLoading,
    handleNodeData,
  };
};
