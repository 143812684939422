import { FC } from 'react';
import { Typography } from '@rubin-dev/goblin';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export const GraphNotFound: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.graphNotFound}>
      <Typography className={styles.graphNotFound__404} variant="head-32">
        404
      </Typography>
      <Typography className={styles.graphNotFound__title} variant="head-32">
        {t('strings.graphNotFound')}
      </Typography>
    </div>
  );
};
