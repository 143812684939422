import { ChangeEvent, FC, useState } from 'react';
import {
  Dialog,
  Maybe,
  Spacer,
  TextField,
  Typography,
  useKeyHandler,
  WithCloseModal,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { MAX_ANNOTATION_SYMBOL } from '@graph/const/annotation.const';
import cx from 'classnames';
export type GraphAnnotationModalProps = {
  text?: Maybe<string>;
  onSubmit(text: string): void;
};
declare global {
  interface ModalProps {
    annotationGraph: GraphAnnotationModalProps;
  }
}

export const GraphAnnotationModal: FC<WithCloseModal<GraphAnnotationModalProps>> = ({
  text,
  onSubmit,
  onClose,
}) => {
  const [value, setValue] = useState<string>(text || '');
  const hasError = MAX_ANNOTATION_SYMBOL <= value.length;
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > MAX_ANNOTATION_SYMBOL)
      setValue(e.target.value.slice(0, MAX_ANNOTATION_SYMBOL));
    else setValue(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(value);
    onClose();
  };

  useKeyHandler(handleSubmit, 'Enter');

  return (
    <Dialog width={500} closed title={t('titles.annotation')}>
      <TextField
        tag="textarea"
        className={cx(styles.annotationModal__input, styles.textarea)}
        autoFocus
        fullWidth
        placeholder={t('strings.enterAnnotation')}
        value={value}
        onChange={handleChange}
      />
      <Spacer size={6} />
      <div className={styles.annotationModal__info}>
        <Typography variant="body-16" color="on-surface-primary-2">
          {t('strings.annotationHelp')}
        </Typography>
        <Typography
          variant="body-16"
          color={hasError ? 'error-2' : 'on-surface-primary-2'}
        >
          {value.length} / {MAX_ANNOTATION_SYMBOL}
        </Typography>
      </div>
    </Dialog>
  );
};
