import { create } from 'zustand';
import { Maybe } from '@apolloGenerated';
import { ClickRightEventParams } from '@graph/libs/store';

export type ContextMenuStoreType = {
  isOpen: boolean;
  item: Maybe<ClickRightEventParams['item']>;
  onOpen(item: ClickRightEventParams['item']): void;
  onClose(): void;
};
export const useContextMenuStore = create<ContextMenuStoreType>()((set) => ({
  isOpen: false,
  item: null,
  onOpen: (item) => set({ item, isOpen: true }),
  onClose: () => set({ isOpen: false, item: null }),
}));
