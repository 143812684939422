import React, { FC } from 'react';
import { hasPermission } from '@utils/auth';
import { SUBSCRIPTION_EXPLORER_MARKUP } from '@constants/permissions.const';
import { Typography } from '@rubin-dev/goblin';
import { Mentions } from './Mentions';
import { useTranslation } from 'react-i18next';

interface Props {
  network: string;
  address: string;
  checkCount?: number;
  subscribeCallback: () => void;
  onClick: () => void;
}
export const PermissionWrapper: FC<Props> = ({
  network,
  address,
  subscribeCallback,
  checkCount,
  onClick,
}) => {
  const { t } = useTranslation();

  if (!hasPermission(SUBSCRIPTION_EXPLORER_MARKUP))
    return (
      <Typography variant={'head-20'}>{t('explorer.permission_subscribe')}</Typography>
    );

  return (
    <Mentions
      network={network}
      address={address}
      subscribeCallback={subscribeCallback}
      checkCount={checkCount}
      onClick={onClick}
    />
  );
};
