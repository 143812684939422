import { gql } from '@apollo/client';

export const nodeFragment = gql`
  fragment NodeFields on Node {
    id
    props
  }
`;

export const mentionFragment = gql`
  fragment MentionFields on Mention {
    id
    description
    createdAt
    label
    link
    address
    reportId
    owner
    otherAddresses {
      network
      address
    }
    source {
      name
      sourceAddress
      categoryNumber
    }
    risk {
      color
      categoryNumber
    }
    isPaid
    uuid
    categoryNumber
  }
`;

export const blockFragment = gql`
  fragment BlockchainBlockFields on BlockchainBlock {
    hash
    height
    timestamp
  }
`;

export const findAddressByHashNodeFragment = gql`
  fragment FindAddressByHashNodeFields on FindAddressByHashNode {
    totalIn
    totalOut
    total
  }
`;

export const calculatedRiskFragment = gql`
  fragment CalculatedRiskFields on CalculatedRisk {
    total
    risk
    items {
      number
      total
      total_human
      risk
      risk_raw
      percent
      percent_raw
      total
      name
      i18n {
        en
        ru
      }
      color
    }
  }
`;

export const riskFragment = gql`
  ${calculatedRiskFragment}
  fragment RiskFields on Risk {
    risk
    reported {
      risk
      name
      number
    }
    calculated {
      total_human
      ...CalculatedRiskFields
    }
  }
`;
