import { gql } from '@apollo/client';
import { userFragment } from './fragments';

export const user = gql`
  ${userFragment}
  query user($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
`;

export const userList = gql`
  ${userFragment}
  query userList($page: Int!, $pageSize: Int!) {
    userList(page: $page, pageSize: $pageSize) {
      total
      edge {
        ...UserFields
      }
      errors
    }
  }
`;

export const userActivate = gql`
  mutation activate($input: ActivateInput!) {
    activate(input: $input) {
      state
      errors
    }
  }
`;

export const userCreate = gql`
  mutation create($input: CreateInput!) {
    create(input: $input) {
      email
      permissions
      password
      errors
    }
  }
`;

export const userGeneratePassword = gql`
  mutation generatePassword($input: GeneratePasswordInput!) {
    generatePassword(input: $input) {
      password
      errors
    }
  }
`;

export const setUserPermissions = gql`
  mutation setUserPermissions($input: SetPermissionsInput!) {
    setUserPermissions(input: $input) {
      errors
    }
  }
`;

export const deleteMutation = gql`
  mutation delete($input: DeleteInput!) {
    delete(input: $input) {
      state
      errors
    }
  }
`;
