import { FC, useState } from 'react';
import { MarginDetailsComponent } from '@component/Marking/MarginDetailsComponent';
import { detectNetworkByAddress } from '@aml/validation';

interface Props {
  query: {
    id: number;
    query: string;
  };
}

export const MarkingDetailsScreen: FC<Props> = ({ query }) => {
  const [network] = useState<string>(String(detectNetworkByAddress(query.query)));

  return <MarginDetailsComponent query={query.query} id={query.id} network={network} />;
};
