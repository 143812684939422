import { GraphApiErrors, GraphDataResult } from '../../types/graph';
import { Maybe } from '@apolloGenerated';

export default <D>({
  data,
  error,
}: {
  data?: Maybe<D>;
  error?: GraphApiErrors;
}): GraphDataResult<D> => ({
  data: data || null,
  error: error ? { code: error } : null,
  status: error ? 'error' : 'success',
});
