import { FC } from 'react';
import { AddressTransactionsFilterTxType, Network } from '@apolloGenerated';
import styles from './styles.module.scss';
import { Spacer, Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { NetworkIcon, TableCellAmount } from '@shared/ui';

export type TransactionItemProps = {
  label: string;
  total: number | string;
  count: number | string;
  token: string;
  network: Network;
  direction: AddressTransactionsFilterTxType;
};
export const TransactionItem: FC<TransactionItemProps> = ({
  label,
  total,
  count,
  token,
  network,
  direction,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.trItem}>
      <Typography variant="head-16" color="on-surface-primary-1">
        {label}
      </Typography>
      <Spacer size={8} />
      <div className={styles.trItem__body}>
        <NetworkIcon network={network} />
        <div>
          <TableCellAmount
            style={{ maxWidth: '110px' }}
            amount={total || 0}
            network={network}
            showSymbol={false}
            variant="body-14"
            type={direction}
          />
          <Typography variant="body-12" color="on-surface-primary-2">
            {token}
          </Typography>
        </div>
      </div>
      <Spacer size={4} />
      <div className={styles.trItem__transactions}>
        <Typography variant="body-14" color="on-surface-primary-2">
          {t('strings.transactions')}:
        </Typography>
        <Typography variant="body-14" color="on-surface-primary-1">
          {count}
        </Typography>
      </div>
    </div>
  );
};
