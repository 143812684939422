import React, { FC, useLayoutEffect, useState } from 'react';
import { AppError } from '../../component';
import { persistUser } from '@utils/auth';
import * as gqlTypes from '../../apolloGenerated';
import { Link } from 'react-justanother-router';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import { Loader, Paper, Spacer, toaster, Typography } from '@rubin-dev/goblin';

type AuthRegistrationConfirmScreenProps = {
  params: { token: string };
};

export const AuthRegistrationConfirmScreen: FC<AuthRegistrationConfirmScreenProps> = ({
  params: { token },
}): JSX.Element => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [mutate, { error, loading }] = gqlTypes.useRegistrationConfirmMutation();

  useLayoutEffect(() => {
    const checkToken = async () => {
      const resp = await mutate({
        variables: {
          input: {
            token,
          },
        },
      });
      const formErrors = resp.data?.registrationConfirm?.errors || {};
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        toaster.error({ title: 'validation error' });
      } else {
        if (resp.data?.registrationConfirm.jwt) {
          persistUser(resp.data.registrationConfirm.jwt);
          window.location.pathname = '/';
        }
      }
    };
    checkToken();
  }, [mutate, token]);

  return (
    <>
      <Link to={RouterName.AuthRegistration} className="b-auth__back">
        &larr; {t('titles.back')}
      </Link>
      <Paper>
        <Typography variant="head-24" color="on-surface-primary-1">
          {t('buttons.register')}
        </Typography>
        <Spacer size={8} />
        {loading && <Loader />}
        {error && <AppError error={error.message} />}
        {Object.keys(errors).length > 0 && <div>{t('errors.invalidRegisterToken')}</div>}
      </Paper>
    </>
  );
};
