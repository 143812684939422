import React, { FC, useMemo, useState } from 'react';
import { LoaderOverlay, TableController, Typography } from '@rubin-dev/goblin';
import { headersGraphsListTable } from '../../const/table.const';
import styles from './styles.module.scss';
import { renderCellDate, renderCellToken } from '@shared/ui';
import { Maybe, OrderDirection, useGraphListQuery } from '@apolloGenerated';
import { GraphsListControlDropdown } from './shared/GraphsListControlDropdown/GraphsListControlDropdown';
import { GraphsListHeader } from './shared/GraphsListHeader/GraphsListHeader';
import { GraphsListImportButton } from './shared/GraphsListImportButton/GraphsListImportButton';
import { openVisualizationByUuid } from '@shared/libs';

const PAGE_SIZE = 15;
export const GraphsList: FC = () => {
  const [selectedItem, setSelectedItem] = useState<Maybe<any>>(null);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<Maybe<OrderDirection>>(OrderDirection.Desc);
  const {
    data: curData,
    previousData,
    refetch,
    loading,
  } = useGraphListQuery({
    variables: { page, pageSize: PAGE_SIZE, order },
  });
  const data = curData?.graphList?.edge || previousData?.graphList?.edge;
  const total = curData?.graphList?.total || previousData?.graphList?.total || 0;
  const items = useMemo(
    () =>
      (data || []).map(
        ({
          data: {
            meta: { network, name },
          },
          updatedAt,
          uuid,
        }) => ({
          uuid,
          networkEnum: network,
          name: (
            <Typography
              tag="div"
              variant="body-14"
              className={styles.graphsList__td_name}
              ellipsis
            >
              {name}
            </Typography>
          ),
          updatedAt: renderCellDate({ timestamp: updatedAt }),
          network: renderCellToken({ token: network }),
          control: (
            <div className={styles.graphsList__td_control}>
              <GraphsListControlDropdown
                name={name}
                uuid={uuid}
                refetch={refetch}
                onChange={(val) => setSelectedItem(val ? { uuid } : null)}
              />
            </div>
          ),
        }),
      ),
    [selectedItem, setSelectedItem, data],
  );

  return (
    <div className={styles.graphsList}>
      <GraphsListHeader>
        <GraphsListImportButton refetch={refetch} />
      </GraphsListHeader>
      <LoaderOverlay show={loading} />
      <div className={styles.graphsList__content}>
        <TableController
          data={items}
          headers={headersGraphsListTable()}
          total={total}
          onChangePage={setPage}
          pageSize={PAGE_SIZE}
          initSortBy={'updatedAt'}
          initSort={order}
          activeRow={selectedItem}
          onSort={(sort) => setOrder(sort as OrderDirection)}
          onClickRow={(_, item) => openVisualizationByUuid(item.networkEnum, item.uuid)}
          notResetPage
        />
      </div>
    </div>
  );
};
