import React, { FC } from 'react';
import { AppError, AuthLoginFormContainer, AuthLoginFormInput } from '..';
import * as gqlTypes from '../../apolloGenerated';
import { persistUser } from '@utils/auth';
import * as gqlQueries from '../../query';
import { FormCallback, toaster } from '@rubin-dev/goblin';

export type AuthLoginFormProps = {
  onSuccess?: () => void;
  refetchMeQuery?: boolean;
};

export const AuthLoginForm: FC<AuthLoginFormProps> = ({
  onSuccess,
  refetchMeQuery = false,
}): JSX.Element => {
  const [mutate, { error, loading, client }] = gqlTypes.useLoginMutation();

  const formOnSubmit: FormCallback<AuthLoginFormInput> = async (
    data,
    setFormErrors,
    setBatchValues,
  ) => {
    const formattedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, value.trim()]),
    ) as AuthLoginFormInput;

    await mutate({
      variables: {
        input: formattedData,
      },
      onCompleted: async (data) => {
        const formErrors = data?.login?.errors || {};
        if (Object.keys(formErrors).length > 0) {
          toaster.error({ title: 'validation error' });
          setFormErrors(formErrors);
        } else {
          setFormErrors({});
          setBatchValues({
            email: '',
            password: '',
          });
          if (data.login.jwt) {
            persistUser(data.login.jwt);
            if (refetchMeQuery) {
              await client.query({
                query: gqlQueries.me,
              });
            }
            onSuccess && onSuccess();
          }
        }
      },
    });
  };

  return (
    <>
      {error && <AppError error={error.message} />}
      <AuthLoginFormContainer loading={loading} formOnSubmit={formOnSubmit} />
    </>
  );
};
