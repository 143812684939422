import React, { FC, PropsWithChildren } from 'react';
import { LayoutUser } from '@component/Layout';
import styles from './styles.module.scss';
import { Spacer } from '@rubin-dev/goblin';

export const LayoutProfile: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LayoutUser>
      <div className={styles.layoutProfile}>{children}</div>
      <Spacer size={40} />
    </LayoutUser>
  );
};
