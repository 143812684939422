import React, { FC } from 'react';
import { Typography, IconProps, CheckRoundIcon, Loader } from '@rubin-dev/goblin';
import { PoliceIcon } from '@shared/ui';
import styles from './styles.module.scss';
import { useSubscriptionStore } from '../../libs/store';
import { useTranslation } from 'react-i18next';

type MentionsErrorProps = {
  iconComponent?: FC<IconProps>;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  buttonSlot?: JSX.Element;
};

export const MentionsError: FC<MentionsErrorProps> = ({
  iconComponent: IconComponent,
  title,
  buttonSlot,
  description,
}) => {
  const { t } = useTranslation();
  const { isSubscribed, loading } = useSubscriptionStore();

  return (
    <>
      <div className={styles['mentions']}>
        {!loading ? (
          <div className={styles['mentions__wrapper']}>
            {isSubscribed ? (
              <>
                <CheckRoundIcon
                  width={50}
                  height={50}
                  style={{ fill: '#d7dee9' }}
                  color={'#fff'}
                />
                <Typography variant={'head-20'}>{t('markup.requestHasBeen')}</Typography>
                <Typography className={styles.mentions__description} variant={'body-16'}>
                  {t('markup.waitAnswer')}
                </Typography>
              </>
            ) : (
              <>
                {IconComponent ? (
                  <IconComponent width={40} height={40} />
                ) : (
                  <PoliceIcon width={40} height={40} />
                )}
                <Typography tag="h2" variant="head-20">
                  {title}
                </Typography>
                {description && (
                  <Typography variant="body-16" color="on-secondary-2">
                    {description}
                  </Typography>
                )}
              </>
            )}
          </div>
        ) : (
          <Loader />
        )}
        {buttonSlot}
      </div>
    </>
  );
};
