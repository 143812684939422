import i18n from '../../../i18n/i18n';
export const PAGE_SIZE = 10;

export const headersGraphsListTable = () => [
  { value: 'uuid', hide: true, key: true },
  { title: i18n.t('titles.name'), value: 'name', width: '25%' },
  {
    title: i18n.t('titles.last_update'),
    value: 'updatedAt',
    width: '20%',
    sortable: true,
  },
  { title: i18n.t('titles.blockchain'), value: 'network', width: '20%' },
  { value: 'control' },
];
