import i18n from '../../../../i18n/i18n';
import styles from './styles.module.scss';

export const headers = () => [
  { value: 'chevron', width: 24, className: styles.wrapper__cell_noPadding },
  { title: i18n.t('titles.date'), value: 'timestamp', sortable: true, width: '15%' },
  { title: i18n.t('titles.hash'), value: 'hash', width: '15%' },
  { title: i18n.t('titles.token'), value: 'token', width: '12%' },
  { value: 'sender_address', width: 130 },
  { value: 'arrow_left', width: 40 },
  { title: i18n.t('strings.amount'), value: 'amount', width: 118, sortable: true },
  { value: 'arrow_right', width: 40 },
  { value: 'receiving_address' },
  {
    value: 'visualization',
    width: 32,
    className: styles.wrapper__cell_noPadding,
  },
  { hide: true, key: true, value: 'key' },
];
