import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Paper, Typography } from '@rubin-dev/goblin';
import { ProfileProductWrapper } from '../ProfileProductWrapper/ProfileProductWrapper';
import styles from './styles.module.scss';
import { formatNumber } from '@aml/amount';
import { IconApi } from '@shared/ui';

export type ProfileApiProps = {
  countAddress: number;
  countTransaction: number;
  bottomSlot: JSX.Element;
};

export const ProfileApi: FC<ProfileApiProps> = ({
  countAddress,
  countTransaction,
  bottomSlot,
}) => {
  const { t } = useTranslation();

  return (
    <ProfileProductWrapper title="API" iconPath={<IconApi />}>
      <div className={styles.profileApi}>
        <div className={styles.profileApi__row}>
          <div className={styles.profileApi__content}>
            <Typography variant="body-16" color="on-secondary-2">
              {t('strings.checksAddresses')}:
            </Typography>
            <Typography variant="head-16" color="on-surface-primary-1">
              {formatNumber(countAddress)}
            </Typography>
          </div>
          {/*<Button className={styles.profileApi__button} color="secondary" fullWidth>*/}
          {/*  {t('buttons.topUp')}*/}
          {/*</Button>*/}
        </div>
        <Divider />
        <div className={styles.profileApi__row}>
          <div className={styles.profileApi__content}>
            <Typography variant="body-16" color="on-secondary-2">
              {t('strings.checksTransactions')}:
            </Typography>
            <Typography variant="head-16" color="on-surface-primary-1">
              {formatNumber(countTransaction)}
            </Typography>
          </div>
          {/*<Button className={styles.profileApi__button} color="secondary" fullWidth>*/}
          {/*  {t('buttons.topUp')}*/}
          {/*</Button>*/}
        </div>
        {bottomSlot && (
          <Paper px={12} py={12} className={styles.profileApi__row}>
            {bottomSlot}
          </Paper>
        )}
      </div>
    </ProfileProductWrapper>
  );
};
