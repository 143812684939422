import { EventName, useGraphStatusStore, useGraphStore } from '../../store';
import { TableType } from '@graph/types/table';
import { AddressBetweenList, AddressTable, LinkService, Meta } from '@graph/models';
import {
  AddressTransactionsFilterTxType,
  Network,
  useExplorerIncomingAddressListByTransactionLazyQuery,
  useExplorerOutcomingAddressListByTransactionLazyQuery,
  useGraphRelationBetweenAddressesLazyQuery,
} from '@apolloGenerated';
import { useGraphNodeData, useGraphLineController } from '@graph/libs/hooks';
import { Transaction } from '@graph/modules';
import { useCallback } from 'react';

export const useGraphLineData = () => {
  const { setCrash, setInitialized } = useGraphStatusStore();
  const { sendEvent } = useGraphStore();
  const [getRelationsWithAddress] = useGraphRelationBetweenAddressesLazyQuery();
  const [getOutcomingData] = useExplorerOutcomingAddressListByTransactionLazyQuery();
  const [getIncomingData] = useExplorerIncomingAddressListByTransactionLazyQuery();
  const { handleNodeData } = useGraphNodeData();
  const { updateLine } = useGraphLineController();
  const handleBetweenAddressData = useCallback(
    (hash: string) => {
      if (!AddressBetweenList.getTableDataByHash(hash))
        AddressBetweenList.create(new AddressTable(hash, TableType.Transaction));
      sendEvent({ type: EventName.DATA_UPDATE }, true);
    },
    [sendEvent],
  );

  const initAddressesWithLink = useCallback(
    async (
      { hash, token, amount }: { hash: string; token: string; amount: string },
      outcomingHash: string,
      incomingHash: string,
    ) => {
      const network = Meta.network;
      const [sentNode, receiveNode] = await Promise.all([
        handleNodeData(outcomingHash, network),
        handleNodeData(incomingHash, network),
      ]);
      updateLine(
        true,
        new Transaction(
          {
            txID: hash,
            senderAddr: [{ address: sentNode.hash, network }],
            recvAddr: [{ address: receiveNode.hash, network }],
            amount,
            token,
            direction: AddressTransactionsFilterTxType.Receives,
            timestamp: 100000,
          },
          network,
          TableType.Transaction,
        ),
      );
      return [sentNode, receiveNode];
    },
    [handleNodeData, updateLine],
  );

  const initBetweenAddressByTr = useCallback(
    async (hash: string, network: Network) => {
      const reqParams = { variables: { network, txid: hash, page: 1, pageSize: 1 } };
      const [
        { data: incomingData, error: incomingError },
        { data: outcomingData, error: outcomingError },
      ] = await Promise.all([getIncomingData(reqParams), getOutcomingData(reqParams)]);
      if (outcomingData && incomingData) {
        const outcomingItem =
          outcomingData.explorerOutcomingAddressListByTransaction.edge[0];
        const incomingItem =
          incomingData.explorerIncomingAddressListByTransaction.edge[0];
        const [{ hash: sentHash }, { hash: receiveHash }] = await initAddressesWithLink(
          { hash, token: incomingItem.token, amount: incomingItem.summ },
          outcomingItem.address,
          incomingItem.address,
        );
        setInitialized(true);
        sendEvent({
          type: EventName.DATA_UPDATE_HASH,
          params: {
            type: TableType.Transaction,
            hash: LinkService.generateLinkHash(sentHash, receiveHash),
          },
        });
      }
      if (incomingError || outcomingError) setCrash();
    },
    [
      getIncomingData,
      getOutcomingData,
      initAddressesWithLink,
      setInitialized,
      setCrash,
      sendEvent,
    ],
  );

  const addAllTransactionsWithCurrentAddress = async (
    hash: string,
    addresses: string[],
    network: Network,
  ) => {
    const { data } = await getRelationsWithAddress({
      variables: { params: { addresses, newAddress: hash, network } },
    });
    for (const relation of data?.explorerRelationBetweenAddresses.relations || []) {
      const isSends = relation.direction === 'sends';
      await initAddressesWithLink(
        { hash: relation.transactionId, token: relation.token, amount: relation.amount },
        isSends ? relation.address : hash,
        isSends ? hash : relation.address,
      );
    }
    sendEvent({ type: EventName.DATA_UPDATE }, true);
  };

  return {
    handleBetweenAddressData,
    initBetweenAddressByTr,
    addAllTransactionsWithCurrentAddress,
  };
};
