import React, { FC, PropsWithChildren } from 'react';
import { Wrapper } from '@component/downloadPDF';

interface Props {
  params: {
    address: string;
    network: string;
  };
}

export const DownloadPDF: FC<PropsWithChildren<Props>> = ({ params }) => {
  const { address, network } = params;
  return <Wrapper address={address} network={network} />;
};
