import React, { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import sdk from '@aml/sdk';
import {
  Button,
  CloseIcon,
  Loader,
  Table,
  TextField,
  ResetIcon,
  Overlay,
  Driver,
  Typography,
} from '@rubin-dev/goblin';
import styles from './transactions.module.scss';
import { UserCategory } from '@aml/sdk/dashboard';
import cx from 'classnames';

export interface EditLimitModalProps {
  onClose?: (val: boolean) => void;
}

export type CustomLimitValues = {
  [key: string | number]: number | string;
};

export const EditLimitModal: FC<EditLimitModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const headers = [
    { title: t('links.category'), value: 'category' },
    { title: t('strings.reccomendedLimit'), value: 'recommended_limit' },
    { title: t('strings.customLimit'), value: 'limit', width: 108 },
  ];

  const [isLoadingReset, setIsLoadingReset] = useState<boolean>(false);
  const [values, setValues] = useState<CustomLimitValues>({});

  const {
    mutateAsync: updateUserCategoryAsync,
    mutate: updateUserCategory,
    isSuccess: isSuccessUpdate,
  } = sdk.dashboard.mutations.usePutSetNumberToUserCategory();
  const { data, refetch, isFetching } = sdk.dashboard.queries.useGetUserCategoryList();

  useEffect(() => {
    if (isSuccessUpdate)
      refetch().then((resp) => resp.data && actualizeValues(resp.data.userCategoryList));
  }, [isSuccessUpdate]);

  const actualizeValues = (userCategoryList: UserCategory[]) => {
    if (!userCategoryList?.length) return;
    let result = {};
    userCategoryList.forEach((item) => {
      result = {
        ...result,
        [item.id]: item.limit,
      };
    });
    setValues(result);
  };

  useMemo(() => {
    if (data) actualizeValues(data.userCategoryList);
  }, [data]);

  const handleInputChange = (id: number, value: number | string) => {
    setValues((state) => ({
      ...state,
      [id]: value,
    }));
  };

  const onApply = async () => {
    const result = Object.entries(values).map(([id, limit]) => ({
      id: Number(id),
      limit: Number(limit),
    }));
    if (!result?.length) return;

    updateUserCategory({
      risks: result,
    });

    onClose && onClose(false);
  };

  const onReset = async () => {
    setIsLoadingReset(true);
    const result = data?.userCategoryList?.map(({ id, recommended_limit }) => ({
      id: Number(id),
      limit: Number(recommended_limit),
    }));
    if (!result?.length) return;
    await updateUserCategoryAsync({
      risks: result,
    });
    setIsLoadingReset(false);
  };
  const rollbackValue = async (id: number, limit: number) => {
    updateUserCategoryAsync({
      risks: [{ id, limit }],
    });
  };

  const customLimit = (id: number, limit: number, recomended_limit: number) => {
    const hasRollbackIcon = values && values[id] !== recomended_limit;
    const value = values ? values[id] : limit;

    return (
      <div className={styles.edit__cell}>
        <TextField
          className={styles.edit__limit}
          value={String(value)}
          onChange={(e) => handleInputChange(id, e.target.value)}
          onBlur={(e) => {
            let numberValue = !isNaN(parseFloat(e.target.value))
              ? parseFloat(e.target.value)
              : recomended_limit;
            if (numberValue > 100) numberValue = 100;
            setValues({
              ...values,
              [id]: numberValue,
            });
          }}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          size="small"
          appendSlot={
            hasRollbackIcon ? (
              <ResetIcon
                className={styles.transactions__reset}
                onClick={(e) => {
                  e.stopPropagation();
                  rollbackValue(id, recomended_limit);
                }}
                width={23}
                height={23}
              />
            ) : (
              <></>
            )
          }
        />
      </div>
    );
  };

  const customCellCategory = (category: string, color: string) => {
    return (
      <div className={cx(styles.edit__cell, styles.edit__cell_start)}>
        <div className={styles.edit__dot} style={{ background: color }} />
        <span>{category}</span>
      </div>
    );
  };

  const customCellLimit = (text: string) => (
    <div className={styles.edit__cell}>{text}</div>
  );

  const items = useMemo(
    () =>
      data?.userCategoryList.map(({ id, category, color, recommended_limit, limit }) => ({
        id,
        category: customCellCategory(category, color),
        recommended_limit: customCellLimit(`${recommended_limit}%`),
        limit: customLimit(id, limit, recommended_limit),
      })) || [],
    [values],
  );

  return (
    <Overlay>
      <Driver width={611} px={0} className={styles.edit}>
        <div className={styles.edit__content}>
          <div className={styles.edit__header}>
            <CloseIcon
              className={styles.edit__close}
              onClick={() => onClose && onClose(false)}
              width={24}
              height={24}
            />
            <div className={styles.edit__title}>
              <Typography variant={'head-24'}>{t('buttons.editLimit')}</Typography>
              <div className={styles.edit__controls}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => onReset()}
                  disabled={isLoadingReset}
                >
                  {isLoadingReset ? (
                    <Loader className={'b-sidebar__actions-button--loading'} size={18} />
                  ) : (
                    t('buttons.reset')
                  )}
                </Button>
                <Button onClick={() => onApply()} size="small">
                  {t('buttons.apply')}
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.edit__wrapper}>
            {isFetching ? (
              <Loader size={32} />
            ) : (
              <Table headers={headers} items={items} />
            )}
          </div>
        </div>
      </Driver>
    </Overlay>
  );
};
