import { gql } from '@apollo/client';
import { jwtFragment } from './fragments';

export const refreshToken = gql`
  ${jwtFragment}
  mutation refreshToken {
    refreshToken {
      result {
        ...JwtFields
      }
      errors
    }
  }
`;
