import { Maybe, Network, useExplorerFindAddressByHashLazyQuery } from '@apolloGenerated';
import { isValidAddress } from '@shared/libs';
import { useState } from 'react';

export const useAddressValidate = (
  network: Network,
): [(id: string) => Promise<boolean>, { loading: boolean; value: Maybe<boolean> }] => {
  const [value, setValue] = useState<Maybe<boolean>>(null);
  const [findAddressByHash, { loading }] = useExplorerFindAddressByHashLazyQuery();
  const validate = async (address: string): Promise<boolean> => {
    if (!isValidAddress(address, network)) {
      setValue(false);
      return false;
    }
    try {
      await findAddressByHash({
        variables: {
          network,
          address,
        },
      });
      setValue(true);
      return true;
    } catch {
      setValue(false);
      return false;
    }
  };
  return [validate, { loading, value }];
};
