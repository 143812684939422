import React, { FC, useState } from 'react';
import { Typography, WithCloseModal, Dialog, Button } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { IconAnalyzer, IconApi, IconMarkup } from '@shared/ui';
import cx from 'classnames';
import { TariffAnalyzer, TariffApi, TariffMarkup } from '../../../screen';
import styles from './tariff.module.scss';

export type Props = {};
declare global {
  interface ModalProps {
    dialogTariffSelect: Props;
  }
}
export const DialogTariffSelect: FC<WithCloseModal<Props>> = () => {
  const { t } = useTranslation();

  enum TariffTabs {
    ANALYZER,
    MARKUP,
    API,
  }

  const [tab, setActiveTab] = useState<TariffTabs>(TariffTabs.ANALYZER);

  return (
    <Dialog
      width={1000}
      titleCenter
      title={
        <Typography tag="h3" variant="head-24" color="on-surface-primary-1">
          {t('titles.pricingPlans')}
        </Typography>
      }
      closed
    >
      <div className={styles['tariff__wrapper']}>
        <div className={styles['tariff__controls']}>
          <Button
            variant={'outlined'}
            prependIcon={() => IconAnalyzer({ width: 32, height: 32 })}
            className={cx(styles['tariff__btn'], [
              tab === TariffTabs.ANALYZER && styles['active'],
            ])}
            onClick={() => setActiveTab(TariffTabs.ANALYZER)}
          >
            <Typography variant={'head-16'} color={'on-surface-primary-1'}>
              {t('titles.analyzer')}
            </Typography>
          </Button>
          <Button
            variant={'outlined'}
            prependIcon={() => IconMarkup({ width: 32, height: 32 })}
            className={cx(styles['tariff__btn'], [
              tab === TariffTabs.MARKUP && styles['active'],
            ])}
            onClick={() => setActiveTab(TariffTabs.MARKUP)}
          >
            <Typography variant={'head-16'} color={'on-surface-primary-1'}>
              {t('titles.markup')}
            </Typography>
          </Button>
          <Button
            variant={'outlined'}
            prependIcon={() => IconApi({ width: 32, height: 32 })}
            className={cx(styles['tariff__btn'], [
              tab === TariffTabs.API && styles['active'],
            ])}
            onClick={() => setActiveTab(TariffTabs.API)}
          >
            <Typography variant={'head-16'} color={'on-surface-primary-1'}>
              {t('links.api')}
            </Typography>
          </Button>
        </div>
        <div>
          {tab === TariffTabs.ANALYZER && <TariffAnalyzer />}
          {tab === TariffTabs.API && <TariffApi />}
          {tab === TariffTabs.MARKUP && <TariffMarkup />}
        </div>
      </div>
    </Dialog>
  );
};
