import { FC, useState, useMemo } from 'react';
import { TableType } from '@graph/types/table';
import {
  AddressTransaction,
  AddressTransactionsFilterTxType,
  AddressTransactionsOrderField,
  OrderDirection,
  useExplorerFindTransactionsByClusterCountQuery,
  useExplorerFindTransactionsByClusterQuery,
} from '@apolloGenerated';
import { LoaderOverlay, Toggle, TableController, Spacer } from '@rubin-dev/goblin';
import {
  renderCellAmount,
  renderCellCheckbox,
  renderCellChevron,
  renderCellDate,
  renderCellHash,
  renderCellToken,
} from '@shared/ui';
import {
  renderCellUTXO,
  SortParams,
  TABLE_TRANSACTION_TABS,
  Transaction,
  useSidebarData,
  useSidebarEvents,
  useSidebarTrTabs,
} from '@graph-sidebar-module/shared';
import { headersTransactions, PAGE_SIZE, INIT_SORT, INIT_SORT_BY } from '../../conts';
import { AddressTable } from '@graph/models';
import styles from './styles.module.scss';

export const ClusterTransactions: FC = () => {
  const { network, hash, checked } = useSidebarData();
  const { onChangeChecked, onClickChevron } = useSidebarEvents();
  const [tab, setTab] = useSidebarTrTabs();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortParams>({
    sort: INIT_SORT,
    sortBy: INIT_SORT_BY,
  });
  const { data: dataCountReceives } = useExplorerFindTransactionsByClusterCountQuery({
    variables: {
      network,
      filter: { cluster: hash, txType: AddressTransactionsFilterTxType.Receives },
    },
  });
  const { data: dataCountSent } = useExplorerFindTransactionsByClusterCountQuery({
    variables: {
      network,
      filter: { cluster: hash, txType: AddressTransactionsFilterTxType.Sent },
    },
  });
  const countReceives = Number(
    dataCountReceives?.explorerFindTransactionsByClusterCount?.total || 0,
  );
  const countSent = Number(
    dataCountSent?.explorerFindTransactionsByClusterCount?.total || 0,
  );
  const { data, previousData, loading } = useExplorerFindTransactionsByClusterQuery({
    variables: {
      network,
      filter: {
        cluster: hash,
        page,
        order: sort.sort
          ? {
              field: sort.sortBy as AddressTransactionsOrderField,
              direction: sort.sort as OrderDirection,
            }
          : null,
        txType: tab,
        pageSize: PAGE_SIZE,
      },
    },
  });

  const rowTemplate = (transaction: AddressTransaction) => {
    let checkboxRender: JSX.Element | string;
    const item = new Transaction(transaction, network, TableType.Cluster, true);
    const checkedItem = AddressTable.getCheckedItem(
      item.txid,
      item.type,
      item.sourceAddress!,
      item.targetAddress!,
    );
    if (AddressTable.hasChecked(checked, checkedItem)) item.setChecked(true);

    if (item.isUTXO) checkboxRender = renderCellUTXO();
    else if (item.sourceAddress === item.targetAddress) checkboxRender = ' ';
    else if (item.isDirectTransfer)
      checkboxRender = renderCellCheckbox({
        checked: item.checked,
        onChange: (val) => onChangeChecked(val, item),
      });
    else checkboxRender = renderCellChevron({ onClick: () => onClickChevron(item) });

    return {
      hashUniq: AddressTable.getCheckedHash(checkedItem),
      checkbox: checkboxRender,
      timestamp: renderCellDate({ timestamp: item.timestamp! }),
      hash: renderCellHash({ hash: item.txid, type: 'transaction', network }),
      token: renderCellToken({ token: item.token }),
      amount: renderCellAmount({ amount: item.amount!, network, type: item.type }),
    };
  };
  const items = useMemo(
    () =>
      (
        data?.explorerFindTransactionsByCluster.edge ||
        previousData?.explorerFindTransactionsByCluster.edge ||
        []
      ).map(rowTemplate),
    [data, rowTemplate],
  );
  const total = useMemo(
    () =>
      Math.floor(
        tab === 'all'
          ? countReceives + countSent
          : tab === 'receives'
          ? countReceives
          : countSent,
      ),
    [countReceives, countSent, tab],
  );
  const hasTabs = !!(countReceives && countSent);

  return (
    <div className={styles.clusterTr}>
      {loading && <LoaderOverlay show />}
      {hasTabs && (
        <Toggle
          initValue={tab}
          items={TABLE_TRANSACTION_TABS()}
          onChange={setTab}
          size="small"
        />
      )}
      <Spacer size={12} />
      <TableController
        data={items}
        headers={headersTransactions()}
        total={Number(total || 0)}
        pageSize={PAGE_SIZE}
        initSort={INIT_SORT}
        initSortBy={INIT_SORT_BY}
        onChangePage={setPage}
        onSort={(sort, sortBy) => setSort({ sort, sortBy })}
      />
    </div>
  );
};
