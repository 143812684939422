import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Spacer } from '@rubin-dev/goblin';
import { ProfileProductWrapper } from '../ProfileProductWrapper/ProfileProductWrapper';
import styles from './styles.module.scss';
import { Maybe } from '@apolloGenerated';
import moment from 'moment';
import { IconAnalyzer } from '@shared/ui';
import { formatDate } from '@utils/timestamp';

export type ProfileAnalyzerProps = {
  plan: Maybe<string>;
  endTimestamp: Maybe<number>;
  analyzerSubscriptionExpireDate?: string;
};

export const ProfileAnalyzer: FC<ProfileAnalyzerProps> = ({
  plan,
  endTimestamp,
  analyzerSubscriptionExpireDate,
}) => {
  const { t } = useTranslation();
  const hasPlan = plan && endTimestamp;
  const isActivePlan = hasPlan && endTimestamp >= +new Date();

  return (
    <ProfileProductWrapper title={t('titles.analyzer')} iconPath={<IconAnalyzer />}>
      <div className={styles.profileAnalyzer}>
        <div className={styles.profileAnalyzer__content}>
          {isActivePlan ? (
            <div className={styles.profileAnalyzer__plan}>
              <Typography variant="body-16" color="on-secondary-2">
                {plan}. {t('strings.activeWillEnd')}:
              </Typography>
              <Typography variant="head-16" color="on-surface-primary-1">
                {moment(endTimestamp).format('MMM DD, YYYY')}
              </Typography>
            </div>
          ) : !hasPlan ? (
            <Typography
              variant="body-16"
              color="on-secondary-2"
              className={styles.profileAnalyzer__info}
            >
              {t('strings.analyzerInfo')}
            </Typography>
          ) : (
            <Typography variant="body-16" color="error-2">
              {t('strings.subscribeHasExpired')}. <br />{' '}
              {t('strings.analyzerNotAvailable')}
            </Typography>
          )}
          <Spacer size={2} />
          <div className={styles.profileAnalyzer__info}>
            <Typography variant="body-14" color="on-secondary-2">
              {t('titles.analyzerSubscriptionExpireDate')}:
            </Typography>
            <Typography variant="head-16" color="on-surface-primary-1">
              {analyzerSubscriptionExpireDate
                ? formatDate(analyzerSubscriptionExpireDate, true)
                : t('titles.base')}
            </Typography>
          </div>
        </div>
        {/*{!isActivePlan && (*/}
        {/*  <Button className={styles.profileAnalyzer__button} color="secondary" fullWidth>*/}
        {/*    {t('buttons.getBuy')}*/}
        {/*  </Button>*/}
        {/*)}*/}
      </div>
    </ProfileProductWrapper>
  );
};
