import React, { FC } from 'react';
import { MarkingEditComponent } from '@component/Marking';
import { Network } from '@apolloGenerated';

interface Props {
  query: {
    id: number;
    address: string;
    network: Network;
  };
}

export const MarkingEdit: FC<Props> = ({ query }) => {
  const { id, address, network } = query;
  return <MarkingEditComponent id={id} address={address} network={network} />;
};
