import React, { FC, PropsWithChildren } from 'react';
import { PageNotFound } from '../../../../screen';
import { useExplorerFindBlockByTransactionQuery } from '../../../../apolloGenerated';
import { LayoutUser } from '../LayoutUser/LayoutUser';
import { getNetworkEnum } from '@helpers/address';
import { Spacer } from '@rubin-dev/goblin';

type LayoutExplorerTransactionProps = {
  params: {
    txid: string;
    network: string;
  };
};

export const LayoutExplorerTransaction: FC<
  PropsWithChildren<LayoutExplorerTransactionProps>
> = ({ children, params: { network, txid } }) => {
  const { loading, data } = useExplorerFindBlockByTransactionQuery({
    variables: {
      network: getNetworkEnum(network),
      txid,
    },
    skip: !txid,
  });

  if (!data?.explorerFindBlockByTransaction?.node && !loading) {
    return <PageNotFound />;
  }

  return (
    <LayoutUser>
      {children}
      <Spacer size={24} />
    </LayoutUser>
  );
};
