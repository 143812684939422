export enum GraphCustomColor {
  OnSurfacePrimary1 = '#394363',
  OnSecondary2 = '#727781',
  Error2 = '#B8433D',
  Warning2 = '#F0A717',
  Yellow = '#EBC348',
  Success2 = '#3D7A21',
  Blue = '#3F8AE2',
  Violet = '#803DEC',
  Gray = '#E0E0E0',
}
