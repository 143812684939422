import { AddressCheckedType, AddressTransactionsFilterTxType } from '@apolloGenerated';
import { TableType } from '@graph/types';

export class AddressTable {
  idN: string;
  mode: TableType = TableType.Address;
  checked: AddressCheckedType[] = [];
  constructor(
    idN: AddressTable['idN'],
    tableType?: AddressTable['mode'],
    checked?: AddressTable['checked'],
  ) {
    this.idN = idN;
    if (tableType) this.mode = tableType;
    if (checked) this.checked = checked;
  }

  addChecked(checked: AddressCheckedType) {
    this.checked = [...this.checked, checked];
  }

  removeChecked(checked: AddressCheckedType) {
    this.checked = this.checked.filter((curHash) => {
      return !(
        curHash.txid === checked.txid &&
        curHash.type === checked.type &&
        curHash.source === checked.source &&
        curHash.target === checked.target
      );
    });
  }

  clearChecked() {
    this.checked = [];
  }

  setChecked(checked: AddressTable['checked']) {
    this.checked = [...checked];
  }

  static hasChecked(checked: AddressCheckedType[], item: AddressCheckedType): boolean {
    return !!checked.find(
      (el) =>
        el.type === item.type &&
        el.txid === item.txid &&
        el.source === item.source &&
        el.target === item.target,
    );
  }
  hasChecked(item: AddressCheckedType): boolean {
    return AddressTable.hasChecked(this.checked, item);
  }

  static getCheckedHash({ txid, type, source, target }: AddressCheckedType): string {
    return `${txid}-${type}:${source} / ${target}`;
  }

  static getCheckedItem(
    txid: string,
    type: AddressTransactionsFilterTxType,
    source: string,
    target: string,
  ): AddressCheckedType {
    return { txid, type, source, target };
  }
}
