import React, { FC } from 'react';
import { AuthLoginForm } from '../../component';
import { useGoBack } from '@hooks/useGoBack';
import { useTranslation } from 'react-i18next';
import { Logo } from '@shared/ui';
import { Paper, Spacer, Typography } from '@rubin-dev/goblin';

export const AuthLoginScreen: FC = (): JSX.Element => {
  const goPrevPage = useGoBack();
  const { t } = useTranslation();

  return (
    <>
      <Logo style={{ maxHeight: '180px', margin: '0 auto' }} />
      <Spacer size={8} />
      <Paper>
        <Typography variant="head-24" color="on-surface-primary-1">
          {t('links.Signin')}
        </Typography>
        <Spacer size={8} />
        <AuthLoginForm onSuccess={goPrevPage} />
      </Paper>
    </>
  );
};
