import { create } from 'zustand';

export type SidebarWindowStoreType = {
  isShow: boolean;
  setShow(val: boolean): void;
};
export const useSidebarWindowStore = create<SidebarWindowStoreType>()((set) => ({
  isShow: true,
  setShow: (isShow) => set({ isShow }),
}));
