import { FC, useEffect, useState } from 'react';
import { EventName, useGraphStore } from '@graph/libs';
import { NodeCoords } from '@apolloGenerated';
import { FloatingMenu } from '../FloatingMenu/FloatingMenu';
import { DropdownList } from '@rubin-dev/goblin';
import { isLinkItem, isNodeItem, isLabelNode } from '@graph/libs/helpers/table';
import { useContextMenuStore } from '@graph-visualization-module/entities/ContextMenu';

export type ContextMenuWrapperProps = {
  nodeSlot: JSX.Element;
  linkSlot: JSX.Element;
  labelSlot: JSX.Element;
};
export const ContextMenuWrapper: FC<ContextMenuWrapperProps> = ({
  nodeSlot,
  linkSlot,
  labelSlot,
}) => {
  const { event, clearEvent } = useGraphStore();
  const { isOpen, item, onOpen, onClose } = useContextMenuStore();
  const [coords, setCoords] = useState<NodeCoords>({ x: 0, y: 0 });
  const canOpen = item && (isNodeItem(item) || isLinkItem(item) || isLabelNode(item));

  useEffect(() => {
    switch (event?.type) {
      case EventName.GRAPH_RIGHT_CLICK:
        setCoords(event.params!.coords);
        onOpen(event.params!.item);
        clearEvent();
        break;
    }
  }, [event]);

  if (!isOpen || !canOpen) return <></>;
  return (
    <FloatingMenu x={coords.x} y={coords.y} onClose={onClose}>
      {item && (
        <DropdownList width={260} maxHeight={10000}>
          {isNodeItem(item) && nodeSlot}
          {isLinkItem(item) && linkSlot}
          {isLabelNode(item) && labelSlot}
        </DropdownList>
      )}
    </FloatingMenu>
  );
};
