import i18n from '../../../../../../../i18n/i18n';
import { tableStyles } from '@graph-sidebar-module/shared';
export const PAGE_SIZE = 10;
export const headersAddresses = () => [
  { title: i18n.t('titles.address'), value: 'hash' },
  // { title: i18n.t('titles.token'), value: 'token', width: 80 },
  // { title: i18n.t('strings.balance'), value: 'balance' },
  // { title: i18n.t('links.transactions'), value: 'transactions' },
  { title: '', value: 'hashUniq', key: true, hide: true },
];

export const INIT_SORT_BY = 'timestamp';
export const INIT_SORT = 'desc';
export const headersTransactions = () => [
  { title: '', value: 'checkbox', width: 20, className: tableStyles.table__checkbox },
  { title: 'Date (UTC)', value: 'timestamp', width: 118, sortable: true },
  { title: i18n.t('titles.hash'), value: 'hash', width: 124 },
  { title: i18n.t('titles.token'), value: 'token', width: 80 },
  {
    title: i18n.t('strings.amount'),
    value: 'amount',
  },
  { title: '', value: 'hashUniq', key: true, hide: true },
];
