import { useTranslation } from 'react-i18next';
import { formatDate, timestampToHumanDate } from '@utils/timestamp';
import moment from 'moment';

export const useActivityPeriod = () => {
  const { t } = useTranslation();
  const dateIsTimestamp = (date: string) => {
    return moment(String(date)).isValid()
      ? `${formatDate(date, true)}, ${formatDate(date)}`
      : timestampToHumanDate(date);
  };

  return (activity: { first: string; last: string }) => {
    const first = activity?.first ? dateIsTimestamp(activity?.first) : t('titles.empty');

    const last = activity?.last ? dateIsTimestamp(activity.last) : t('titles.empty');

    return `${first} - ${last}`;
  };
};
