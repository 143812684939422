import { UseGraphIntegration, UseGraphIntegrationMethods } from './types';
import { DataController } from '@graph/models';
import {
  EventName,
  GraphCrashStatus,
  useGraphStatusStore,
  useGraphStore,
} from '../../store';
import { DataConverter } from '@graph/models/Data/DataConverter';
import { useCurrentRoute } from 'react-justanother-router';
import { RouterName } from '../../../../../router';
import { GraphApiErrors } from '@graph/types/graph';
import { getFormatDate } from '@utils/timestamp';
import { useCallback, useState } from 'react';
import { toaster } from '@rubin-dev/goblin';
import { TableType } from '@graph/types';

export const useGraphIntegration: UseGraphIntegration = () => {
  const {
    currentRoute: { name: routeName },
  } = useCurrentRoute();
  const { isSaving, setSaving, setCrash, setLoading } = useGraphStatusStore();
  const { sendEvent } = useGraphStore();
  const [isLoading] = useState<boolean>(false);

  const handleExportJSON: UseGraphIntegrationMethods['exportJSON'] = useCallback(
    (convertedData = DataConverter.getConvertedData()) => {
      const { error } = DataController.exportJSON(convertedData);
      if (error?.code) toaster.error({ title: `Error export JSON: ${error.code}` });
    },
    [],
  );

  const handleImportJSON: UseGraphIntegrationMethods['importJSON'] = useCallback(
    async (file) => {
      const { error } = await DataController.importJSON(file);
      if (error?.code) toaster.error({ title: `Error import JSON: ${error.code}` });
    },
    [],
  );

  const handleExportJSONByUuid: UseGraphIntegrationMethods['exportJSONByUuid'] =
    useCallback(
      async (uuid) => {
        const { data, error } = await DataController.getByUuid(uuid);
        if (data) handleExportJSON(data.data);
        else if (error?.code)
          toaster.error({ title: `Error export JSON: ${error.code}` });
      },
      [handleExportJSON],
    );

  const handleCreateData = useCallback(async (name?: string) => {
    const convertedData = DataConverter.getConvertedData();
    const { data, error } = await DataController.create(convertedData, name);
    if (data?.uuid) DataController.navigateToUuid(data.uuid);
    else if (error?.code.includes(GraphApiErrors.ALREADY_EXIST))
      await handleCreateData(`${convertedData.meta.name} (${getFormatDate()})`);
    else if (error?.code) toaster.error({ title: `Error create graph: ${error.code}` });
  }, []);

  const handleSaveData = useCallback(async () => {
    const { error } = await DataController.save(DataConverter.getConvertedData());
    if (error?.code) toaster.error({ title: `Error save graph: ${error.code}` });
  }, []);
  const handleUpdateData: UseGraphIntegrationMethods['updateData'] =
    useCallback(async () => {
      if (import.meta.env.VITE_OFF_SAVE_GRAPH) return;
      if (isSaving) return;
      const isSavedGraph = routeName === RouterName.ExplorerSavedVisualization;
      setSaving(true);
      if (isSavedGraph) await handleSaveData();
      else await handleCreateData();
      setSaving(false);
    }, [setSaving, isSaving, routeName, handleSaveData, handleCreateData]);

  const handleInitData: UseGraphIntegrationMethods['initDataByUuid'] = useCallback(
    async (uuid) => {
      const { data, error } = await DataController.getByUuid(uuid);
      if (data) {
        const current = data.data.meta.current;
        DataController.setData(data.uuid, data.data);
        if (current.hash && [TableType.Address, TableType.Cluster].includes(current.type))
          sendEvent({
            type: EventName.DATA_UPDATE_HASH,
            params: { type: current.type, hash: current.hash },
          });
        else sendEvent({ type: EventName.DATA_UPDATE });
      } else if (error?.code) {
        setLoading(false);
        setCrash(GraphCrashStatus.NOT_FOUND_SAVED);
      }
      // else if (error?.code) toaster.error({ title: `Error get graph: ${error.code}` });
    },
    [sendEvent],
  );
  const handleDuplicateData: UseGraphIntegrationMethods['duplicateData'] = useCallback(
    async (uuid) => {
      const { data, error } = await DataController.getByUuid(uuid);
      if (data) await DataController.duplicate(data.data);
      else if (error?.code)
        toaster.error({ title: `Error duplicate graph: ${error.code}` });
    },
    [],
  );

  return [
    {
      exportJSON: handleExportJSON,
      importJSON: handleImportJSON,
      exportJSONByUuid: handleExportJSONByUuid,
      updateData: handleUpdateData,
      initDataByUuid: handleInitData,
      duplicateData: handleDuplicateData,
    },
    { isLoading },
  ];
};
