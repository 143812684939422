import React, { FC, useState } from 'react';
import {
  Button,
  EditIcon,
  TrashIcon,
  Typography,
  Divider,
  ArrowLeftIcon,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import cls from '@component/Marking/contentMarking.module.scss';
import styles from '../../screen/Markup/styles/details.module.scss';
import { MentionComponent } from '@component/Mention';
import { useNavigate } from 'react-justanother-router';
import { RouterName } from '../../router';
import { getNetworkEnum } from '@helpers/address';
import { useModal } from '@hooks/useModal';
import { Mention, Network } from '@apolloGenerated';

interface Props {
  network: string;
  id: number;
  query: string;
}

export const MarginDetailsComponent: FC<Props> = ({ id, query, network }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const [mention, setMention] = useState<unknown>();

  const openDialog = useModal('dialogMarkingDelete');

  const openEditDetails = () => {
    navigate(
      RouterName.MarkingEdit,
      {},
      { id, address: query, network: getNetworkEnum(network) },
    );
  };

  const onDelete = () => {
    openDialog({ uuid: (mention as Mention).uuid, network: network as Network });
  };

  const onPrevPage = () => {
    navigate(RouterName.Marking, {}, { query });
  };

  return (
    <>
      <Divider />
      <section className={cls.container}>
        <header className={cls.header}>
          <div className={cls.group}>
            <Button
              variant={'outlined'}
              icon
              size="small"
              prependIcon={ArrowLeftIcon}
              onClick={onPrevPage}
            />
            <Typography variant={'head-24'} color={'on-surface-primary-1'}>
              {t('titles.details')}
            </Typography>
          </div>
          <div className={cls.group}>
            <Button
              variant={'outlined'}
              prependIcon={EditIcon}
              icon
              size={'small'}
              onClick={openEditDetails}
            />
            <Button
              variant={'outlined'}
              prependIcon={TrashIcon}
              icon
              size={'small'}
              onClick={onDelete}
            />
          </div>
        </header>
        <div className={styles['markup__content']}>
          <MentionComponent
            setMention={setMention}
            showHeader={false}
            address={query}
            network={network}
            id={id}
          />
        </div>
      </section>
    </>
  );
};
