import { FC, PropsWithChildren } from 'react';
import { PropsWithNativeTypes } from '@rubin-dev/goblin';
import cx from 'classnames';
import styles from './styles.module.scss';

export const Container: FC<
  PropsWithChildren<PropsWithNativeTypes<{}, HTMLDivElement>>
> = ({ children, className, ...rest }) => {
  return (
    <div className={cx(styles.container, className)} {...rest}>
      {children}
    </div>
  );
};
