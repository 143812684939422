import React, { FC, useMemo } from 'react';
import * as gqlTypes from '../../apolloGenerated';
import { getNetworkEnum } from '@helpers/address';
import { useTranslation } from 'react-i18next';
import { useExplorerFindTransactionsByAddressCountQuery } from '@apolloGenerated';
import { Loader } from '@rubin-dev/goblin';

type Props = {
  address: string;
  network: gqlTypes.Network;
  bold?: boolean;
};

export const ExplorerAddressTransactionTitle: FC<Props> = ({ address, network }) => {
  const { t } = useTranslation();
  const { loading, data } = useExplorerFindTransactionsByAddressCountQuery({
    variables: {
      network: getNetworkEnum(network),
      filter: {
        address,
      },
    },
  });

  const totalMemo = useMemo(
    () => data?.explorerFindTransactionsByAddressCount?.total || 0,
    [data],
  );

  return (
    <span>
      {t('strings.transactions')} {loading ? <Loader size={16} /> : ` (${totalMemo})`}
    </span>
  );
};
