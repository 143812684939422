import { FC, useEffect, useState, PropsWithChildren } from 'react';
import {
  useFloating,
  autoUpdate,
  flip,
  offset,
  shift,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
} from '@floating-ui/react';
import { NodeCoords } from '@apolloGenerated';

export type FloatingMenuProps = NodeCoords & {
  onClose?(): void;
};
export const FloatingMenu: FC<PropsWithChildren<FloatingMenuProps>> = ({
  x,
  y,
  onClose,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    refs: { setFloating, setPositionReference, floating },
    floatingStyles,
    context,
  } = useFloating({
    open: isOpen,
    onOpenChange: (val) => {
      if (!val) handleCloseMenu();
      else setIsOpen(true);
    },
    middleware: [
      offset({ mainAxis: 4, alignmentAxis: 4 }),
      flip({
        fallbackPlacements: ['left-start'],
      }),
      shift({ padding: 8 }),
    ],
    placement: 'right-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([role, dismiss]);

  useEffect(() => {
    setPositionReference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x,
          y,
          top: y,
          right: x,
          bottom: y,
          left: x,
        };
      },
    });
    setIsOpen(true);
  }, [x, y]);

  const handleCloseMenu = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <FloatingPortal>
      {isOpen && (
        <FloatingOverlay lockScroll>
          <FloatingFocusManager context={context} initialFocus={floating}>
            <div
              className="ContextMenu"
              ref={setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
};
