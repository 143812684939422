import { FC } from 'react';
import {
  Network,
  useExplorerAddressTransactionStatsQuery,
  useExplorerFindAddressByHashQuery,
} from '@apolloGenerated';
import { getNetworkEnum } from '@helpers/address';
import { TransactionInfo } from '@graph-sidebar-module/shared';

export type AddressTransactionInfoProps = {
  address: string;
  network: Network;
};
export const AddressTransactionInfo: FC<AddressTransactionInfoProps> = ({
  address,
  network,
}) => {
  const { data: balanceData, loading: balanceLoading } =
    useExplorerFindAddressByHashQuery({
      variables: { network: getNetworkEnum(network), address },
    });
  const { data: countData, loading: countLoading } =
    useExplorerAddressTransactionStatsQuery({
      variables: { network: getNetworkEnum(network || ''), address: address },
    });

  return (
    <TransactionInfo
      address={address}
      network={network}
      loading={balanceLoading || countLoading}
      totalIn={Number(balanceData?.explorerFindAddressByHash?.node?.totalIn || 0)}
      totalOut={Number(balanceData?.explorerFindAddressByHash?.node?.totalOut || 0)}
      receivedCount={Number(
        countData?.explorerAddressTransactionStats?.stats?.received || 0,
      )}
      sentCount={Number(countData?.explorerAddressTransactionStats?.stats?.sent || 0)}
    />
  );
};
