import { isProd } from '@shared/libs';

export type AppConfig = {
  isProd: boolean;
  endpoint: string;
  apiPrefix: string;
};

const apiPrefix = '/graphql';

const endpoint = import.meta.env.VITE_GRAPHQL_URL || apiPrefix;
export const config: AppConfig = {
  isProd,
  endpoint,
  apiPrefix,
};
