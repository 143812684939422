import React, { FC } from 'react';
import { amount } from '@aml/amount';
import { getNetworkEnum } from '@helpers/address';
import { BigNumber } from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { CardItem, ListItem } from '@shared/ui';
import { LoaderOverlay } from '@rubin-dev/goblin';
import { BalanceTooltip } from '@component/Address';

type BalanceCardProps = {
  network: string;
  total: number;
  totalIn: number;
  totalOut: number;
  loading?: boolean;
};
export const BalanceCard: FC<BalanceCardProps> = ({
  network,
  total,
  totalIn,
  totalOut,
  loading,
}) => {
  const { t } = useTranslation();
  const formatBalance = (val?: number) =>
    Number(val) > 0 ? amount(BigNumber(val || 0).toString(10), network, false) : 0;
  const totalFormat = formatBalance(total);
  const totalInFormat = formatBalance(totalIn);
  const totalOutFormat = formatBalance(totalOut);

  return (
    <CardItem
      rightSlot={
        <BalanceTooltip
          totalIn={`${totalInFormat} ${getNetworkEnum(network)}`}
          totalOut={`${totalOutFormat} ${getNetworkEnum(network)}`}
        />
      }
    >
      <LoaderOverlay show={loading} />
      <ListItem
        label={`${t('strings.balance')}:`}
        value={`${totalFormat} ${getNetworkEnum(network)}`}
      />
    </CardItem>
  );
};
