import React, { FC, useLayoutEffect, useState } from 'react';
import {
  AppError,
  AuthRestoreConfirmForm,
  AuthRestoreConfirmFormInput,
} from '../../component';
import { persistUser } from '@utils/auth';
import * as gqlTypes from '../../apolloGenerated';
import { useRestoreCheckMutation } from '@apolloGenerated';
import { Link } from 'react-justanother-router';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import {
  FormCallback,
  Loader,
  Paper,
  Spacer,
  toaster,
  Typography,
} from '@rubin-dev/goblin';

type AuthRestoreConfirmScreenProps = {
  params: { token: string };
};

export const AuthRestoreConfirmScreen: FC<AuthRestoreConfirmScreenProps> = ({
  params: { token },
}): JSX.Element => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [restoreCheck, { error, loading, reset }] = useRestoreCheckMutation();

  const [restoreConfirm, { error: formError, loading: formLoading, reset: formReset }] =
    gqlTypes.useRestoreConfirmMutation();

  const formOnSubmit: FormCallback<AuthRestoreConfirmFormInput> = async (
    data,
    setFormErrors,
    setBatchValues,
  ) => {
    const resp = await restoreConfirm({
      variables: {
        input: {
          ...data,
          token,
        },
      },
    });

    const formErrors = resp.data?.restoreConfirm?.errors || {};
    if (Object.keys(formErrors).length > 0) {
      toaster.error({ title: 'Пожалуйста исправьте ошибки в форме' });
      setFormErrors(formErrors);
    } else {
      setBatchValues({
        passwordConfirm: '',
        password: '',
      });
      setFormErrors({});
      toaster.success({ title: 'Пароль сохранен' });
      if (resp.data?.restoreConfirm.jwt) {
        persistUser(resp.data.restoreConfirm.jwt);
        window.location.pathname = '/';
      }
    }
    formReset();
  };

  useLayoutEffect(() => {
    restoreCheck({
      variables: {
        input: {
          token,
        },
      },
    }).then((resp) => {
      const formErrors = resp.data?.restoreCheck?.errors || {};
      if (Object.keys(formErrors).length > 0) {
        toaster.error({ title: 'Ошибка восстановления пароля' });
      } else {
        setSuccess(true);
      }
      reset();
    });
  }, [reset, restoreCheck, token]);

  return (
    <>
      <Link to={RouterName.AuthRestore} className="b-auth__back">
        &larr; {t('titles.back')}
      </Link>
      <Paper>
        <Typography variant="head-24" color="on-surface-primary-1">
          {t('titles.newPassword')}
        </Typography>
        <Spacer size={8} />
        {loading && <Loader />}
        {error && <AppError error={error.message} />}
        {success ? (
          <>
            {formError && <AppError error={formError.message} />}
            <AuthRestoreConfirmForm loading={formLoading} formOnSubmit={formOnSubmit} />
          </>
        ) : (
          <div>
            {t('strings.cantCheckCodeNewPassword')}.{' '}
            <strong>{t('strings.onlyLastCodeValid')}</strong>
          </div>
        )}
      </Paper>
    </>
  );
};
