import styles from './styles.module.scss';
import { PropsWithNativeTypes } from '@rubin-dev/goblin';
import cx from 'classnames';
import { ColorItem } from '@shared/ui/ColorPicker/ColorItem';

export interface ColorPickerProps<T> {
  value: T;
  items: T[];
  onChange(value: T): void;
}
export const ColorPicker = function <T extends string>({
  value,
  items,
  onChange,
  className,
  ...rest
}: PropsWithNativeTypes<ColorPickerProps<T>, HTMLDivElement>) {
  return (
    <div className={cx(styles.colorPicker, className)} {...rest}>
      {items.map((el) => (
        <ColorItem
          key={el}
          color={el}
          active={el === value}
          onClick={() => onChange(el)}
        />
      ))}
    </div>
  );
};
