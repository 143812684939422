import { TableType } from '@graph/types';
import { AddressTable, NodeList, AddressBetweenList } from '@graph/models';
import { Maybe } from '@apolloGenerated';

export class TableController {
  static getTableDataByHash(
    currentHash: string,
    currentType: TableType,
  ): Maybe<AddressTable> {
    if (!currentHash) return null;
    if (currentType === TableType.Transaction)
      return AddressBetweenList.getTableDataByHash(currentHash);
    return NodeList.getTableDataByHash(currentHash);
  }
}
