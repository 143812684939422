import { useLayoutEffect, RefObject, useState } from 'react';
import { isEllipsisActive } from '@helpers/dom';
export const useIsEllipsis = (
  ref: RefObject<HTMLElement | null>,
  trigger?: any,
): boolean => {
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (ref.current) setIsEllipsis(isEllipsisActive(ref.current));
  }, [trigger]);

  return isEllipsis;
};
