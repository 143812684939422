import i18n from '../../../i18n/i18n';

export const headersTransactionDetailSentTable = () => [
  { title: i18n.t('strings.receivingAddress'), value: 'hash' },
  {
    title: i18n.t('strings.amount'),
    value: 'amount',
  },
  { title: '', value: 'hashUniq', key: true, hide: true },
];

export const headersTransactionDetailReceiveTable = () => [
  { title: i18n.t('strings.sendingAddress'), value: 'hash' },
  {
    title: i18n.t('strings.amount'),
    value: 'amount',
  },
  { title: '', value: 'hashUniq', key: true, hide: true },
];
