import React, { FC } from 'react';
import { AddressTransactionInfo } from '../AddressTransactionInfo/AddressTransactionInfo';
import { AddressTransactionsCard } from '../AddressTransactionsCard/AddressTransactionsCard';
import { AddressBalanceCard } from '../AddressBalanceCard/AddressBalanceCard';
import { AddressMainInfo } from '@component/Address';
import { Network } from '@apolloGenerated';
import styles from './styles.module.scss';
import { Spacer } from '@rubin-dev/goblin';

type AddressInfoProps = {
  address: string;
  network: Network;
};
export const AddressInfo: FC<AddressInfoProps> = ({ address, network }) => {
  return (
    <div className={styles.addressInfo}>
      <AddressMainInfo
        address={address}
        network={network}
        hideBalance
        hideTransactions
        hideActivity
      />
      <Spacer size={12} />
      <div className={styles.addressInfo__card}>
        <AddressBalanceCard address={address} network={network} />
        <AddressTransactionsCard address={address} network={network} />
      </div>
      <Spacer size={8} />
      <AddressTransactionInfo address={address} network={network} />
    </div>
  );
};
