import { FC } from 'react';
import { ArrowRightDirectionIcon, CardItem } from '@shared/ui';
import styles from './styles.module.scss';
import { AddressTransactionsFilterTxType, Network } from '@apolloGenerated';
import { TransactionItem } from '../TransactionItem/TransactionItem';
import { CopyHash, LoaderOverlay } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';

export type TransactionInfoProps = {
  address: string;
  network: Network;
  loading: boolean;
  totalIn: number;
  totalOut: number;
  receivedCount: number;
  sentCount: number;
};
export const TransactionInfo: FC<TransactionInfoProps> = ({
  address,
  network,
  loading,
  totalIn,
  totalOut,
  sentCount,
  receivedCount,
}) => {
  const { t } = useTranslation();

  return (
    <CardItem className={styles.trInfo} px={12} py={8}>
      <LoaderOverlay show={loading} />
      <TransactionItem
        label={t('strings.received')}
        token={network}
        network={network}
        total={totalIn}
        count={receivedCount}
        direction={AddressTransactionsFilterTxType.Receives}
      />
      <div className={styles.trInfo__direction}>
        <ArrowRightDirectionIcon color="var(--success-2)" />
        <CopyHash
          label={address.slice(0, 8) + '...'}
          hash={address}
          variant="head-16"
          defaultColor="on-surface-primary-1"
        />
        <ArrowRightDirectionIcon color="var(--error-2)" />
      </div>
      <TransactionItem
        label={t('strings.sending')}
        token={network}
        network={network}
        total={totalOut}
        count={sentCount}
        direction={AddressTransactionsFilterTxType.Sent}
      />
    </CardItem>
  );
};
