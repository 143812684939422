import { FC } from 'react';
import { GraphCrashStatus, useGraphStatusStore } from '../../libs/store';
import { GraphLoader } from './shared/GraphLoader/GraphLoader';
import { GraphCrash } from './shared/GraphCrash/GraphCrash';
import { LoaderOverlay } from '@rubin-dev/goblin';
import { GraphNotFound } from '@graph/ui/GraphStatus/shared/GraphNotFound/GraphNotFound';

export type GraphStatusProps = {
  hasSidebar: boolean;
};
export const GraphStatus: FC<GraphStatusProps> = ({ hasSidebar }) => {
  const { isInitialized, isCrash, isLoading, crashMessage } = useGraphStatusStore();

  if (isLoading && !hasSidebar) return <LoaderOverlay show />;
  if (isCrash) {
    switch (crashMessage) {
      case GraphCrashStatus.NOT_FOUND_SAVED:
        return <GraphNotFound />;
      default:
        return <GraphCrash />;
    }
  }
  if (!isInitialized) return <GraphLoader />;
  return <></>;
};
