import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const ArrowRightToLeftIcon: FC<IconProps> = ({
  width = 584,
  height = 32,
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 584 32"
      fill="none"
      {...rest}
    >
      <path
        d="M583.5 0.999998C583.5 0.723856 583.276 0.499998 583 0.499998C582.724 0.499998 582.5 0.723856 582.5 0.999998H583.5ZM4.35358 0.646446C4.15833 0.451183 3.84174 0.451183 3.64648 0.646446L0.464478 3.82843C0.269226 4.02369 0.269226 4.34027 0.464478 4.53553C0.659729 4.73079 0.976318 4.73079 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02368 4.73079 7.34027 4.73079 7.53552 4.53553C7.73083 4.34027 7.73083 4.02369 7.53552 3.82843L4.35358 0.646446ZM582.5 0.999998V19H583.5V0.999998H582.5ZM571 30.5H16V31.5H571V30.5ZM4.5 19V0.999998H3.5V19H4.5ZM16 30.5C9.64874 30.5 4.5 25.3513 4.5 19H3.5C3.5 25.9036 9.09644 31.5 16 31.5V30.5ZM582.5 19C582.5 25.3513 577.351 30.5 571 30.5V31.5C577.904 31.5 583.5 25.9036 583.5 19H582.5Z"
        fill={color}
      />
    </svg>
  );
};
