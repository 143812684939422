export const precisions: Record<string, number> = {
  btc: 8,
  eth: 18,
  tron: 6,
};

export const symbols: Record<string, string> = {
  btc: 'btc',
  eth: 'eth',
  tron: 'trx',
};

const appendNetwork = (value: string, network: string): string =>
  `${value} ${network?.toUpperCase()}`;

export const amount = (
  value: number | string,
  network: string,
  showSymbol: boolean = true,
  toFixed: boolean = true,
): string => {
  const precision = precisions[network.toLowerCase()];
  let numberVal = Number(value) === 0 ? '0.00' : format(value, precision);

  if (!showSymbol) {
    return numberVal;
  }

  if (toFixed) {
    numberVal = numberVal.substr(0, 12);
  }

  return appendNetwork(numberVal, symbols[network.toLowerCase()]);
};

export const format = (value: number | string, precision: number): string => {
  if (value === '0') return '0';
  return Intl.NumberFormat('en', { maximumFractionDigits: precision }).format(+value);
};
export const findFirstNonZeroIndex = (str: string): number => {
  const dotIndex = str.indexOf('.');
  if (dotIndex === -1) {
    return -1;
  }

  for (let i = dotIndex + 1; i < str.length; i++) {
    if (str[i] !== '0') {
      return i === 0 ? -1 : i;
    }
  }

  return -1;
};

export const disableScienticNotation = (value: number, decimals = 18): string => {
  return value.toFixed(decimals).replace(/\.?0+$/, '');
};

export const smartRound = (value: number): string => {
  const btcValue = disableScienticNotation(value);
  const index = findFirstNonZeroIndex(btcValue);
  if (index === -1) {
    return String(Math.round(value * 100) / 100);
  }
  return btcValue.slice(0, index + 1);
};

export const formatNumber = (number: number): string =>
  Intl.NumberFormat('ru-Ru').format(number);
