import { FC } from 'react';
import { Button, Dialog, DialogFooter, Modal, Typography } from '@rubin-dev/goblin';
import { useNavigate } from 'react-justanother-router';
import styles from './styles.module.scss';
import { RouterName } from '../../../../../../router';

export const GraphCrash: FC = () => {
  const { navigate } = useNavigate();
  return (
    <Modal isOpen onClose={() => {}} overlayProps={{ background: 'transparent' }}>
      <Dialog
        width={350}
        footerSlot={
          <DialogFooter
            fullWidth
            startSlot={
              <Button
                onClick={() => navigate(RouterName.HomePage)}
                size={'large'}
                variant="outlined"
                fullWidth
              >
                Go home
              </Button>
            }
            endSlot={
              <Button onClick={() => location.reload()} size={'large'} fullWidth>
                Refresh
              </Button>
            }
          />
        }
      >
        <Typography className={styles.graphCrash__title} variant="head-24">
          Graph has been crashed
        </Typography>
      </Dialog>
    </Modal>
  );
};
