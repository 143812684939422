import React, { FC, useState } from 'react';
import { AppError, AuthRestoreForm, AuthRestoreFormInput } from '../../component';
import { useRestoreMutation } from '@apolloGenerated';
import { useTranslation } from 'react-i18next';
import { useGoBack } from '@hooks/useGoBack';
import { FormCallback, Paper, Spacer, toaster, Typography } from '@rubin-dev/goblin';

export const AuthRestoreScreen: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const goPrevPage = useGoBack();
  const [success, setSuccess] = useState<boolean>(false);

  const [mutate, { error, loading }] = useRestoreMutation();

  const formOnSubmit: FormCallback<AuthRestoreFormInput> = async (
    data,
    setFormErrors,
    setBatchValues,
  ) => {
    const resp = await mutate({
      variables: {
        input: data,
      },
    });

    const formErrors = resp.data?.restore?.errors || {};
    if (Object.keys(formErrors).length > 0) {
      toaster.error({ title: 'validation error' });
      setFormErrors(formErrors);
    } else {
      setBatchValues({
        email: '',
      });
      setSuccess(true);
    }
  };

  return (
    <>
      <div
        onClick={() => goPrevPage()}
        className="b-auth__back"
        role="button"
        tabIndex={0}
      >
        &larr; {t('titles.back')}
      </div>
      <Paper>
        <Typography variant="head-24" color="on-surface-primary-1">
          {t('titles.restorepasswd')}
        </Typography>
        <Spacer size={8} />
        {success ? (
          <div>{t('strings.messageRegisterSend')}.</div>
        ) : (
          <>
            {error && <AppError error={error.message} />}
            <AuthRestoreForm loading={loading} formOnSubmit={formOnSubmit} />
          </>
        )}
      </Paper>
    </>
  );
};
