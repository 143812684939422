import { useEffect, useState } from 'react';
import { AddressTransactionsFilterTxType } from '@apolloGenerated';
import { EventName, useGraphStore } from '@graph/libs';

export const useSidebarTrTabs = (): [
  AddressTransactionsFilterTxType,
  (tab: AddressTransactionsFilterTxType) => void,
] => {
  const { event } = useGraphStore();
  const [tab, setTab] = useState<AddressTransactionsFilterTxType>(
    AddressTransactionsFilterTxType.All,
  );

  useEffect(() => {
    switch (event?.type) {
      case EventName.GRAPH_CLICK:
        setTab(AddressTransactionsFilterTxType.All);
        break;
    }
  }, [event]);

  return [tab, setTab];
};
