import React, { FC, PropsWithChildren } from 'react';
import { Divider, Paper, Typography } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

export type ProfileProductWrapperProps = {
  title: string;
  iconPath: JSX.Element;
};

export const ProfileProductWrapper: FC<PropsWithChildren<ProfileProductWrapperProps>> = ({
  title,
  iconPath,
  children,
}) => {
  return (
    <Paper className={styles.productWrapper} px={0} py={0}>
      <div className={styles.productWrapper__head}>
        {iconPath}
        {/*<img src={iconPath} alt={title} className={styles.productWrapper__icon} />*/}
        <Typography variant="head-20" color="on-surface-primary-1">
          {title}
        </Typography>
      </div>
      <Divider />
      <div className={styles.productWrapper__content}>{children}</div>
    </Paper>
  );
};
