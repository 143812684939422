import { ApolloLink, from } from '@apollo/client';
import {
  authLink,
  refreshTokenErrorLink,
  resetAuthTokenErrorLink,
  globalErrorLink,
} from './auth';
import { ApolloClient } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { cache } from './cache';
import { config } from '../config';

const httpLink: ApolloLink | any = createUploadLink({
  // uri: config.isProd ? config.endpoint : config.apiPrefix,
  uri: config.endpoint,
});

export const link = from([
  globalErrorLink,
  resetAuthTokenErrorLink,
  refreshTokenErrorLink,
  authLink,
  httpLink,
]);
export const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: !config.isProd,
});
