import React, { FC } from 'react';
import styles from './styles/markup.module.scss';
import { Informer, Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';

interface Props {
  contentSlot: JSX.Element;
  hasContent: boolean;
}
export const WrapperFinderContent: FC<Props> = ({ hasContent, contentSlot }) => {
  const { t } = useTranslation();

  return (
    <>
      {hasContent ? (
        <Typography tag={'div'} variant={'body-16'} className={styles['markup__text']}>
          <Informer color={'#394363'} title={''} subtitle={t('explorer.find')} />
        </Typography>
      ) : (
        contentSlot
      )}
    </>
  );
};
