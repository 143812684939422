import { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const IconApi: FC<IconProps> = ({ width = 32, height = 32, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
      {...rest}
    >
      <rect width="32" height="32" rx="10" fill="url(#paint0_linear_1745_87858)" />
      <path
        d="M20.1667 6.83337L23.5 10.1667M23.5 10.1667L20.1667 13.5M23.5 10.1667H11C9.61929 10.1667 8.5 11.286 8.5 12.6667V14.3334"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8333 25.1666L8.5 21.8333M8.5 21.8333L11.8333 18.5M8.5 21.8333H21C22.3807 21.8333 23.5 20.714 23.5 19.3333V17.6666"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1745_87858"
          x1="16"
          y1="32"
          x2="15.2"
          y2="1.95116e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.481579" stopColor="#44B5E1" />
          <stop offset="1" stopColor="#A4E5CA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
